/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { filterTreeByLeafNames, Skill, skillLevelComparator, skillYearsComparator } from '@core/models/skill';
import { EmployeeDetails } from '@core/models/employee';
import { filterInstance, FilterInstance, FILTERS } from '@core/prototypes/filtering';
import {
  ComparatorFunction,
  filterObjectTreesBy,
  flattenObjectTrees,
  MenuItem,
  objectTreeIncludesAny,
  SimpleFilter
} from '@ebcont/galaxy';
import { deepCopy, numberComparator, textAsMenuItem } from '@core/utils/utils';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, of } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-employee-skills',
  templateUrl: './employee-skills.component.html',
  styleUrls: ['./employee-skills.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeSkillsComponent implements OnChanges {
  @Input() employee: EmployeeDetails | undefined;
  public skillTreeSearchValue = '';
  public filteredSkills: Skill[] = [];
  public skillSortItems: MenuItem[] = [
    { label: 'sorting.level', isRouterLink: false, id: 'level' },
    { label: 'sorting.years', isRouterLink: false, id: 'years' }
  ];
  protected comparator: ComparatorFunction = skillLevelComparator;
  protected filtersForBlock: FilterInstance[] = [];
  private allSkills: Skill[] = [];

  constructor(public cd: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['employee']) {
      this.employee = changes['employee'].currentValue;
      this.skillTreeSearchValue = '';

      if (this.employee?.skillTree) {
        const allLeafSkills = flattenObjectTrees(this.employee.skillTree);
        this.allSkills = allLeafSkills.filter((elem) => {
          return !elem.children;
        });

        this.registerSkillFilter();
        this.registerYearFilter();
        this.registerLevelFilter();
      }
    }
  }

  // public handleSearched(searchValue: string): void {
  //   this.skillTreeSearchValue = searchValue;
  //   this.updateFilteredSkills();
  // }

  public isSkillFiltered(data: Skill, filteredSkills: Skill[]): boolean {
    if (filteredSkills.length === 0) {
      return false;
    }
    return objectTreeIncludesAny(data, filteredSkills);
  }

  private getFilteredSkills(value: Record<string, string>): Skill[] {
    const { searchValue, skill, years, level } = value;
    const filters: SimpleFilter[] = [];
    if (searchValue && searchValue.trim() !== '') {
      filters.push({ param: 'name', values: [searchValue] });
    }
    if (skill) {
      filters.push({ param: 'name', values: skill.split(',') });
    }
    if (years) {
      filters.push({ param: 'years', values: years.split(',') });
    }
    if (level) {
      filters.push({ param: 'factor', values: level.split(',') });
    }

    if (filters.length === 0) {
      return deepCopy(this.employee?.skillTree ?? []);
    }

    const filteredSkillNames = filterObjectTreesBy(this.employee?.skillTree ?? [], filters).map((skill) => {
      return skill.name;
    });
    return filterTreeByLeafNames(deepCopy(this.employee?.skillTree ?? []), filteredSkillNames);
  }

  /**
   * Registers the skill filter, containing all the skill names.
   */
  private registerSkillFilter() {
    if (!this.employee) {
      return;
    }
    const nameOfLeafsMenuItems = this.prepareStringArrayForFilters(
      this.allSkills.map((skill) => {
        return skill.name;
      }),
      false
    );
    this.filtersForBlock.push(this.getDefaultItemsFilter(FILTERS.SKILL, nameOfLeafsMenuItems, {}));
  }

  /**
   * Registers the level filter, containing all the selectable levels.
   */
  private registerLevelFilter() {
    const selectableLevels = [
      'rating.levels.basic',
      'rating.levels.good',
      'rating.levels.very_good',
      'rating.levels.excellent',
      'rating.levels.expert'
    ];
    this.filtersForBlock.push(
      this.getDefaultItemsFilter(FILTERS.LEVEL, this.prepareStringArrayForFilters(selectableLevels, true, 1), {
        searchable: false
      })
    );
  }

  /**
   * Registers the year filter, containing all the selectable years.
   */
  private registerYearFilter() {
    if (!this.employee) {
      return;
    }
    const selectableYears = Array.from(
      new Set(
        flattenObjectTrees(this.employee?.skillTree).map((skill) => {
          return parseInt(skill.years ?? '0', 10);
        })
      )
    );
    selectableYears.sort(numberComparator);

    this.filtersForBlock.push(
      this.getDefaultItemsFilter(
        FILTERS.YEARS,
        this.prepareStringArrayForFilters(
          selectableYears.map((value) => {
            return value.toString();
          })
        ),
        {
          searchable: false
        }
      )
    );
  }

  private prepareStringArrayForFilters(array: string[], useIndex = true, correction = 0): MenuItem[] {
    return Array.from(
      new Set( // make it distinct
        array.filter((value) => {
          return !!value;
        }) // clear null and undefined values
      )
    ).map((value, index) => {
      // convert
      return { id: useIndex ? String(index + correction) : value, label: value, isRouterLink: false } as MenuItem;
    });
  }

  private getDefaultItemsFilter(
    filterKey: FILTERS,
    menuItems: MenuItem[],
    options: Partial<FilterInstance>
  ): FilterInstance {
    return filterInstance(filterKey, menuItems, {
      ...{
        filterOptions: 'items',
        switchMode: false,
        maxLabels: 3,
        isMulti: true,
        searchable: true
      },
      ...options
    });
  }

  protected searchForName = (text: string): Observable<MenuItem[]> => {
    return of(
      this.allSkills
        .filter((skill) => {
          return skill.name.toLocaleLowerCase().includes(text.toLocaleLowerCase());
        })
        .map((skill) => {
          return textAsMenuItem(skill.name);
        })
    );
  };

  filterBlockChanged(value: Record<string, any>) {
    if (!this.employee?.skillTree) {
      return;
    }
    this.filteredSkills = this.getFilteredSkills(value);
    if (value['searchValue'] || value['skill'] || value['years'] || value['level']) {
      // open all summaries when filtered
      setTimeout(() => {
        document.querySelectorAll('summary').forEach((summary) => {
          summary.dispatchEvent(new MouseEvent('click'));
        });
      }, 200);
    }
    if (value['sorting'] && value['sorting'] !== 'undefined') {
      this.comparator = value['sorting'] === 'level' ? skillLevelComparator : skillYearsComparator;
    }
    this.cd.detectChanges();
  }
}
