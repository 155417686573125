import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faFaceSmile, faHome } from '@fortawesome/free-solid-svg-icons';

export interface NavigationItem {
  text: string;
  icon?: IconProp;
  image?: string;
  class?: string;
  routerLink: string;
}

const dashboardMenuItem: NavigationItem = {
  text: 'dashboard.singular',
  icon: 'home',
  routerLink: '/dashboard'
};

const teamMenuItem: NavigationItem = {
  text: 'team.singular',
  icon: 'face-smile',
  routerLink: '/team'
};

const customerMenuItem: NavigationItem = {
  text: 'customer.plural',
  icon: 'briefcase',
  routerLink: '/customer'
};
const projectsMenuItem: NavigationItem = {
  text: 'project.plural',
  icon: 'project-diagram',
  routerLink: '/projects'
};
const settingsMenuItem: NavigationItem = {
  text: 'settings.plural',
  icon: 'gear',
  class: 'md:hidden',
  routerLink: '/settings'
};
const skillsMenuItem: NavigationItem = {
  text: 'skill.plural',
  icon: 'user-gear',
  routerLink: '/skills'
};
const usefulMenuItem: NavigationItem = {
  text: 'usefulLinks',
  icon: 'link',
  routerLink: '/useful'
};

export const navigationItems: NavigationItem[] = [
  dashboardMenuItem,
  teamMenuItem,
  customerMenuItem,
  projectsMenuItem,
  settingsMenuItem,
  skillsMenuItem,
  usefulMenuItem
];

@Injectable({ providedIn: 'root' })
export class MainMenuService {
  constructor(private library: FaIconLibrary) {
    library.addIcons(faFaceSmile, faHome);
  }

  public getNavigationItems(): Observable<NavigationItem[]> {
    return of(navigationItems);
  }
}
