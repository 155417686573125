<!-- Primary column -->
<div class="w-full">
  <section class="px-3 md:px-6">
    <gx-section-title [title]="'error.oops'|translate"/>
    <h1 id="primary-heading">{{ 'error.not_found.subtitle'|translate }}</h1>
    <p class="text-lg">{{ 'error.not_found.text'|translate }}</p>
    <div class="suggestions">
      <a routerLink="/">{{ 'header.menu.home'|translate }}</a>
    </div>
  </section>
</div>
