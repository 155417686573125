import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import LINKS, { Link } from '@modules/useful/useful-links/links';
import { TranslateService } from '@ngx-translate/core';
import { LanguageChangeService } from '@ebcont/galaxy';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-useful-links',
  templateUrl: './useful-links.component.html',
  styleUrls: ['./useful-links.component.css']
})
export class UsefulLinksComponent {
  public links: Link[] = LINKS;
  public code = 1;

  constructor(
    private translate: TranslateService,
    private languageChangeService: LanguageChangeService,
    public cd: ChangeDetectorRef
  ) {
    this.languageChangeService.languageChanged$.subscribe(() => {
      this.code++;
      this.cd.detectChanges();
    });
  }

  getIcon(item: Link) {
    return '/assets/useful/' + item.icon + '.svg';
  }

  getHeader(item: Link) {
    return this.translate.currentLang === 'de' ? item.header_de : item.header_en;
  }

  getText(item: Link) {
    return this.translate.currentLang === 'de' ? item.text_de : item.text_en;
  }
}
