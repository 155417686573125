export interface Link {
  rank: number;
  icon: string;
  header_de: string;
  header_en: string;
  text_de: string;
  text_en: string;
  link: string;
}

const LINKS: Link[] = [
  {
    rank: 1,
    icon: 'Apps',
    header_de: 'Arbeitsalltag-Links',
    header_en: 'Workday-Links',
    text_de: 'Eine Ansammlung von weiterführenden Links zu allen im Arbeitsalltag nötigen Tools.',
    text_en: 'A collection of useful links to all the tools you need in your workday.',
    link: 'https://confluence.ebcont.com/pages/viewpage.action?pageId=96047871'
  },
  {
    rank: 2,
    icon: 'Map',
    header_de: 'Sitzplan Wien',
    header_en: 'Floor plan Vienna',
    text_de: 'Hier sind die Sitzpläne in unserem Hauptsitz im Millennium Tower zu finden.',
    text_en: 'Here you can find the floor plans of our main office in the Millennium Tower.',
    link: 'https://confluence.ebcont.com/display/WBE/Wien'
  },
  {
    rank: 3,
    icon: 'Manage-Accounts',
    header_de: 'Administration',
    header_en: 'Administration',
    text_de: 'Unsere Ansprechpersonen für administrative Bereiche im Unternehmen.',
    text_en: 'Our contact persons for administrative areas in the company.',
    link: 'https://confluence.ebcont.com/display/WBE/Administrative+Funktionen'
  },
  {
    rank: 4,
    icon: 'Checklist',
    header_de: 'Der erste Tag',
    header_en: 'The first day',
    text_de: 'Administrative Dokumente und Anleitungen für einen übersichtlichen Start.',
    text_en: 'Administrative documents and instructions for a clear start.',
    link: 'https://confluence.ebcont.com/display/WBE/Der+erste+Tag'
  },
  {
    rank: 5,
    icon: 'Security',
    header_de: 'Software & Security',
    header_en: 'Software & Security',
    text_de: 'Installations- und Einrichtungsanleitungen für Hard- und Software.',
    text_en: 'Installation and setup instructions for hardware and software.',
    link: 'https://confluence.ebcont.com/pages/viewpage.action?pageId=70779030'
  },
  {
    rank: 6,
    icon: 'Vpn-Key',
    header_de: 'VPN',
    header_en: 'VPN',
    text_de: 'Die Anleitung zum Einrichten einer VPN-Verbindung für das externe Zugreifen auf interne Systeme.',
    text_en: 'The guide to setting up a VPN connection for external access to internal systems.',
    link: 'https://confluence.ebcont.com/pages/viewpage.action?pageId=106892014'
  },
  {
    rank: 7,
    icon: 'Rule-Folder',
    header_de: 'Projekt-Guidelines',
    header_en: 'Project Guidelines',
    text_de: 'Hier sind unsere Guidelines zu Projektprozessen, -methoden, und -tools zu finden.',
    text_en: 'Here you can find our guidelines for project processes, methods, and tools.',
    link: 'https://confluence.ebcont.com/display/HOME/Projekt+Guidelines'
  },
  {
    rank: 8,
    icon: 'Edit',
    header_de: 'Vorlagen',
    header_en: 'Templates',
    text_de: 'Eine Übersicht über alle EBCONT-Vorlagen für verschiedenste Formate.',
    text_en: 'An overview of all EBCONT templates for various formats.',
    link: 'https://confluence.ebcont.com/display/HOME/Vorlagen'
  },
  {
    rank: 9,
    icon: 'Punch-Clock',
    header_de: 'SAP Anleitung',
    header_en: 'SAP Guide',
    text_de: 'Eine Anleitung zur korrekten Verwendung von SAP für Zeit- und Projektabbuchungen.',
    text_en: 'A guide to the correct use of SAP for time and project bookings.',
    link: 'https://confluence.ebcont.com/display/SET/SAP'
  },
  {
    rank: 10,
    icon: 'Work-History',
    header_de: 'HR4you Anleitung',
    header_en: 'HR4you Guide',
    text_de: 'Um die Projektzuteilung zu erleichtern, halte dein HR4you-Profil nach diesen Angaben aktuell."',
    text_en: 'To facilitate project allocation, keep your HR4you profile up to date with this information.',
    link: 'https://confluence.ebcont.com/display/HOME/HR4you+-+CV+Maintenance+Guidelines'
  },
  {
    rank: 11,
    icon: 'Extension',
    header_de: 'Toolstack',
    header_en: 'Toolstack',
    text_de: 'Hier sind alle von uns verwendeten Tools übersichtlich zusammengefasst.',
    text_en: 'Here you can find all the tools we use in a clear overview.',
    link: 'https://confluence.ebcont.com/display/HOME/EBCONT+Toolstack'
  },
  {
    rank: 12,
    icon: 'Science',
    header_de: 'EBCONT Labs',
    header_en: 'EBCONT Labs',
    text_de: 'In Labs bearbeiten Mitarbeiter:innen in Stehzeiten interne Projekte.',
    text_en: 'In Labs, employees work on internal projects during slack time.',
    link: 'https://confluence.ebcont.com/display/EBCLABS/Ebcont+LABS+Home'
  },
  {
    rank: 13,
    icon: 'Desk',
    header_de: 'Coworking Space',
    header_en: 'Coworking Space',
    text_de:
      'Hier ist eine Anleitung für das Reservieren eines Schreibtisches im Coworking ' +
      'Space am Standort Wien Millennium Tower zu finden.',
    text_en:
      'Here you can find a guide to reserving a desk in the coworking space at the Vienna Millennium Tower location.',
    link: 'https://confluence.ebcont.com/display/WBE/Coworking+Space'
  },
  {
    rank: 14,
    icon: 'Hub',
    header_de: 'Networking',
    header_en: 'Networking',
    text_de: 'All unsere internen Circles, Meetups und Events auf einen Blick.',
    text_en: 'All our internal circles, meetups and events at a glance.',
    link: 'https://confluence.ebcont.com/display/WBE/Networking'
  },
  {
    rank: 15,
    icon: 'Storefront',
    header_de: 'EBCONT Kaufhaus',
    header_en: 'EBCONT Store',
    text_de: 'Exklusive Rabatte und andere Benefits für verschiedenste Shopping-Bedürfnisse.',
    text_en: 'Exclusive discounts and other benefits for various shopping needs.',
    link: 'https://confluence.ebcont.com/display/WBE/Good+to+know+@EBCONT'
  }
];

export default LINKS;
