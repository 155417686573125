import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { EbcontGalaxyModule } from '@ebcont/galaxy';
import { HocModule } from '@hoc/hoc.module';
import { TeamModule } from '@modules/team/team.module';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from '@core/core.module';
import { GeneralModule } from '@modules/general/general.module';
import { RouterLinkActive } from '@angular/router';
import { SkillListComponent } from '@modules/skill/skill-list/skill-list.component';
import { SkillListItemComponent } from './skill-list-item/skill-list-item.component';
import { SkillListPlaceholderComponent } from './skill-list-placeholder/skill-list-placeholder.component';
import { SkillDetailsComponent } from '@modules/skill/skill-details/skill-details.component';
import { SkillEmployeeListComponent } from '@modules/skill/skill-employee-list/skill-employee-list.component';
import { CoreCompetenceEmployeeListComponent } from '@modules/skill/core-competence-employee-list/core-competence-employee-list.component';

@NgModule({
  declarations: [
    SkillListComponent,
    SkillListItemComponent,
    SkillListPlaceholderComponent,
    CoreCompetenceEmployeeListComponent,
    SkillDetailsComponent,
    SkillEmployeeListComponent
  ],
  exports: [SkillListComponent],
  imports: [
    CommonModule,
    EbcontGalaxyModule,
    HocModule,
    TeamModule,
    TranslateModule,
    CoreModule,
    GeneralModule,
    NgOptimizedImage,
    RouterLinkActive
  ]
})
export class SkillModule {}
