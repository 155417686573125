/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { EmployeeDetails } from '@core/models/employee';
import { EmployeeListProject, getFromDateYearFunction, getToDateYearFunction } from '@core/models/project';
import { asMenuItems, filterInstance, FilterInstance, FILTERS } from '@core/prototypes/filtering';
import {
  AFTER_OPERATION,
  BEFORE_OPERATION,
  BETWEEN_OPERATION,
  DEFAULT_SORTING_STATUS_LIST,
  FilterMethod,
  MenuItem,
  NOT_BETWEEN_OPERATION,
  SORTING_STATUS_DESC,
  SORTING_STATUS_NONE,
  SortingSelectorAdvancedMenuItem
} from '@ebcont/galaxy';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, of } from 'rxjs';
import { getValues, textAsMenuItem } from '@core/utils/utils';

@UntilDestroy()
@Component({
  selector: 'app-employee-projects',
  templateUrl: './employee-projects.component.html',
  styleUrls: ['./employee-projects.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeProjectsComponent implements OnInit, OnChanges {
  @Input() employee: EmployeeDetails | undefined;
  @Input() isAdmin = true;
  projectSearchInputValue = '';

  public projectSortingSelectorItems: SortingSelectorAdvancedMenuItem[] = [];

  protected filtersForBlock: FilterInstance[] = [];
  protected filteredProjects: EmployeeListProject[] = [];
  private projectsSelectedSortingItem?: SortingSelectorAdvancedMenuItem;
  private allNames: string[] = [];

  get activeProjects(): EmployeeListProject[] {
    return this.filteredProjects.filter((elem) => {
      return !elem.isInactive;
    });
  }

  get inactiveProjects(): EmployeeListProject[] {
    return this.filteredProjects.filter((elem) => {
      return elem.isInactive;
    });
  }

  constructor(private translate: TranslateService, public cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.setProjectSortingSelectorItems();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['employee']) {
      this.employee = changes['employee'].currentValue;
      this.projectSearchInputValue = '';

      if (this.employee?.projects) {
        this.filteredProjects = this.employee.projects;
        const projectNames = this.employee.projects.map((project) => {
          return project.name;
        });
        const customerNames = this.employee.projects.map((project) => {
          return project.customerName;
        });
        this.allNames = Array.from(new Set([...projectNames, ...customerNames])).filter((name) => {
          return name;
        });
        this.registerProjectFromDateFilter();
        this.registerProjectToDateFilter();
        this.registerProjectHoursFilter();
        this.registerProjectNamesFilter();
        this.registerCustomersFilter();
      }
    }
  }

  public filterProjects(searchValue: string): void {
    this.projectSearchInputValue = searchValue;
    this.projectFilterChanged();
  }

  public handleChangedSortingStatus(selectedItem: SortingSelectorAdvancedMenuItem): void {
    selectedItem = { ...selectedItem };
    this.projectSortingSelectorItems
      .filter((item) => {
        return item.id !== selectedItem.id;
      })
      .forEach((item) => {
        item.currentSortingStatus = SORTING_STATUS_NONE;
      });

    this.projectsSelectedSortingItem = selectedItem;

    if (selectedItem.currentSortingStatus !== SORTING_STATUS_NONE) {
      this.sortProjects(selectedItem, this.filteredProjects);
    }
  }

  public projectFilterChanged(): void {
    if (this.employee?.projects) {
      this.filteredProjects = this.getFilteredProjects(this.employee.projects);
      this.filteredProjects = this.sortProjects(this.projectsSelectedSortingItem, this.filteredProjects);
    }
  }

  private getFilteredProjects(values: Record<string, any>): EmployeeListProject[] {
    let filteredProjects: EmployeeListProject[] = [...(this.employee?.projects ?? [])];

    if (values['searchValue']) {
      // search value is for filtering by project or customer name
      filteredProjects = filteredProjects.filter((project) => {
        return `${project.name} ${project.customerName}`
          .toLocaleLowerCase()
          .includes(values['searchValue'].toLocaleLowerCase());
      });
    }

    if (values[FILTERS.PROJECT_DATE_END]) {
      const fn: FilterMethod<Date> = values[FILTERS.PROJECT_DATE_END];
      filteredProjects = filteredProjects.filter((project) => {
        return project.toDate && fn(new Date(project.toDate));
      });
    }

    if (values[FILTERS.PROJECT_DATE_BEGIN]) {
      const fn: FilterMethod<Date> = values[FILTERS.PROJECT_DATE_BEGIN];
      filteredProjects = filteredProjects.filter((project) => {
        return project.fromDate && fn(new Date(project.fromDate));
      });
    }

    if (values[FILTERS.PROJECT_HOURS]) {
      const fn: FilterMethod<number> = values[FILTERS.PROJECT_HOURS];
      filteredProjects = filteredProjects.filter((project) => {
        return project.workedHours && fn(project.workedHours);
      });
    }

    if (values[FILTERS.CUSTOMERS]) {
      const filterData = values[FILTERS.CUSTOMERS].split(',');
      filteredProjects = filteredProjects.filter((project) => {
        return project.customerName && filterData.includes(project.customerName);
      });
    }

    if (values[FILTERS.PROJECTS]) {
      const filterData = values[FILTERS.PROJECTS].split(',');
      filteredProjects = filteredProjects.filter((project) => {
        return project.name && filterData.includes(project.name);
      });
    }

    return filteredProjects;
  }

  private setProjectSortingSelectorItems(): void {
    this.projectSortingSelectorItems = [
      {
        id: 'fromDate',
        label: this.translate.instant('sorting.names.project.date_begin'),

        sortingStatusList: DEFAULT_SORTING_STATUS_LIST,
        currentSortingStatus: SORTING_STATUS_NONE
      },
      {
        id: 'toDate',
        label: this.translate.instant('sorting.names.project.date_end'),

        sortingStatusList: DEFAULT_SORTING_STATUS_LIST,
        currentSortingStatus: SORTING_STATUS_NONE
      },
      {
        id: 'customerName',
        label: this.translate.instant('sorting.names.project.customer'),

        sortingStatusList: DEFAULT_SORTING_STATUS_LIST,
        currentSortingStatus: SORTING_STATUS_NONE
      },
      {
        id: 'name',
        label: this.translate.instant('sorting.names.project.name'),
        sortingStatusList: DEFAULT_SORTING_STATUS_LIST,
        currentSortingStatus: SORTING_STATUS_NONE
      }
    ];

    if (this.isAdmin) {
      this.projectSortingSelectorItems.push({
        id: 'workedHours',
        label: this.translate.instant('sorting.names.project.hours'),
        sortingStatusList: DEFAULT_SORTING_STATUS_LIST,
        currentSortingStatus: SORTING_STATUS_NONE
      });
    }
  }

  private sortProjects(
    sortingOperation: SortingSelectorAdvancedMenuItem | undefined,
    filteredProjects: EmployeeListProject[]
  ): EmployeeListProject[] {
    if (!sortingOperation || sortingOperation.currentSortingStatus === SORTING_STATUS_NONE) {
      return filteredProjects;
    }

    const sortingBy = this.getSortingBy(sortingOperation.id);
    const direction = sortingOperation.currentSortingStatus === SORTING_STATUS_DESC ? -1 : 1;
    return filteredProjects.sort((a, b) => {
      if (!Reflect.get(a, sortingBy)) {
        return 1;
      }
      if (!Reflect.get(b, sortingBy)) {
        return -1;
      }
      return (Reflect.get(a, sortingBy) < Reflect.get(b, sortingBy) ? -1 : 1) * direction;
    });
  }

  private getSortingBy(id: string): string {
    return ['customerName', 'name', 'workedHours', 'toDate', 'fromDate'].includes(id) ? id : 'name';
  }

  private registerProjectNamesFilter() {
    this.filtersForBlock.push(
      this.getDefaultItemsFilter(
        FILTERS.PROJECTS,
        asMenuItems(
          (this.employee?.projects ?? []).map((project) => {
            return project.name;
          }),
          false
        ),
        {}
      )
    );
  }

  private registerProjectToDateFilter() {
    const thisYear = new Date().getFullYear();
    const toDates = getValues<EmployeeListProject, number>(this.employee?.projects ?? [], getToDateYearFunction);
    toDates.push(thisYear);
    const minDate = toDates.sort()[0];
    const maxDate = toDates.sort().reverse()[0];
    this.filtersForBlock.push(
      filterInstance(FILTERS.PROJECT_DATE_END, [], {
        filterType: 'date',
        methodNeeded: true,
        operationNeeded: false,
        dateFilterParams: {
          defaultOperation: BEFORE_OPERATION,
          operations: [BEFORE_OPERATION, AFTER_OPERATION, BETWEEN_OPERATION, NOT_BETWEEN_OPERATION],
          minYear: minDate,
          maxYear: maxDate,
          iconAt: 'right'
        }
      })
    );
  }

  private registerProjectHoursFilter() {
    this.filtersForBlock.push(
      filterInstance(FILTERS.PROJECT_HOURS, [], {
        filterType: 'number',
        methodNeeded: true,
        operationNeeded: false
      })
    );
  }

  private registerProjectFromDateFilter() {
    const thisYear = new Date().getFullYear();
    const fromDates = getValues<EmployeeListProject, number>(this.employee?.projects ?? [], getFromDateYearFunction);
    fromDates.push(thisYear);
    const minDate = fromDates.sort()[0];
    const maxDate = fromDates.sort().reverse()[0];
    this.filtersForBlock.push(
      filterInstance(FILTERS.PROJECT_DATE_BEGIN, [], {
        filterType: 'date',
        methodNeeded: true,
        operationNeeded: false,
        dateFilterParams: {
          defaultOperation: BEFORE_OPERATION,
          operations: [BEFORE_OPERATION, AFTER_OPERATION, BETWEEN_OPERATION, NOT_BETWEEN_OPERATION],
          minYear: minDate,
          maxYear: maxDate,
          iconAt: 'right'
        }
      })
    );
  }

  private registerCustomersFilter() {
    this.filtersForBlock.push(
      this.getDefaultItemsFilter(
        FILTERS.CUSTOMERS,
        asMenuItems(
          (this.employee?.projects ?? []).map((project) => {
            return project.customerName;
          }),
          false
        ),
        {}
      )
    );
  }

  private getDefaultItemsFilter(
    filterKey: FILTERS,
    menuItems: MenuItem[],
    options: Partial<FilterInstance>
  ): FilterInstance {
    return filterInstance(filterKey, menuItems, {
      ...{
        filterOptions: 'items',
        switchMode: false,
        maxLabels: 3,
        isMulti: true,
        searchable: true
      },
      ...options
    });
  }

  protected searchForName = (text: string): Observable<MenuItem[]> => {
    return of(
      this.allNames
        .filter((name) => {
          return name.toLocaleLowerCase().includes(text.toLocaleLowerCase());
        })
        .map((name) => {
          return textAsMenuItem(name);
        })
    );
  };

  filterBlockChanged(value: Record<string, any>) {
    if (!this.employee?.projects) {
      return;
    }
    this.filteredProjects = this.getFilteredProjects(value);
    this.cd.detectChanges();
  }
}
