export enum InjectorKeys {
  CONFIGURATION = 'CONFIGURATION',
  EMPLOYEES_SERVICE = 'EMPLOYEES_SERVICE',
  EMPLOYEE_DETAILS_SERVICE = 'EMPLOYEE_DETAILS_SERVICE',
  FACETS_SERVICE = 'FACETS_SERVICE',
  STATUS_SERVICE = 'STATUS_SERVICE',
  HEALTH_CHECK_SERVICE = 'HEALTH_CHECK_SERVICE',
  CUSTOMERS_SERVICE = 'CUSTOMERS_SERVICE',
  PROJECTS_SERVICE = 'PROJECTS_SERVICE',
  ENVIRONMENT = 'ENVIRONMENT',
  DASHBOARD = 'DASHBOARD',
  SKILL = 'SKILL',
  EMPLOYEES_SKILL_SERVICE = 'EMPLOYEES_SKILL_SERVICE',
  EMPLOYEES_CORE_COMPETENCE_SERVICE = 'EMPLOYEES_CORE_COMPETENCE_SERVICE'
}
