<!-- Top nav-->
<gx-toast></gx-toast>
<gx-dialog></gx-dialog>
<gx-drawer-blocking></gx-drawer-blocking>

<hoc-yesnodialog dialogId="custom"></hoc-yesnodialog>

<header class="relative flex items-center border-b border-ui-border bg-ui-surface-rise">
  <!-- Logo area -->
  <gx-brand>&nbsp;&nbsp;Maps-v3 ({{ applicationConfigurationService.version }})</gx-brand>
  <div class="mx-4 flex flex-1 items-center justify-end gap-3">
    <!-- Search area -->

    <hoc-download-status></hoc-download-status>

    <div class="hidden sm:block">
      <template [ngTemplateOutlet]="userMenu"></template>
    </div>

    <!-- Mobile menu button -->
    <div class="inline-flex lg:hidden">
      <gx-button text="Open main menu" variant="neutral" icon="bars" hasIcon="only" (click)="toggleOffCanvasMenu()"/>
    </div>
  </div>
</header>

<!-- Mobile menu, show/hide this div based on menu open/closed state -->
<div *ngIf="isOffCanvasMenuDialog" class="absolute inset-0 z-40 lg:hidden" role="dialog" aria-modal="true">
  <div @opacity *ngIf="isOffCanvasMenu" class="absolute inset-0 bg-black/60"></div>
  <div @opacityScaleUp *ngIf="isOffCanvasMenu" (click)="toggleOffCanvasMenu()" class="fixed inset-0 z-40 sm:hidden">
    <div
      class="fixed inset-0 z-40 flex size-full flex-col bg-ui-surface-rise sm:inset-y-0 sm:left-auto sm:right-0 sm:w-full sm:max-w-sm sm:shadow-lg"
      aria-label="Global">
      <div class="flex items-center justify-between">
        <!-- Logo area -->
        <gx-brand/>
        <div class="ml-auto mr-4 inline-flex items-center justify-center">
          <gx-button
            text="Close main menu"
            variant="neutral"
            icon="close"
            hasIcon="only"
            (click)="toggleOffCanvasMenu()"/>
        </div>
      </div>
      <!-- menu -->
      <div class="flex flex-row justify-end border-y border-ui-border p-4">
        <template [ngTemplateOutlet]="userMenu"></template>
      </div>
      <hoc-navigation/>
      <div class="grow">&nbsp;</div>
    </div>
  </div>
  <div @translateX *ngIf="isOffCanvasMenu" (click)="toggleOffCanvasMenu()" class="fixed inset-0 z-40 hidden sm:block">
    <div
      class="fixed inset-0 z-40 size-full bg-ui-surface-rise sm:inset-y-0 sm:left-auto sm:right-0 sm:w-full sm:max-w-sm sm:shadow-lg"
      aria-label="Global">
      <div class="flex items-center justify-between pr-4">
        <!-- Logo area -->
        <gx-brand/>
        <div class="ml-auto inline-flex items-center justify-center">
          <gx-button
            text="Close main menu"
            variant="neutral"
            icon="close"
            hasIcon="only"
            (click)="toggleOffCanvasMenu()"/>
        </div>
      </div>
      <!-- Search area -->
      <div class="mx-4 flex flex-1"></div>
      <hoc-navigation/>
      <div class="border-t border-ui-border pb-3 pt-4">
        <hoc-user-info/>
        <gx-menu/>
      </div>
    </div>
  </div>
</div>

<!-- Bottom section -->
<div class="flex min-h-0 flex-1 overflow-hidden">
  <!-- Sidebar-->
  <div class="hidden border-r border-ui-border bg-ui-surface-rise lg:block">
    <hoc-navigation/>
  </div>

  <!-- Main area -->
  <main class="min-w-0 flex-1 flex-col @container md:flex">
    <gx-breadcrumbs></gx-breadcrumbs>

    <div
      aria-labelledby="primary-heading"
      class="flex size-full min-w-0 flex-1 @container lg:order-last xl:flex-row">
      <!-- TODO: if the Drawer is implemented here - we can solve probably the layout issues. -->
      <router-outlet></router-outlet>
    </div>
  </main>
</div>


<ng-template #userMenu>
  <div class="flex items-center gap-3">
    <hoc-profile-image [value]="email" [profilePicture]="true"/>

    <hoc-user-menu
      [label]="fullName"
      [items]="userMenuItems"
      (navigation)="toggleOffCanvasMenu(false)"
      menuIcon="user"
    />
    <gx-language-menu/>
    <gx-theme-button/>
  </div>
</ng-template>
