import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Employee } from '@core/models/employee';
import { TranslateService } from '@ngx-translate/core';
import { Description } from '@ebcont/galaxy';

@Component({
  selector: 'app-team-list-item',
  templateUrl: './team-list-item.component.html',
  styleUrls: ['./team-list-item.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamListItemComponent implements OnInit {
  @Input() employee: Employee = {} as Employee;
  @Input() isExtended = false;
  @Output() employeeSelected = new EventEmitter<string>();

  items: Description[] = [];

  constructor(private translate: TranslateService) {
    this.items = [];
  }

  ngOnInit() {
    this.items = [
      {
        term: this.employee.companyName ?? this.translate.instant('error.not_available'),
        description: this.employee.email ?? this.translate.instant('error.not_available')
      },
      {
        term: this.employee.location ?? this.translate.instant('error.not_available'),
        description: this.employee.phone || this.translate.instant('error.not_available')
      }
    ];
  }

  public selectEmployee() {
    this.employeeSelected.emit(this.employee.email);
  }
}
