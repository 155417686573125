import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Input } from '@angular/core';
import { SIMPLE_EVENTS } from '@core/utils/utils';

@Component({
  selector: 'hoc-download-status',
  templateUrl: './hoc-download-status.component.html',
  styleUrls: ['./hoc-download-status.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HocDownloadStatusComponent {
  public total = 100;
  public current = 0;
  public progress = 0;
  public status: 'starting' | 'progress' | 'completed' | 'none' = 'none';
  /** The time in milliseconds to clear the status after a download is completed */
  @Input() clearAfter = 3000;

  constructor(public cd: ChangeDetectorRef) {}

  /**
   * When a download starting, we expect to receive an event with the total number chunks
   * @param event The custom event, detail must contain the total number of chunks
   */
  @HostListener(`document:${SIMPLE_EVENTS.DOWNLOAD_STARTING}`, ['$event'])
  protected downloadStarting(event: Event): void {
    const customEvent = event as CustomEvent;
    this.status = 'starting';
    this.current = 0;
    this.progress = 0;
    this.total = customEvent.detail;
  }

  /**
   * When a download progress, we expect to receive an event with the filter and the result in the payload.
   * NOTE: in this use case we do not need neither the filter nor the result.
   */
  @HostListener(`document:${SIMPLE_EVENTS.DOWNLOAD_PROGRESS}`, ['$event'])
  protected downloadProgress(): void {
    this.status = 'progress';
    this.current += 1;
    this.progress = Math.floor((this.current / this.total) * 100);
  }

  @HostListener(`document:${SIMPLE_EVENTS.DOWNLOAD_COMPLETED}`, ['$event'])
  protected downloadCompleted(): void {
    this.status = 'completed';
    setTimeout(() => {
      this.status = 'none';
      this.current = 0;
      this.total = 0;
      this.progress = 0;
      this.cd.detectChanges();
    }, this.clearAfter);
  }
}
