<div class="card" [ngClass]="!name ? 'loading' : ''">
  <div class="mx-auto size-20 self-center overflow-hidden rounded-full">
    <img class="object-cover"
         [src]="(image | profileImage | async) ?? '/assets/images/image-avatar-fallback.svg' | sanitize" alt="">
  </div>
  <div class="flex flex-col flex-wrap gap-4 @md:flex-row">
    <div class="flex-1">
      <h1 class="name mb-1 truncate p-0 text-3xl font-semibold @xl:text-4xl">{{ name }}</h1>
      <gx-description-list
        [isTermHidden]="true"
        [items]="items"/>
    </div>
    <div class="flex w-full flex-row items-end justify-end gap-4 pr-4">
      <gx-button
        variant="prime"
        [text]="'action.email' | translate"
        (click)="mailto()"
      />
      <gx-button
        [text]="'action.phone_call' | translate"
        (click)="call()"
      />
      <gx-dropdown
        *ngIf="isAdmin || isMyProfile()"
        [isSelectable]="false"
        [icon]="ellipsisIcon"
        [menuItems]="menuItems"
        (menuItemSelected)="onDropDownClick($event)"
      />
    </div>
  </div>
</div>
<hoc-profile-download [email]="employeeDetails?.email"/>
