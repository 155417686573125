import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from '@modules/general/dashboard/dashboard.component';
import { PageNotFoundComponent } from '@modules/general/page-not-found/pagenotfound.component';
import { TeamListComponent } from '@modules/team/components/team-list/team-list.component';
import { environment } from '@env/environment';
import { MsalGuard } from '@azure/msal-angular';

import { GeneralModule } from '@modules/general/general.module';
import { TeamModule } from '@modules/team/team.module';
import { HocModule } from '@hoc/hoc.module';
import { HocAccessDeniedComponent } from '@hoc/hoc-access-denied/hoc-access-denied.component';
import { CustomerListComponent } from '@modules/customer/components/customer-list/customer-list.component';
import { ProjectListComponent } from '@modules/project/project-list/project-list.component';
import { SettingsComponent } from '@modules/general/settings/settings.component';
import { SkillListComponent } from '@modules/skill/skill-list/skill-list.component';
import { UsefulLinksComponent } from '@modules/useful/useful-links/useful-links.component';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'access-denied', component: HocAccessDeniedComponent },
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: { roles: [environment.roles.superUser, environment.roles.user] },
    runGuardsAndResolvers: 'always',
    canActivate: [MsalGuard],
    canActivateChild: [MsalGuard]
  },
  {
    path: 'team',
    component: TeamListComponent,
    data: { roles: [environment.roles.superUser, environment.roles.user] },
    runGuardsAndResolvers: 'always',
    canActivate: [MsalGuard],
    canActivateChild: [MsalGuard]
  },
  {
    path: 'customer',
    component: CustomerListComponent,
    data: { roles: [environment.roles.superUser, environment.roles.user] },
    runGuardsAndResolvers: 'always',
    canActivate: [MsalGuard],
    canActivateChild: [MsalGuard]
  },
  {
    path: 'useful',
    component: UsefulLinksComponent,
    data: { roles: [environment.roles.superUser, environment.roles.user] },
    runGuardsAndResolvers: 'always',
    canActivate: [MsalGuard],
    canActivateChild: [MsalGuard]
  },
  {
    path: 'projects',
    component: ProjectListComponent,
    data: { roles: [environment.roles.superUser, environment.roles.user] },
    runGuardsAndResolvers: 'always',
    canActivate: [MsalGuard],
    canActivateChild: [MsalGuard]
  },
  {
    path: 'settings',
    component: SettingsComponent,
    data: { roles: [environment.roles.superUser, environment.roles.user] },
    runGuardsAndResolvers: 'always',
    canActivate: [MsalGuard],
    canActivateChild: [MsalGuard]
  },
  {
    path: 'skills',
    component: SkillListComponent,
    data: { roles: [environment.roles.superUser, environment.roles.user] },
    runGuardsAndResolvers: 'always',
    canActivate: [MsalGuard],
    canActivateChild: [MsalGuard]
  },
  // { path: 'employees/employee', component: EmployeeComponent },
  // { path: 'skills', component: SkillsComponent },
  // {
  //   path: 'help',
  //   component: HelpComponent,
  //   data: { roles: [environment.roles.superUser, environment.roles.user] },
  //   runGuardsAndResolvers: 'always',
  //   canActivate: [MsalGuard],
  //   canActivateChild: [MsalGuard]
  // },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled'
    }),
    GeneralModule,
    TeamModule,
    HocModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [RouterModule]
})
export class AppRoutingModule {}
