<div *ngIf="employee">
  <div class="flex gap-3">
    <gx-description-list (clickedItem)="selectFiltersForClickedItem($event)" [isClickable]="true"
                         [items]="employee | employeeToItem"/>
  </div>
  <section>
    <div class="flex items-baseline gap-3">
      <gx-section-title [title]="'team.competences' | translate" class="flex-1"/>
      <gx-dropdown
        *ngIf="getSortedCompetences().length > 0"
        (menuItemSelected)="competenceSortingChanged($event)"
        [createCaption]="captionForSorting"
        [defaultSelectedItems]="currentCompetenceSort"
        [icon]="downIcon"
        [isRightAligned]="true"
        [menuItems]="competenceSortItems"
        [multiSelect]="false"
        label="sorting.caption"/>
    </div>
    <ng-container [ngTemplateOutlet]="competences"/>
  </section>
  <section>
    <gx-section-title [title]="'team.education' | translate" class="flex-1"/>
    <ng-container [ngTemplateOutlet]="education"/>
  </section>
  <section>
    <gx-section-title [title]="'team.groups' | translate"/>
    <ul class="flex flex-col gap-2 lg:flex-row">
      <div *ngIf="employee.boards.length <= 0" class="italic">{{'employee.profile.no_groups' | translate}}</div>
      <li *ngFor="let board of employee.boards">
        <gx-pill (click)="boardClicked(board)" [caption]="board"/>
      </li>
    </ul>
  </section>
</div>


<ng-template #competences>
  <ol class="flex flex-col divide-y" *ngIf="getSortedCompetences().length > 0">
    <li
      *ngFor="let competence of getSortedCompetences()"
      class="flex flex-col items-start gap-1 py-3 @lg:flex-row @lg:gap-3">
      <div class="flex-1">{{ competence.name }}</div>
      <div class="flex w-full justify-between gap-3 @lg:w-auto">
        <div class="text-sm">{{ competence.duration }}</div>
        <gx-rating [rating]="competence.levelInt" activeRatingClass="__full-star"
                   inactiveRatingClass="__empty-star"></gx-rating>
      </div>
    </li>
  </ol>
  <div *ngIf="getSortedCompetences().length <= 0" class="italic">{{'employee.profile.no_competences' | translate}}</div>
</ng-template>


<ng-template #education>
  <ol class="flex flex-col divide-y" *ngIf="getEducation().length > 0">
    <li *ngFor="let education of getEducation()"
        class="flex flex-col items-start gap-1 py-3 @lg:flex-row @lg:gap-3">
      <div class="flex-1">{{ education }}</div>
    </li>
  </ol>
  <div *ngIf="getEducation().length <= 0" class="italic">{{'employee.profile.no_education' | translate}}</div>
</ng-template>
