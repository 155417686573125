<div class="group/item item">
  <div class="__infos">
    <div class="name">&nbsp;</div>
    <div class="name">&nbsp;</div>
  </div>
  <div class="__infos">
    <div class="name">&nbsp;</div>
    <div class="name">&nbsp;</div>
  </div>
</div>


