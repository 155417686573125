<div *ngIf="factSheet">
  <div *ngFor="let data of data" class="flex flex-col">
    <h3 class="mt-1 font-bold">{{ data[0] }}</h3>
    <p>{{ data[1] }}</p>
  </div>
  <!--  <div class="flex flex-col" *ngIf="factSheet.overview?.projectDescription">-->
  <!--    <h3 class="font-bold mt-1">{{ 'project.factSheet.overview.description' | translate }}</h3>-->
  <!--    <p>{{}}</p>-->
  <!--  </div>-->
  <!--  <div class="flex flex-col" *ngIf="factSheet.overview?.issueTracking">-->
  <!--    <h3 class="font-bold">{{ 'project.factSheet.overview.issueTracking' | translate }}</h3>-->
  <!--    <p>{{ factSheet.overview?.issueTracking }}</p>-->
  <!--  </div>-->
  <!--  <div class="flex flex-col" *ngIf="factSheet.overview?.fileStorageSystem">-->
  <!--    <h3 class="font-bold">{{ 'project.factSheet.overview.documentFilingSystem' | translate }}</h3>-->
  <!--    <p>{{ factSheet.overview?.fileStorageSystem }}</p>-->
  <!--  </div>-->
  <!--  <div class="flex flex-col" *ngIf="factSheet.overview?.implementationTime">-->
  <!--    <h3 class="font-bold">{{ 'project.factSheet.overview.implementationSpan' | translate }}</h3>-->
  <!--    <p>{{ factSheet.overview?.implementationTime }}</p>-->
  <!--  </div>-->
  <!--  <div class="flex flex-col" *ngIf="factSheet.overview?.peopleInAuthority">-->
  <!--    <h3 class="font-bold">{{ 'project.factSheet.overview.responsiblePeople' | translate }}</h3>-->
  <!--    <p>{{ factSheet.overview?.peopleInAuthority }}</p>-->
  <!--  </div>-->
  <!--  <div class="flex flex-col" *ngIf="factSheet.overview?.averageRisk">-->
  <!--    <h3 class="font-bold">{{ 'project.factSheet.overview.risks' | translate }}</h3>-->
  <!--    <p>{{ factSheet.overview?.averageRisk }}</p>-->
  <!--  </div>-->
  <!--  <div class="flex flex-col" *ngIf="factSheet.overview?.sapProjects">-->
  <!--    <h3 class="font-bold">{{ 'project.factSheet.overview.sap' | translate }}</h3>-->
  <!--    <p>{{ factSheet.overview?.sapProjects }}</p>-->
  <!--  </div>-->
</div>
