import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Project } from '@core/models/project';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-project-list-item',
  templateUrl: './project-list-item.component.html',
  styleUrls: ['./project-list-item.component.css']
})
export class ProjectListItemComponent {
  @Input() isAdmin = false;
  @Input() project: Project = {} as Project;

  constructor(private translate: TranslateService) {}
}
