/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/no-non-null-assertion */
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { EmployeeDetailsService } from '@core/services/employee/employee.details.service';
import { InjectorKeys } from '@core/utils/injector.keys';
import { EMPTY, Observable } from 'rxjs';
import { EmployeeDetails } from '@core/models/employee';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { UserService } from '@core/services/user/user.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-employee-details',
  templateUrl: './employee-details.component.html',
  styleUrls: ['./employee-details.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeDetailsComponent implements OnInit, OnChanges {
  @Input() employeeId: string | undefined;
  @Input() openIcon: IconProp = faPlus;
  @Input() closeIcon: IconProp = faMinus;
  @Output() closeClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() companySelected: EventEmitter<string> = new EventEmitter<string>();
  @Output() locationSelected: EventEmitter<string> = new EventEmitter<string>();
  @Output() boardSelected: EventEmitter<string> = new EventEmitter<string>();

  public employee$: Observable<EmployeeDetails> = EMPTY;
  public isAdmin = false;

  constructor(
    @Inject(InjectorKeys.EMPLOYEE_DETAILS_SERVICE) private service: EmployeeDetailsService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.reloadEmployee();
    this.updateAdminStatus();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['employeeId']) {
      this.reloadEmployee();
    }
  }

  public onCloseButtonClicked(): void {
    this.closeClicked.emit();
  }

  private updateAdminStatus() {
    this.userService
      .getAdminStatus()
      .pipe(untilDestroyed(this))
      .subscribe((value: boolean) => {
        this.isAdmin = value;
      });
  }

  private reloadEmployee() {
    this.employee$ = this.employeeId ? this.service.get(this.employeeId) : EMPTY;
  }

  public companySelect(company: string) {
    this.companySelected.emit(company);
  }

  public locationSelect(location: string) {
    this.locationSelected.emit(location);
  }

  public boardSelect(board: string) {
    this.boardSelected.emit(board);
  }
}
