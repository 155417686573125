<div class="w-full">
  <hoc-grouped-list
    [loading]="loading"
    [groupLetters]="groupLetters"
    [items]="items"
    [headerBlock]="headerBlock"
    [loadingBlock]="loadingBlock"
    [footerBlock]="footerBlock"
    [groupBlock]="groupBlock"
    [itemBlock]="itemBlock"
    [emptyItemsBlock]="emptyItemsBlock"
    (scrolledToEnd)="scrolledToEnd()">
  </hoc-grouped-list>
</div>


<ng-template #groupBlock let-letter>
  <h3 class="list-title-text">{{ letter }}</h3>
</ng-template>

<ng-template #loadingBlock>
  <ng-container *ngTemplateOutlet="loadingTitle"></ng-container>
  <ng-container *ngFor="let item of loadingSkeletonSize">
    <app-team-list-placeholder [isExtended]="true"/>
  </ng-container>
</ng-template>

<ng-template #loadingTitle>
  <div class="list-title">
    <h3 class="list-title-text my-1 ms-4"></h3>
  </div>
</ng-template>

<ng-template #itemBlock let-item>
  <a class="item" [href]="getPersonLink(item)">
    <div class="__image">
      <img
        [src]="(item.photo | profileImage | async) ?? '/assets/images/image-avatar-fallback.svg' | sanitize"
        alt=""/>
    </div>
    <div class="__infos">
      <div class="name">{{ item.businessPartnerFormattedName }}</div>
      <div>{{ item.roleDescription || 'n/a' }}</div>
    </div>
  </a>
</ng-template>

<ng-template #emptyItemsBlock>
  <app-empty-details-list-page/>
</ng-template>

<ng-template #footerBlock>
  <div #footerContainer class="px-3 py-4">
    <!-- TODO: Add a <gx-button>Load more</gx-button> also with infinite scroll. in case something is not working user has control!-->
    <gx-pagination
      *ngIf="loadMode === 'paged' && items.size > 0"
      [data]="pagination"
      (newPage)="gotoPage($event)"
      [availablePageSizes]="config.pageSizes"
      [currentPageSize]="filter.pageSize"
      [lengthBeginSegment]="2"
      [lengthEndSegment]="2"
      (newPageSize)="newPageSize($event)"/>
    <app-team-list-placeholder
      *ngIf="loadMode === 'infinite' && canLoadMore"
      [isExtended]="true"
      (click)="scrolledToEnd()"/>
  </div>
</ng-template>

<ng-template #headerBlock>
  <div>{{ 'skill.employees' | translate: {count: pagination.totalItems} }}</div>
</ng-template>
