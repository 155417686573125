/* eslint-disable unused-imports/no-unused-vars,@typescript-eslint/no-unused-vars,@typescript-eslint/no-explicit-any */
/* @typescript-eslint/no-this-alias,@typescript-eslint/no-explicit-any */
import { firstValueFrom, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NotImplementedError } from '@ebcont/galaxy';
import { ListResult } from './list.result';
import { downloadJSONAsXLSX, emitSimpleEvent, SIMPLE_EVENTS } from '@core/utils/utils';

export interface TypeaheadItem {
  id: string;
  name: string;
}

interface DownloadParams {
  filter: object;
  pageNumber: number;
  pageSize: number;
}

/**
 * Defines a generic backend service.
 */
export interface BackendService<T> {
  /**
   * Returns an item by id.
   * @param id The id of the item to return.
   * @returns An observable of the item.
   */
  get(id: string): Observable<T>;

  /**
   * Returns a list of items.
   * @param filter The filter to apply to the list.
   * @param emitEvent If true, an `EVENTS.DOWNLOAD_PROGRESS` event will be emitted when the list is retrieved, the
   * event payload will contain the filter and the results
   * @returns An observable of the list.
   */
  list(filter: unknown, emitEvent?: boolean): Observable<ListResult<T>>;

  /**
   * Creates a new item.
   * @param item The item to create.
   * @returns An observable of the created item.
   */
  create(item: T): Observable<T>;

  /**
   * Updates an existing item.
   * @param item  The item to update.
   * @returns An observable of the updated item.
   */
  update(item: T): Observable<T>;

  /**
   * Deletes an item.
   * @param id  The id of the item to delete.
   * @returns An observable of the deleted item.
   */
  delete(id: string): Observable<T>;

  /**
   * Returns a list of typeahead items, usually list of names.
   * @param text The text to search for.
   * @returns An observable of the list of typeahead items.
   */
  typeahead(text: string): Observable<TypeaheadItem[]>;

  /**
   * Returns a list of autocomplete items, usually list of names.
   * @param text The text to search for.
   * @param subPath The sub-path to use for the autocomplete (e.g. "/skills" to "autocomplete/skills").
   * @returns An observable of the list of autocomplete items.
   */
  autocomplete(text: string, subPath?: string): Observable<string[]>;

  /**
   * Downloads a list into a local file.
   * @param filter The filter to use, will be written also into the "filter parameters" worksheet
   * @param mapper The mapper function to create the records going into the "data" worksheet
   * @param fileName The name of the file to download, "xlsx" will be automatically appended
   * @param totalItems
   * @param stepSize
   */
  downloadList(
    filter: unknown,
    mapper: DownloadMapperFunction,
    fileName: string,
    totalItems: number,
    stepSize?: number
  ): Promise<void>;
}

/**
 * A function to map an object item to a record.
 * @param item The item to map.
 * @returns The mapped record.
 */
export type DownloadMapperFunction = (item: object) => Record<string, string | number | boolean>;

export abstract class AbstractBackendService<T> implements BackendService<T> {
  protected constructor(protected endPointUrl: string, protected http: HttpClient) {}

  protected cache: Map<string, unknown> = new Map<string, unknown>();

  protected pushToCache<U>(key: string, item: U, timeout = 10000): void {
    this.cache.set(key, item);
    setTimeout(() => {
      this.cache.delete(key);
    }, timeout);
  }

  protected getFromCache<U>(key: string): Promise<U | undefined> {
    return Promise.resolve(this.cache.has(key) ? (this.cache.get(key) as U) : undefined);
  }

  create(item: T): Observable<T> {
    throw new NotImplementedError('AbstractBackendService.create');
  }

  delete(id: string): Observable<T> {
    throw new NotImplementedError('AbstractBackendService.delete');
  }

  get(id: string): Observable<T> {
    throw new NotImplementedError('AbstractBackendService.get');
  }

  list(filter: unknown, emitEvent?: boolean): Observable<ListResult<T>> {
    throw new NotImplementedError('AbstractBackendService.list');
  }

  update(item: T): Observable<T> {
    throw new NotImplementedError('AbstractBackendService.item');
  }

  typeahead(text: string): Observable<TypeaheadItem[]> {
    throw new NotImplementedError('AbstractBackendService.typeahead');
  }

  autocomplete(text: string, subPath?: string): Observable<string[]> {
    throw new NotImplementedError('AbstractBackendService.typeahead');
  }

  async parallelDownload(params: object[], threads = 5): Promise<any[]> {
    const result: any = [];
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    while (params.length) {
      const res = await Promise.all(
        params.splice(0, threads).map((param) => {
          return firstValueFrom(self.list(param, true));
        })
      );
      result.push(res);
    }
    return result.flat();
  }

  downloadList(
    filter: unknown,
    mapper: DownloadMapperFunction,
    fileName: string,
    totalItems: number,
    stepSize?: number
  ): Promise<void> {
    return new Promise((resolve) => {
      resolve(); // resolve at once, so it can run in the background
      const totalPages = Math.ceil(totalItems / (stepSize ?? 100));
      emitSimpleEvent(SIMPLE_EVENTS.DOWNLOAD_STARTING, totalPages + 1);
      const pages = Array.from(Array(totalPages).keys());
      const params = pages.map((pageNumber) => {
        return { ...(filter as object), pageNumber, pageSize: stepSize ?? 100 } as object;
      });
      this.parallelDownload(params, 2)
        .then((chunks) => {
          emitSimpleEvent(SIMPLE_EVENTS.DOWNLOAD_PROGRESS, { filter, result: undefined });
          const hits: T[] = [];
          chunks
            .sort((a: ListResult<T>, b: ListResult<T>) => {
              return a.currentStartIndex - b.currentStartIndex;
            })
            .forEach((chunk: ListResult<T>) => {
              hits.push(...chunk.results);
            });
          const mappedHits = hits.map((item) => {
            return mapper(item as object);
          });
          downloadJSONAsXLSX(mappedHits, filter, fileName);
          emitSimpleEvent(SIMPLE_EVENTS.DOWNLOAD_COMPLETED);
        })
        .catch((error) => {
          console.error('cannot download list', error);
        });
    });
  }
}
