import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Customer } from '@core/models/customer';

@Component({
  selector: 'app-customer-list-item',
  templateUrl: './customer-list-item.component.html',
  styleUrls: ['./customer-list-item.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerListItemComponent {
  @Input() customer: Customer = {} as Customer;
  @Input() isAdmin = false;
  @Input() isSmallScreen = false;
}
