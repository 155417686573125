import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  Input,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { GxDropdownComponent, ignorePromise, MenuItem, slideInFromRight } from '@ebcont/galaxy';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AbstractGroupedList } from '@hoc/hoc-grouped-list';
import { environment } from '@env/environment';
import {
  skillDownloadMapper,
  SkillFilterBuilder,
  SkillListItem,
  skillListItemGroupingOperator,
  updateSkillFilter
} from '@core/models/skill';
import { InjectorKeys } from '@core/utils/injector.keys';
import { BackendService } from '@core/prototypes/backend.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationConfiguration } from '@core/services/configuration/application.configuration.interface';
import { UserService } from '@core/services/user/user.service';
import { Observable } from 'rxjs';
import { SkillsService } from '@core/services/employee/skills.service';
import { map } from 'rxjs/operators';
import { SIMPLE_EVENTS, textAsMenuItem } from '@core/utils/utils';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-skill-list',
  templateUrl: './skill-list.component.html',
  styleUrls: ['./skill-list.component.css'],
  animations: [slideInFromRight]
})
export class SkillListComponent extends AbstractGroupedList<SkillListItem> implements OnInit {
  @Input() isExtended = true;
  @ViewChild('footerContainer') footerBlock: TemplateRef<Element> | undefined;
  @ViewChild('titleBlock') titleBlock: HTMLDivElement | undefined;

  searchValue = '';
  autocompleteData: MenuItem[] = [];
  loadingSkeletonSize = new Array(environment.loadingSkeletonSize);
  isAdmin = false;
  skills: SkillListItem[] = [];
  totalNumberResultsets = 0;

  public isDrawerOpen = false;
  public selectedSkill: SkillListItem | undefined;

  protected initialized = false;

  /** The choices to search for. */
  public searchForOptions: MenuItem[] = [
    {
      label: 'skill.plural',
      isRouterLink: false,
      id: 'skill'
    },
    {
      label: 'coreCompetence.plural',
      isRouterLink: false,
      id: 'coreCompetence'
    }
  ];
  /** The actual choice to search for. */
  public searchFor = this.searchForOptions[0];
  public listIsDownloading = false;

  constructor(
    @Inject(InjectorKeys.SKILL) private service: BackendService<SkillListItem>,
    cd: ChangeDetectorRef,
    router: Router,
    route: ActivatedRoute,
    @Inject(InjectorKeys.CONFIGURATION) public config: ApplicationConfiguration,
    private userService: UserService
  ) {
    super(service, skillListItemGroupingOperator, config, cd, router, route);
  }

  ngOnInit(): void {
    this.filter = new SkillFilterBuilder(this.config).build();
    Reflect.set(this.filter, 'type', this.searchFor.id);

    this.route.queryParams.subscribe((queryParams) => {
      const type = this.searchForOptions.find((option) => {
        return option.id === queryParams['type'];
      });
      if (type) {
        this.searchFor = type;
        Reflect.set(this.filter, 'type', this.searchFor.id);
      }

      const pageNumber = parseInt(queryParams['pageNumber'] || 0);
      const pageSize = parseInt(queryParams['pageSize'] || this.config.pageSize);
      this.filter.pageNumber = pageNumber;
      this.filter.pageSize = pageSize;
      this.pagination.currentPage = pageNumber;
      this.pagination.pageSize = pageSize;

      this.cd.detectChanges();
    });

    this.userService.getAdminStatus().subscribe((isAdmin) => {
      this.isAdmin = isAdmin;
    });

    this.initialized = true;
    this.cd.markForCheck();
  }

  public handleEmptyList(): void {
    this.searchValue = '';
  }

  /**
   * Reloads the current page with the current filter values in the query parameter.
   */
  private applyFilter() {
    this.isDrawerOpen = false;
    this.sorting = Reflect.get(this.filter, 'sorting');
    console.log('filter before reload', this.filter);
    ignorePromise(this.router.navigate(['skills'], { queryParams: this.filter }));
    this.reload();
  }

  filterSelected(item: MenuItem[]) {
    this.searchFor = item[0];
    Reflect.set(this.filter, 'type', this.searchFor.id);
    Reflect.set(this.filter, 'sorting', this.sorting);
    this.filter.pageNumber = 0;
    this.applyFilter();
  }

  /**
   * Searches for a name to serve the autocomplete dropdown.
   * @param text
   */
  protected searchForName = (text: string): Observable<MenuItem[]> => {
    const service = this.service as SkillsService;
    return service.autocompleteSkills(text, this.searchFor.id).pipe(
      map((suggest: string[]) => {
        return suggest.map((item) => {
          return textAsMenuItem(item);
        });
      })
    );
  };

  protected createCaptionForDropdown(dropdown: GxDropdownComponent): string {
    const dropdownLabel = dropdown?.label ?? '';
    const selectedItemLabel = dropdown.selectedItems[0]?.label ?? '';
    const dropdownLabelText = dropdownLabel ? dropdown.translate.instant(dropdownLabel) : '';
    const dropdownItemText = selectedItemLabel ? dropdown.translate.instant(selectedItemLabel) : '';
    return `${dropdownLabelText} ${dropdownItemText}`.trim();
  }

  filterBlockChanged(value: Record<string, string>) {
    const oldFilter = JSON.stringify(this.filter); // the current filter
    this.filter = updateSkillFilter(this.filter, value);
    const newFilter = JSON.stringify(this.filter); // the new filter
    console.log('filterBlockChanged', oldFilter, newFilter, this.initialized);
    if (oldFilter !== newFilter && this.initialized) {
      this.filter.pageNumber = 0;
      this.applyFilter();
    }
  }

  clickedOnSkill(item: SkillListItem) {
    this.selectedSkill = item;
    this.isDrawerOpen = true;
  }

  closeDrawer() {
    this.isDrawerOpen = false;
  }

  protected readonly csvExportIcon = faDownload;

  public downloadTheList() {
    this.listIsDownloading = true;
    ignorePromise(
      this.backendService.downloadList(
        {
          ...this.filter,
          forDownload: true
        },
        skillDownloadMapper,
        this.searchFor.id === 'skill' ? 'skills' : 'coreCompetences',
        this.pagination.totalItems
      )
    );
  }

  @HostListener(`document:${SIMPLE_EVENTS.DOWNLOAD_COMPLETED}`, ['$event'])
  protected downloadCompleted(): void {
    this.listIsDownloading = false;
  }
}
