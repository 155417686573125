<div *ngIf="factSheet">
  <h3 class="mt-1 font-bold">{{ 'project.factSheet.sticker.kickoff' | translate }}</h3>
  <div>{{ getSingleString("//kick-off") }}</div>
  <h3 class="font-bold">{{ 'project.factSheet.sticker.technologies' | translate }}</h3>
  <table *ngIf="technologies.length > 0" class="w-full table-fixed border-collapse border-spacing-y-2">
    <tbody>
    <tr *ngFor="let technology of technologies"
        class="h-12 border-spacing-2 border-b border-ui-border align-middle">
      <td>{{ technology[1] }}</td>
      <td>{{ technology[0] }}</td>
    </tr>
    </tbody>
  </table>
  <h3 class="font-bold">{{ 'project.factSheet.sticker.architecturalChart' | translate }}</h3>
  <a *ngFor="let image of architectureImages" class="underline accent-sky-500"
     [href]="image[0]">{{ image[0] }}</a>
</div>

