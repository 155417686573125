import { Inject, Injectable } from '@angular/core';
import { ApplicationConfiguration } from './application.configuration.interface';
import { Environment } from '@env/environment.interface';
import { InjectorKeys } from '@core/utils/injector.keys';

@Injectable({
  providedIn: 'root'
})
export class ApplicationConfigurationService implements ApplicationConfiguration {
  constructor(@Inject(InjectorKeys.ENVIRONMENT) private environment: Environment) {}
  get activeOnly(): boolean {
    return this.environment.activeOnly;
  }

  get pageSize(): number {
    return this.environment.pageSize;
  }

  get authLogLevel(): number {
    return this.environment.authLogLevel;
  }

  get defaultLanguage(): string {
    return this.environment.defaultLanguage;
  }

  get pageSizes(): string {
    return this.environment.pageSizes;
  }
  get version(): string {
    return this.environment.version;
  }
}
