<div class="m-2 flex items-center gap-2">
  <gx-autocomplete [leftIcon]="searchIcon" [rightIcon]="closeIcon" class="grow" id="text-search"
                   [acceptNewValue]="true" [value]="searchValue" [doSearch]="textSearch"
                   [clearOnRightIconClick]="true"
                   (valueSelected)="textSearched($event)"
                   fieldClass="px-10"/>
  <ng-template [ngTemplateOutlet]="externalFiltering || defaultFiltering"></ng-template>
</div>


  <div class="mx-2 flex flex-row flex-wrap items-center justify-start gap-4">
    <div *ngIf="statusFilterVisible"
         class="flex flex-wrap items-center gap-3 rounded-full border-2 border-ui-interactive bg-ui-surface-rise px-4 py-2">
      <gx-dropdown
        #GxDropdown
        label="filter.names.activeOnly"
        [icon]="dropdownIcon"
        openWith="pill"
        notificationAt="whenSelected"
        [menuItems]="statusFilter.items"
        [defaultSelectedItems]="statusFilter.selectedItems"
        (menuItemSelected)="filterAction('activeOnly', $event)"
        [multiSelect]="statusFilter.isMulti"
        [withSearch]="statusFilter.searchable"
        [createCaption]="statusFilter.createCaption ?? createCaption"
        [maxLabels]="statusFilter!.maxLabels"
        [allSelectable]="false"
        [switchMode]="statusFilter.switchMode"
        [reload]="statusFilter.reload"/>
    </div>

    <div *ngFor="let filterName of visibleFilters">
      <div class="flex items-center gap-3 rounded-full border-2 border-ui-interactive bg-ui-surface-rise px-4 py-2">
        <gx-dropdown
          #GxDropdown
          [id]="filterName"
          *ngIf="getFilter(filterName)!.filterType === 'items'"
          [label]="'filter.names.' + filterName"
          [icon]="dropdownIcon"
          openWith="pill"
          notificationAt="whenSelected"
          [menuItems]="getFilter(filterName)!.items"
          [defaultSelectedItems]="getFilter(filterName)!.selectedItems"
          (menuItemSelected)="filterAction(filterName, $event)"
          [multiSelect]="getFilter(filterName)!.isMulti"
          [withSearch]="getFilter(filterName)!.searchable"
          [createCaption]="getFilter(filterName)?.createCaption ?? createCaption"
          [maxLabels]="getFilter(filterName)!.maxLabels"
          [allSelectable]="false"
          [switchMode]="getFilter(filterName)!.switchMode"
          [reload]="getFilter(filterName)!.reload"/>

        <gx-number-filter
          #GxDropdown
          [id]="filterName"
          *ngIf="getFilter(filterName)!.filterType === 'number'"
          [label]="'filter.names.' + filterName"
          [icon]="dropdownIcon"
          openWith="pill"
          [inputFieldsWithArrows]="getFilter(filterName)!.numberFilterParams!.inputFieldsWithArrows"
          [operations]="getFilter(filterName)!.numberFilterParams!.operations"
          [inputFieldStep]="getFilter(filterName)!.numberFilterParams!.inputFieldStep"
          [defaultOperation]="getFilter(filterName)!.numberFilterParams!.defaultOperation"
          [createCaption]="getFilter(filterName)?.createCaption ?? createCaptionNumber"
          [selectedOperation]="selectedOperations[filterName]"
          (filterChanged)="numberFilterChanged(filterName, $event)"
          (filterOperationChanged)="filterOperationAction(filterName, $event)">
        </gx-number-filter>

        <gx-date-filter
          #GxDropdown
          [id]="filterName"
          *ngIf="getFilter(filterName)!.filterType === 'date'"
          [label]="'filter.names.' + filterName"
          [icon]="dropdownIcon"
          openWith="pill"
          [minYear]="getFilter(filterName)!.dateFilterParams!.minYear"
          [maxYear]="getFilter(filterName)!.dateFilterParams!.maxYear"
          [iconAt]="getFilter(filterName)!.dateFilterParams!.iconAt"
          [operations]="getFilter(filterName)!.dateFilterParams!.operations"
          [defaultOperation]="getFilter(filterName)!.dateFilterParams!.defaultOperation"
          (filterChanged)="dateFilterChanged(filterName, $event)"
          (filterOperationChanged)="filterOperationAction(filterName, $event)"
          [selectedOperation]="selectedOperations[filterName]"
          [createCaption]="getFilter(filterName)?.createCaption ?? createCaptionDate"
        >
        </gx-date-filter>

        <div *ngIf="getFilter(filterName)!.canHide" class="--icon-container"
             (click)="toggleFilterVisibility([textAsMenuItem(filterName)])">
          <fa-icon [icon]="deleteFilterIcon" size="xs"></fa-icon>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!disableSorting" class="flex w-full justify-end pr-2">
    <ng-template [ngTemplateOutlet]="externalSorting || defaultSorting"></ng-template>
  </div>

<ng-template #defaultSorting>
  <gx-sorting-selector *ngIf="!externalSorting" class="h-fit" [direction]="sorting" [choices]="sortingItems"
                       (directionChanged)="sortingChanged($event)"/>
</ng-template>

<ng-template #defaultFiltering>
  <div *ngIf="availableFilters.length > 0"
       class="flex items-center gap-3 rounded-full border-2 border-ui-interactive bg-ui-surface-rise px-4 py-2">
    <gx-dropdown
      label="filter.singular"
      icon="plus"
      openWith="button"
      [isRightAligned]="true"
      notificationAt="whenSelected"
      iconAt="right"
      [isSelectable]="false"
      [allSelectable]="false"
      [menuItems]="getAllAvailableFilters()"
      [defaultSelectedItems]="getAllAvailableFilters()"
      (menuItemSelected)="toggleFilterVisibility($event)"
      [multiSelect]="false"
      [withSearch]="false"/>
  </div>
</ng-template>
