import { GroupingOperator } from '@core/prototypes/grouping';
import { Inject } from '@angular/core';
import { InjectorKeys } from '@core/utils/injector.keys';
import { ApplicationConfiguration } from '@core/services/configuration/application.configuration.interface';
import { Filter } from '@core/prototypes/filtering';
import { DownloadMapperFunction } from '@core/prototypes/backend.service';
import { ignore } from '@ebcont/galaxy';

export interface Customer {
  id: string;
  businessPartnerName: string;
  isInactive: boolean;
  numberOfProjects: number;
  numberOfEmployes: number; // NOTE: this is a spelling error in the backend API, do not correct it!
  sumHours: number;
}

export interface CustomerFilter extends Filter {
  searchValue?: string;
  minHours?: string;
  maxHours?: string;
  fromDate?: string;
  toDate?: string;
}

export const basicCustomerFilter = (config: ApplicationConfiguration): CustomerFilter => {
  return {
    activeOnly: config.activeOnly.toString() ?? true,
    pageNumber: 0,
    pageSize: config.pageSize ?? 10
  };
};

export class CustomerFilterBuilder {
  private readonly filter: CustomerFilter;

  constructor(@Inject(InjectorKeys.CONFIGURATION) config: ApplicationConfiguration) {
    this.filter = basicCustomerFilter(config);
  }

  build(): CustomerFilter {
    return this.filter;
  }
}

export const updateCustomerFilter = (filter: CustomerFilter, params: Record<string, string>): CustomerFilter => {
  // we only inherit the values of the active only, page number and page size, every other value is overwritten
  return {
    activeOnly: filter.activeOnly ? 'true' : 'false',
    pageNumber: filter.pageNumber,
    pageSize: filter.pageSize,
    ...params
  };
};

export const customerGroupingOperator: GroupingOperator<Customer> = (
  customers: Customer[],
  sorting: 'asc' | 'desc'
): Map<string, Customer[]> => {
  ignore(sorting);
  const groupedCustomers: Map<string, Customer[]> = new Map<string, Customer[]>();

  customers.forEach((customer: Customer) => {
    const initialLetter: string = customer.businessPartnerName.substring(0, 1).toUpperCase();
    const collection: Customer[] = groupedCustomers.get(initialLetter) || [];

    collection.push(customer);
    // the customers are sorted on the server size name ascending and there is no option for change sorting
    // collection.sort((a: Customer, b: Customer) => {
    //   const reverseAName: string = a.businessPartnerName.split(' ').reverse().join('');
    //   const reverseBName: string = b.businessPartnerName.split(' ').reverse().join('');
    //   return reverseAName.localeCompare(reverseBName) * (sorting === 'asc' ? 1 : -1);
    // });

    groupedCustomers.set(initialLetter, collection);
  });

  if (sorting === 'desc') {
    return new Map([...groupedCustomers.entries()].sort().reverse());
  }
  return groupedCustomers;
};

export const customerDownloadMapper: DownloadMapperFunction = (
  item: object
): Record<string, string | number | boolean> => {
  const customer = item as Customer;
  return {
    Name: customer.businessPartnerName,
    Inactive: customer.isInactive ? 'Yes' : 'No',
    Projects: customer.numberOfProjects,
    Employees: customer.numberOfEmployes,
    TotalHours: customer.sumHours ?? 'n/a'
  };
};
