<!-- TOFIX: is the loading css class working? it should apply styles to __image and __infos children. -->
<div
  class="item"
  [ngClass]="!employee.businessPartnerFormattedName ? 'loading' : ''"
  routerLinkActive="active">
  <a class="__link" (click)="selectEmployee()"><span>Open profile</span></a>
  <div class="__image">
    <img
      [src]="(employee.photo | profileImage | async) ?? '/assets/images/image-avatar-fallback.svg' | sanitize"
      alt="" />
  </div>
  <div class="__infos">
    <div class="name">{{ employee.businessPartnerFormattedName }}</div>
    <div>{{ employee.roleDescription || 'n/a' }}</div>
  </div>
  <div class="__extended" *ngIf="isExtended">
    <gx-description-list [isInline]="true" [items]="items" />
  </div>
  <!--
  <div class="__actions">
    <gx-button
      text="More"
      icon="ellipsis-vertical"
      hasIcon="only"
    />
  </div>
  -->
</div>
