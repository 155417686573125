<!--Ebcont employees table-->
<h3 class="mb-7 font-bold">{{ 'project.factSheet.contacts.ebcontParticipants' | translate }}</h3>
<table class="w-full border-collapse border-spacing-y-2 break-all">
  <thead>
  <tr class="text-left">
    <th>{{ 'project.factSheet.contacts.name' | translate }}</th>
    <th>{{ 'project.factSheet.contacts.role' | translate }}</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let employee of ebcontEmployees" class="h-12 border-spacing-2 border-b border-ui-border">
    <td>{{ employee[0] }}</td>
    <td>{{ employee[1] }}</td>
  </tr>
  </tbody>
</table>

<!--Customer employees table-->
<h3 class="mb-7 mt-9 font-bold">{{ 'project.factSheet.contacts.customerParticipants' | translate }}</h3>
<table class="w-full border-collapse border-spacing-y-2 break-all">
  <thead>
  <tr class="text-left">
    <th>{{ 'project.factSheet.contacts.name' | translate }}</th>
    <th>{{ 'project.factSheet.contacts.role' | translate }}</th>
    <th>{{ 'project.factSheet.contacts.email' | translate }}</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let customer of customers"
      class="h-12 border-spacing-2 border-b border-ui-border align-middle">
    <td>{{ customer.name }}</td>
    <td>{{ customer.role }}</td>
    <td>{{ customer.email }}</td>
  </tr>
  </tbody>
</table>

