import { ChangeDetectionStrategy, Component } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'app-employee-details-placeholder',
  templateUrl: './employee-details-placeholder.component.html',
  styleUrls: ['./employee-details-placeholder.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeDetailsPlaceholderComponent {
  public loadingSkeletonSize = new Array(environment.loadingSkeletonSize);
}
