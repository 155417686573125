<div
  class="group/item item"
  [ngClass]="!project.name ? 'loading' : '' || project.isInactive ? '__inActive' : ''"
  routerLinkActive="active">
  <div class="__infos">
    <div class="name">{{ project.name }}</div>
    <div>{{ project.id || 'n/a' }}</div>
  </div>

  <div class="__infos __toRight">
    <div class="name">
      <div title="{{'viewCustomer' | translate}}">{{ project.customerName || 'n/a' }}</div>
    </div>
    <div>
      <span class="pr-1 font-bold">
        {{ project.projectParticipants.length }}
      </span>
      <span>
        {{ "employee.plural" | translate }}
      </span>
    </div>
  </div>

  <div *ngIf="isAdmin" class="__infos __toRight">
    <div class="name">
      {{ project.sumHours | number:'1.0-2' }} {{ 'hours' | translate }}
    </div>
  </div>
</div>
