import { Environment } from './environment.interface';

export const environment: Environment = {
  production: true,
  defaultLanguage: 'en',
  authLogLevel: 2, // INFO
  pageSize: 10,
  activeOnly: true,
  roles: {
    user: 'User',
    superUser: 'Admin'
  },
  pageSizes: '10,25,50,100',
  loadingSkeletonSize: 10,
  version: 'V1.0.4-b9d3321e'
};
