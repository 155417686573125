import { NgModule } from '@angular/core';
import { ProfileImagePipe } from './pipes/profile-image.pipe';
import { EmployeesService } from './services/employee/employees.service';
import { EmployeeDetailsServiceImpl } from './services/employee/employee.details.service.impl';
import { FacetsServiceImpl } from './services/facets/facets.service';
import { InjectorKeys } from './utils/injector.keys';

import { ExceptionHandlerService } from './services/exceptionhandler/exception.handler.service';
import { StatusService } from './services/health-check/status.service';

import { ProjectsService } from './services/employee/projects.service';
import { CustomersService } from './services/employee/customers.service';
import { DashboardService } from '@core/services/employee/dashboard.service';
import { SkillsService } from '@core/services/employee/skills.service';
import { EmployeesSkillService } from '@core/services/employee/employees.skill.service';
import { EmployeesCoreCompetenceService } from '@core/services/employee/employees.core-competence.service';
import { ClickStopPropagationDirective } from './utils/click-stop-propagation.directive';

@NgModule({
  declarations: [ProfileImagePipe, ClickStopPropagationDirective],
  exports: [ProfileImagePipe, ClickStopPropagationDirective],
  imports: [],
  providers: [
    ProfileImagePipe,
    ExceptionHandlerService,
    {
      provide: InjectorKeys.EMPLOYEES_SERVICE,
      useClass: EmployeesService
    },
    {
      provide: InjectorKeys.EMPLOYEES_SKILL_SERVICE,
      useClass: EmployeesSkillService
    },
    {
      provide: InjectorKeys.EMPLOYEES_CORE_COMPETENCE_SERVICE,
      useClass: EmployeesCoreCompetenceService
    },
    {
      provide: InjectorKeys.SKILL,
      useClass: SkillsService
    },
    {
      provide: InjectorKeys.EMPLOYEE_DETAILS_SERVICE,
      useClass: EmployeeDetailsServiceImpl
    },
    {
      provide: InjectorKeys.FACETS_SERVICE,
      useClass: FacetsServiceImpl
    },
    {
      provide: InjectorKeys.STATUS_SERVICE,
      useClass: StatusService
    },
    {
      provide: InjectorKeys.CUSTOMERS_SERVICE,
      useClass: CustomersService
    },
    {
      provide: InjectorKeys.PROJECTS_SERVICE,
      useClass: ProjectsService
    },
    {
      provide: InjectorKeys.DASHBOARD,
      useClass: DashboardService
    }
  ]
})
export class CoreModule {}
