/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { getElementByXpath, getElementsByXpath, getMultipleStrings, getSingleString } from '@core/utils/utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-factsheet-overview',
  templateUrl: './factsheet-overview.component.html',
  styleUrls: ['./factsheet-overview.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FactsheetOverviewComponent implements OnChanges {
  @Input() factSheet: XMLDocument | undefined;
  public data: string[][] = [];

  constructor(private translate: TranslateService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['factSheet'] && changes['factSheet'].currentValue) {
      this.factSheet = changes['factSheet'].currentValue;
      this.regenerateData();
    }
  }

  protected regenerateData(): void {
    const data = new Map<string, string>();
    data.set('description', getSingleString(getElementByXpath(this.factSheet!, '//project-description')));
    data.set('issueTracking', getSingleString(getElementByXpath(this.factSheet!, '//issue-tracking')));
    data.set('fileStorageSystem', getSingleString(getElementByXpath(this.factSheet!, '//dokumentenablage')));
    data.set('implementationTime', getSingleString(getElementByXpath(this.factSheet!, '//implementation-time')));
    data.set(
      'peopleInAuthority',
      getMultipleStrings(getElementsByXpath(this.factSheet!, '//person-in-authority')).join(', ')
    );
    data.set('averageRisk', getSingleString(getElementByXpath(this.factSheet!, '//averageRisk')));
    data.set('sapProjects', getMultipleStrings(getElementsByXpath(this.factSheet!, '//sap-project')).join(', '));

    this.data = Array.from(data)
      .filter((entry) => {
        return entry[1] && entry[1] !== 'n/a';
      })
      .map((entry) => {
        return [this.translate.instant('project.factSheet.overview.' + entry[0]), entry[1]];
      });
  }
}
