import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faArrowRight,
  faBars,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircleHalfStroke,
  faClose,
  faEllipsisVertical,
  faPlus,
  faSquare,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { GALAXY_SETTINGS, GalaxyInjectorKeys, GalaxySettings, ignorePromise, MenuItem } from '@ebcont/galaxy';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@core/auth/auth.service';
import { ApplicationConfigurationService } from '@core/services/configuration/application.configuration.service';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('opacityScaleDown', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('75ms ease-in', style({ opacity: 0, transform: 'scale(.95)' }))
      ])
    ]),
    trigger('opacity', [
      transition(':enter', [style({ opacity: 0 }), animate('300ms ease', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('300ms ease', style({ opacity: 0 }))])
    ]),
    trigger('opacityScaleUp', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(1.1)' }),
        animate('150ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('150ms ease-in', style({ opacity: 0, transform: 'scale(1.1)' }))
      ])
    ]),
    trigger('translateX', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms ease-in-out', style({ transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate('300ms ease-in-out', style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})
export class AppComponent implements OnInit {
  isOffCanvasMenu = false;
  isOffCanvasMenuDialog = false;

  protected email = 'n/a';
  protected fullName = 'n/a';

  protected readonly userMenuItems: MenuItem[] = [
    {
      label: 'header.menu.profile',
      id: 'profile',
      isRouterLink: false,
      clickEvent: () => {
        ignorePromise(
          this.router.navigate(['/team'], {
            queryParams: {
              employee: this.email,
              searchValue: this.email,
              tab: 'profile'
            }
          })
        );
      }
    },
    {
      label: 'header.menu.logout',
      id: 'logout',
      isRouterLink: false,
      clickEvent: () => {
        this.authService.logout();
      }
    }
  ];

  constructor(
    private library: FaIconLibrary,
    private translate: TranslateService,
    @Inject(GalaxyInjectorKeys.SETTINGS) private settings: GalaxySettings,
    // @Inject(GalaxyInjectorKeys.LOGGING) private logging: GalaxyLogging,
    // @Inject(InjectorKeys.CONFIGURATION) private configuration: ApplicationConfiguration,
    // @Inject(InjectorKeys.EMPLOYEE_DETAILS_SERVICE) private employeeDetailsService: EmployeeDetailsService,
    private authService: AuthService,
    public applicationConfigurationService: ApplicationConfigurationService,
    private router: Router
  ) {
    library.addIcons(
      faBars,
      faCircleHalfStroke,
      faSquare,
      faTrash,
      faArrowRight,
      faClose,
      faPlus,
      faEllipsisVertical,
      faChevronLeft,
      faChevronRight,
      faChevronDown
    );
  }

  toggleOffCanvasMenu(force?: boolean) {
    this.isOffCanvasMenu = force ?? !this.isOffCanvasMenu;

    if (this.isOffCanvasMenuDialog) {
      setTimeout(() => {
        this.isOffCanvasMenuDialog = !this.isOffCanvasMenuDialog;
      }, 400);
    } else {
      this.isOffCanvasMenuDialog = !this.isOffCanvasMenuDialog;
    }
  }

  ngOnInit(): void {
    // initialize language
    const lang = this.settings.get(GALAXY_SETTINGS.LANGUAGE, 'en');
    this.translate.use(lang);

    this.authService.getLoginSuccessSubject()
      .pipe(
        untilDestroyed(this),
        filter(x => x)
      ).subscribe(_ => {
      this.email = this.authService.getUserEmail() ?? 'n/a';
      this.fullName = this.authService.getUserFullName() ?? 'n/a';
    });
  }
}
