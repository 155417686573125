<ng-container *ngIf="employee$ && employee$ | async as employee; else placeholder">
  <header>
    <gx-button (click)="onCloseButtonClicked()" hasIcon="only" icon="close" text="Close" variant="neutral"/>
  </header>
  <hoc-person-card [details]="employee"/>
  <gx-tabs>
    <gx-tab caption="Profil" id="profile">
      <app-employee-profile [employee]="employee" (companySelected)="companySelect($event)"
                            (locationSelected)="locationSelect($event)"
                            (boardSelected)="boardSelect($event)"></app-employee-profile>
    </gx-tab>
    <gx-tab caption="Skills" id="skills">
      <app-employee-skills [employee]="employee"></app-employee-skills>
    </gx-tab>
    <gx-tab caption="Projects" id="projects">
      <app-employee-projects [employee]="employee" [isAdmin]="isAdmin"></app-employee-projects>
    </gx-tab>
  </gx-tabs>
</ng-container>

<ng-template #placeholder>
  <app-employee-details-placeholder/>
</ng-template>
