import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogCloseAction, GalaxyToastService } from '@ebcont/galaxy';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-hoc-profile-download-form-component',
  templateUrl: './hoc-profile-download-form.component.html',
  styleUrls: ['./hoc-profile-download-form.component.css']
})
export class HocProfileDownloadFormComponent implements OnInit {
  downloadForm!: FormGroup;

  @Input() email?: string;

  @Output() closeDialog = new EventEmitter<DialogCloseAction>();

  private buildLink(): string {
    return `https://profile.ebcont.com/${encodeURIComponent(this.email ?? '')}?${new URLSearchParams(
      this.downloadForm.value
    )}`;
  }

  constructor(
    private fb: FormBuilder,
    private toastService: GalaxyToastService,
    private translateService: TranslateService,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.downloadForm = this.fb.group({
      format: this.fb.control('pdf', [Validators.required]),
      language: this.fb.control('de', [Validators.required]),
      original: this.fb.control('true', [Validators.required]),
      short: this.fb.control(false),
      anonymous: this.fb.control(false)
    });
  }

  copyLinkHandler() {
    navigator.clipboard.writeText(this.buildLink()).then(async () => {
      const heading = await firstValueFrom(this.translateService.get('action.copy_link.message'));
      this.toastService.add({ heading, life: 5000 });
    });
  }

  updateHandler() {
    firstValueFrom(this.http.get<void>(`https://profile.ebcont.com/${this.email}/sync`)).then(
      async () => {
        const heading = await firstValueFrom(this.translateService.get('action.update_profile.message'));
        this.toastService.add({ heading, life: 5000 });
      },
      (e) => {
        return console.log('Update error', e);
      }
    );
  }

  downloadHandler() {
    window.open(this.buildLink());
    this.closeDialog.emit(DialogCloseAction.YES);
  }
}
