import { BackendService } from '@core/prototypes/backend.service';
import { ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ListResult } from '@core/prototypes/list.result';
import { GroupingOperator } from '@core/prototypes/grouping';
import { emptyFilter, Filter } from '@core/prototypes/filtering';
import { addParamForRoute, emptyPagination, ignorePromise, Pagination } from '@ebcont/galaxy';
import { ApplicationConfiguration } from '@core/services/configuration/application.configuration.interface';

export interface GroupedList<T> {
  /**
   * Reloads the list.
   */
  reload(): void;

  /**
   * This method can be overridden to process the filter before it is sent to the backend. It is useful to modify the
   * special filter parameters, like date ranges, or to add additional parameters.
   */
  processFilter(): Filter;

  onReloaded: () => void;
  getItemsForLetter: (letter: string) => T[];
  sortingChanged: (sorting: unknown) => void;

  transformListToGroupedList(payload: T[], sorting: 'asc' | 'desc'): Map<string, T[]>;

  gotoPage(index: number): void;

  /**
   * When the page size is changed, we need to reload the data. It is necessary to reload from the first page and
   * not the actual page, because the actual page may not exist anymore.
   * @param size The new page size.
   */
  newPageSize(size: number): void;

  /**
   * Loads the next page of the data. Used only in infinite loading mode.
   * @param index The next page needed to load.
   */
  loadNextPage(index: number): void;

  /**
   * When the user scrolled to the end of the list and the infinite loading mode is used, we need to load the next page
   * if it exists.
   */
  scrolledToEnd(): void;
}

export abstract class AbstractGroupedList<T> implements GroupedList<T> {
  public loading = true;
  public loadMode: 'paged' | 'infinite' = 'paged';
  public canLoadMore = false;
  public items: Map<string, T[]> = new Map();
  public itemCount = 0;
  public groupLetters: string[] = [];
  public sorting: 'asc' | 'desc' = 'asc';
  public lastResult: ListResult<T> = {
    results: [],
    currentStartIndex: 0,
    currentEndIndex: 0,
    totalNumberResultsets: 0,
    debug: undefined
  } as ListResult<T>;
  public pagination: Pagination = emptyPagination();
  filter: Filter = emptyFilter(this.configuration);

  protected constructor(
    protected readonly backendService: BackendService<T>,
    private readonly groupingOperator: GroupingOperator<T>,
    private readonly configuration: ApplicationConfiguration,
    public cd: ChangeDetectorRef,
    protected router: Router,
    protected route: ActivatedRoute
  ) {}

  /**
   * This method can be overridden to process the filter before it is sent to the backend. It is useful to modify the
   * special filter parameters, like date ranges, or to add additional parameters.
   */
  public processFilter(): Filter {
    return this.filter;
  }

  public reload() {
    this.loading = true;
    this.backendService.list(this.processFilter()).subscribe((next) => {
      if (this.loadMode === 'paged') {
        // when in paged mode, no need to any extra processing
        this.lastResult = next;
        this.pagination = this.asPagination(next, true);
        this.process(next.results);
      }
      if (this.loadMode === 'infinite') {
        // when infinite mode, then we need a bit more processing
        if (this.lastResult.results.length === 0 || this.filter.pageNumber === 0) {
          // when loading the first page, then we can just take the result
          this.lastResult = { ...next };
        } else {
          // when loading the next pages, then we need to append the results
          this.lastResult.results = this.lastResult.results.concat(next.results);
          this.lastResult.currentEndIndex = next.currentEndIndex;
        }
        this.pagination = this.asPagination(this.lastResult, false);
        this.process(this.lastResult.results);
      }
    });
  }

  public getItemsForLetter(letter: string): T[] {
    return this.items.get(letter) ?? [];
  }

  /**
   * Modify the URL parameters where the sorting changed.
   * @param sorting The new sorting
   */
  public sortingChanged(sorting: unknown) {
    this.sorting = sorting as 'asc' | 'desc';
    this.router
      .navigate([], {
        relativeTo: this.route,
        queryParams: { sorting },
        queryParamsHandling: 'merge'
      })
      .then(() => {
        this.reload();
      })
      .catch(() => {
        console.error('cannot reload list');
      });
  }

  private process(payload: T[]) {
    const grouped = this.transformListToGroupedList(payload, this.sorting);
    this.itemCount = payload.length;
    this.items = grouped;
    this.groupLetters = Array.from(grouped.keys());
    this.loading = false;
    this.onReloaded();
    this.cd.detectChanges();
  }

  private asPagination = (result: ListResult<T>, paged = true): Pagination => {
    return {
      startIndex: result.currentStartIndex,
      endIndex: Math.min(result.currentEndIndex, result.totalNumberResultsets),
      pageSize: this.filter.pageSize,
      totalItems: result.totalNumberResultsets,
      currentPage: paged
        ? Math.floor(result.currentStartIndex / this.filter.pageSize)
        : Math.floor(this.lastResult.currentEndIndex / this.filter.pageSize) - 1
    };
  };

  public transformListToGroupedList(payload: T[], sorting: 'asc' | 'desc'): Map<string, T[]> {
    return this.groupingOperator(payload, sorting);
  }

  public onReloaded() {
    this.canLoadMore = this.pagination.endIndex < this.pagination.totalItems; // this is important only in infinite mode
  }

  /**
   * Loads a new page to the list. It is used only in paged loading mode.
   * @param index The page index to load.
   * @ignore
   */
  public gotoPage(index: number): void {
    if (index === this.filter.pageNumber || index < 0) {
      return;
    }

    ignorePromise(
      addParamForRoute(this.router, this.route, { pageNumber: index }).then(() => {
        this.filter.pageNumber = index;
        this.reload();
      })
    );
  }

  /**
   * When the page size is changed, we need to reload the data. It is necessary to reload from the first page and
   * not the actual page, because the actual page may not exist anymore.
   * @param size The new page size.
   */
  public newPageSize(size: number): void {
    if (size === this.filter.pageSize) {
      return;
    }
    ignorePromise(
      addParamForRoute(this.router, this.route, { pageSize: size, pageNumber: 0 }).then(() => {
        this.filter.pageSize = size;
        this.filter.pageNumber = 0;
        this.reload();
      })
    );
  }

  /**
   * Loads the next page of the data. Used only in infinite loading mode.
   * @param index The next page needed to load.
   */
  loadNextPage(index: number) {
    ignorePromise(
      addParamForRoute(this.router, this.route, { pageNumber: index }).then(() => {
        this.filter.pageNumber = index;
        this.reload();
      })
    );
  }

  /**
   * When the user scrolled to the end of the list and the infinite loading mode is used, we need to load the next page
   * if it exists.
   */
  scrolledToEnd() {
    if (this.loadMode === 'infinite' && this.canLoadMore) {
      this.loadNextPage(this.pagination.currentPage + 1);
    }
  }
}
