<ng-container *ngIf="customer; else placeholder">
  <header>
    <gx-button (click)="onCloseButtonClicked()" hasIcon="only" icon="close" text="Close" variant="neutral"/>
  </header>
  <div class="flex w-full flex-col justify-start gap-4 p-4">
    <div class="text-xl font-bold">{{ customer.businessPartnerName }}</div>
    <div class="flex w-full gap-4">
      <div class="flex flex-1 flex-col items-start gap-1">
        <div>{{ 'project.numberOf' | translate }}</div>
        <div>{{ customer.numberOfProjects }}</div>
      </div>
      <div class="flex flex-1 flex-col items-start gap-1">
        <div>{{ 'employee.numberOf' |translate }}</div>
        <div>{{ customer.numberOfEmployes }}</div>
      </div>
    </div>
    <gx-tabs>
      <gx-tab [caption]="'employee.plural' | translate" id="employees">
        <app-customer-employee-list [customer]="customer"></app-customer-employee-list>
      </gx-tab>
      <gx-tab [caption]="'project.plural' | translate" id="projects">
        <app-customer-project-list [customer]="customer"></app-customer-project-list>
      </gx-tab>
    </gx-tabs>
  </div>
</ng-container>

<ng-template #placeholder>
  <div>placeholder</div>
</ng-template>
