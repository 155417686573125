import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { GALAXY_SETTINGS, GalaxyInjectorKeys, GalaxySettings, GxDropdownComponent, MenuItem } from '@ebcont/galaxy';
import { DashboardCard } from '@core/models/card.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DashboardStats } from '@core/models/dashboardStats';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@core/services/user/user.service';
import { InjectorKeys } from '@core/utils/injector.keys';
import { DashboardService } from '@core/services/employee/dashboard.service';
import { createCaptionForDropdown } from '@core/prototypes/filtering';
import { EmployeesService } from '@core/services/employee/employees.service';
import { AuthService } from '@core/auth/auth.service';

@UntilDestroy()
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardComponent implements OnInit {
  locationItems: MenuItem[] = [
    { label: 'Wien', isRouterLink: false, id: 'wien' },
    { label: 'Graz', isRouterLink: false, id: 'graz' },
    { label: 'Linz', isRouterLink: false, id: 'linz' },
    { label: 'Hard', isRouterLink: false, id: 'hard' },
    { label: 'Saarbrücken', isRouterLink: false, id: 'saarbruecken' },
    { label: 'New York', isRouterLink: false, id: 'newyork' },
    { label: 'St. Pölten', isRouterLink: false, id: 'stpoelten' },
    { label: 'Frankfurt am Main', isRouterLink: false, id: 'frankfurt' },
    { label: 'Neulengbach', isRouterLink: false, id: 'neulengbach' }
  ];

  @ViewChild('locationDropdown') locationDropdown?: GxDropdownComponent;

  dashBoardCardItems1: DashboardCard[] | undefined;
  dashBoardCardItems2: DashboardCard[] | undefined;

  protected get firstName() {
    return this.authService.getUserFullName()?.split(' '[0]);
  }

  protected get fullName() {
    return this.authService.getUserFullName();
  }

  get selectedLocationItem(): MenuItem | undefined {
    return this.locationDropdown?.selectedItems[0];
  }

  private dashboardStats: DashboardStats | undefined;

  constructor(
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
    @Inject(GalaxyInjectorKeys.SETTINGS) private settings: GalaxySettings,
    @Inject(InjectorKeys.DASHBOARD) private service: DashboardService,
    @Inject(InjectorKeys.EMPLOYEES_SERVICE) private employeesService: EmployeesService,
    // private userService: UserService
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    const lang = this.settings.get(GALAXY_SETTINGS.LANGUAGE, 'en');
    this.translate.use(lang);
    this.service
      .get()
      .pipe(untilDestroyed(this))
      .subscribe((dashboardStats: DashboardStats) => {
        this.dashboardStats = dashboardStats;
        return this.loadDashboardStatsItems(dashboardStats);
      });

    this.translate.onLangChange.subscribe(() => {
      return this.loadDashboardStatsItems(this.dashboardStats);
    });

    this.employeesService
      .list({ searchValue: this.authService.getUserEmail(), pageNumber: 0, pageSize: 1 })
      .subscribe((result) => {
        let location: MenuItem | undefined;
        if (result.results.length > 0) {
          const employee = result.results[0];

          location = this.locationItems.find((item) => {
            return item.label.toLocaleLowerCase() === employee.location?.toLocaleLowerCase();
          });
          if (location) {
            this.locationDropdown?.setSelectedItems([location]);
          }
        }
        // If no employee is found or the location of the employee is not set --> default to Wien
        if (!location) {
          this.locationDropdown?.setSelectedItems([this.locationItems[0]]);
        }
      });
  }

  private loadDashboardStatsItems(dashboardStats: DashboardStats | undefined): void {
    if (!dashboardStats) {
      return;
    }
    this.dashBoardCardItems1 = [
      {
        title: 'dashboard.cards.new_Customers',
        quantity: dashboardStats?.newCustomers ?? 0
      },
      {
        title: 'dashboard.cards.new_Projects',
        quantity: dashboardStats?.newProjects ?? 0
      }
    ];

    this.dashBoardCardItems2 = [
      {
        title: 'dashboard.cards.active_Customers',
        quantity: dashboardStats?.activeCustomers ?? 0
      },
      {
        title: 'dashboard.cards.active_Projects',
        quantity: dashboardStats?.activeProjects ?? 0
      },
      {
        title: this.translate.instant('dashboard.cards.customers_Last_Year') + this.getCurrentYear(),
        quantity: dashboardStats?.customersLastYear ?? 0
      },
      {
        title: this.translate.instant('dashboard.cards.projects_Last_Year') + this.getCurrentYear(),
        quantity: dashboardStats?.projectsLastYear ?? 0
      },
      {
        title: 'dashboard.cards.average_Employees_Per_Project',
        quantity: dashboardStats?.averageEmployeesPerProject ?? 0
      }
    ];

    this.cd.detectChanges();
    this.cd.markForCheck();
  }

  private getCurrentYear(): string {
    return new Date().getFullYear().toString();
  }

  protected readonly createCaptionForDropdown = createCaptionForDropdown;
}
