import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { EbcontGalaxyModule } from '@ebcont/galaxy';
import { HocModule } from '@hoc/hoc.module';
import { TeamModule } from '@modules/team/team.module';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from '@core/core.module';
import { GeneralModule } from '@modules/general/general.module';
import { RouterLinkActive } from '@angular/router';
import { CustomerListComponent } from '@modules/customer/components/customer-list/customer-list.component';
import { CustomerDetailsComponent } from '@modules/customer/components/customer-details/customer-details.component';
import { CustomerListItemComponent } from '@modules/customer/components/customer-list-item/customer-list-item.component';
import { CustomerListPlaceholderComponent } from '@modules/customer/components/customer-list-placeholder/customer-list-placeholder.component';
import { CustomerProjectListComponent } from '@modules/customer/components/customer-project-list/customer-project-list.component';
import { CustomerEmployeeListComponent } from '@modules/customer/components/customer-employee-list/customer-employee-list.component';

@NgModule({
  declarations: [
    CustomerListComponent,
    CustomerDetailsComponent,
    CustomerListItemComponent,
    CustomerListPlaceholderComponent,
    CustomerProjectListComponent,
    CustomerEmployeeListComponent
  ],
  exports: [CustomerListComponent],
  imports: [
    CommonModule,
    EbcontGalaxyModule,
    HocModule,
    TeamModule,
    TranslateModule,
    CoreModule,
    GeneralModule,
    NgOptimizedImage,
    RouterLinkActive
  ]
})
export class CustomerModule {}
