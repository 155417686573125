import { Pipe, PipeTransform } from '@angular/core';
import { EmployeeDetails } from '@core/models/employee';
import { Description } from '@ebcont/galaxy';

@Pipe({
  name: 'employeeToItem'
})
export class EmployeeToItemPipe implements PipeTransform {
  transform(employee: EmployeeDetails): Description[] {
    const companyKeys = employee?.positions
      ?.filter((position) => {
        return position.currentCompanyKey !== null;
      })
      .map((position) => {
        return position.currentCompanyKey;
      });

    const location = employee?.positions
      ?.filter((position) => {
        return position.cities !== null && position.cities.length > 0;
      })
      .map((position) => {
        return position.cities;
      });

    return [
      { term: 'team.title', description: employee?.roleDescription ?? 'n/a' },
      {
        term: 'team.company',
        description: companyKeys?.length > 0 ? companyKeys.join(', ') : 'n/a'
      },
      {
        term: 'team.location',
        description: location?.length > 0 ? location.join(', ') : 'n/a'
      },
      {
        term: 'team.languages',
        description: employee?.languages?.length > 0 ? employee?.languages.join(', ') : 'n/a'
      }
    ];
  }
}
