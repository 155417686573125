import { EmployeeDetails } from '../../models/employee';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractBackendService } from '@core/prototypes/backend.service';
import { EmployeeDetailsService } from './employee.details.service';
import { catchError, Observable, of } from 'rxjs';
import { PLACEHOLDER_IMAGE } from '../../utils/utils';
import { mergeMap } from 'rxjs/operators';
import { ExceptionHandlerService } from '../exceptionhandler/exception.handler.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeDetailsServiceImpl
  extends AbstractBackendService<EmployeeDetails>
  implements EmployeeDetailsService
{
  constructor(http: HttpClient, private exceptionService: ExceptionHandlerService) {
    super('/api/employees', http);
  }

  /**
   * @param response The response coming from the backend
   * @returns The base64 encoded image
   */
  private readDataFromURL = (response: unknown): Observable<string> => {
    return new Observable<string>((subscriber) => {
      const reader = new FileReader();
      reader.readAsDataURL(response as Blob);
      reader.onloadend = () => {
        subscriber.next((reader.result as string).replace('data:application/json;base64,', 'data:image/jpeg;base64,'));
      };
    });
  };

  /**
   * @inheritDoc
   */
  public getProfileImage(fileName?: string): Observable<string> {
    if (!fileName) {
      return of(PLACEHOLDER_IMAGE);
    }

    return this.http
      .get(this.endPointUrl + `/profileImages/${fileName}`, {
        responseType: 'blob' as 'json'
      })
      .pipe(
        mergeMap((response) => {
          return this.readDataFromURL(response);
        }),
        catchError(() => {
          return of(PLACEHOLDER_IMAGE);
        })
      );
  }

  /**
   * @inheritDoc
   */
  public getProfilePicture(email?: string): Observable<string> {
    if (!email) {
      return of(PLACEHOLDER_IMAGE);
    }

    return this.http
      .get(this.endPointUrl + `/profilePictures/${email}`, {
        responseType: 'blob' as 'json'
      })
      .pipe(
        mergeMap((response) => {
          return this.readDataFromURL(response);
        }),
        catchError(() => {
          return of(PLACEHOLDER_IMAGE);
        })
      );
  }

  /**
   * @inheritDoc
   * @override
   */
  override get(id: string): Observable<EmployeeDetails> {
    return this.http
      .get<EmployeeDetails>(this.endPointUrl + `/${id}`)
      .pipe(catchError(this.exceptionService.handleError<EmployeeDetails>()));
  }
}
