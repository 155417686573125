import { Employee, EmployeeFilter } from '../../models/employee';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, Observable, tap } from 'rxjs';
import { AbstractBackendService } from '../../prototypes/backend.service';
import { EmployeeListResult } from '../../prototypes/list.result';
import { CustomHttpParamEncoder } from '../../utils/custom.http.param.encoder';
import { ExceptionHandlerService } from '../exceptionhandler/exception.handler.service';
import { emitSimpleEvent, SIMPLE_EVENTS } from '@core/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class EmployeesService extends AbstractBackendService<Employee> {
  constructor(http: HttpClient, private exceptionService: ExceptionHandlerService) {
    super(`/api/employees/`, http);
  }

  /**
   * In the backend the sorting expected in the "sortDirection" field and the isAdmin field is not expected.
   * @param filter The original filter
   * @returns The modified filter, replacing the "sorting" field with "sortDirection" and "isAdmin" field removed.
   */
  private modifyFilter = (filter: object): object => {
    const modifiedFilter = { ...filter };
    const sorting = Reflect.get(filter, 'sorting');
    if (sorting) {
      Reflect.deleteProperty(modifiedFilter, 'sorting');
      Reflect.set(modifiedFilter, 'sortDirection', sorting);
    }
    return modifiedFilter;
  };

  /**
   * @inheritDoc
   */
  override list(filter: EmployeeFilter, emitEvent?: boolean): Observable<EmployeeListResult> {
    const modifiedFilter = this.modifyFilter(filter);
    let httpParams = new HttpParams({ encoder: new CustomHttpParamEncoder() });
    Object.keys(modifiedFilter)
      .filter((key) => {
        return Reflect.get(modifiedFilter, key) !== undefined && Reflect.get(modifiedFilter, key) !== null;
      })
      .forEach((key) => {
        httpParams = httpParams.append(key, Reflect.get(modifiedFilter, key));
      });

    return this.http.get<EmployeeListResult>(this.endPointUrl, { params: httpParams }).pipe(
      tap((result) => {
        if (emitEvent) {
          emitSimpleEvent(SIMPLE_EVENTS.DOWNLOAD_PROGRESS, { modifiedFilter, result });
        }
      }),
      catchError(this.exceptionService.handleError<EmployeeListResult>())
    );
  }

  /**
   * @inheritDoc
   */
  // eslint-disable-next-line unused-imports/no-unused-vars,@typescript-eslint/no-unused-vars
  override autocomplete(text: string, subPath?: string): Observable<string[]> {
    return this.http
      .get<string[]>(this.endPointUrl + 'autocomplete/name?searchValue=' + text)
      .pipe(catchError(this.exceptionService.handleError<string[]>()));
  }
}
