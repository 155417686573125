<ng-container *ngIf="project; else placeholder">
  <header>
    <gx-button (click)="onCloseButtonClicked()" hasIcon="only" icon="close" text="Close" variant="neutral"/>
  </header>
  <div class="flex w-full flex-col justify-start gap-4 p-4">
    <div class="text-xl font-bold">{{ project.name }} ({{ project.id }})</div>
    <a class="text-xl font-bold" [href]="getCustomerLink()">{{ project.customerName }}
      <fa-icon [icon]="faLink"></fa-icon>
    </a>
    <div class="flex w-full gap-4">
      <div class="flex flex-1 flex-col items-start gap-1" *ngIf="isAdmin">
        <div>{{ 'hours' | translate }}</div>
        <div>{{ project.sumHours | number:'1.0-2' }}</div>
      </div>
      <a class="flex flex-1 flex-col items-start gap-1" [href]="getTeamLink()">
        <div>{{ 'employee.numberOf' |translate }}
          <fa-icon [icon]="faLink"></fa-icon>
        </div>
        <div>{{ project.projectParticipants.length }}</div>
      </a>
    </div>
    <gx-tabs>
      <gx-tab [caption]="'employee.plural' | translate" id="employees">
        <app-project-employee-list [project]="project"></app-project-employee-list>
      </gx-tab>
      <gx-tab [caption]="'project.factSheet.singular' | translate" id="factsheet">
        <app-project-factsheet *ngIf="factSheet" [factSheet]="factSheet"></app-project-factsheet>
        <app-no-factsheet *ngIf="!factSheet"/>
      </gx-tab>
    </gx-tabs>
  </div>
</ng-container>

<ng-template #placeholder>
  <div>placeholder</div>
</ng-template>
