import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ProjectListComponent } from '@modules/project/project-list/project-list.component';
import { EbcontGalaxyModule } from '@ebcont/galaxy';
import { HocModule } from '@hoc/hoc.module';
import { TeamModule } from '@modules/team/team.module';
import { TranslateModule } from '@ngx-translate/core';
import { ProjectListItemComponent } from './project-list-item/project-list-item.component';
import { CoreModule } from '@core/core.module';
import { GeneralModule } from '@modules/general/general.module';
import { RouterLinkActive } from '@angular/router';
import { ProjectListPlaceholderComponent } from './project-list-placeholder/project-list-placeholder.component';
import { FactSheetComponent } from './factsheet/fact-sheet.component';
import { FactsheetHeaderComponent } from '@modules/project/factsheet/factsheet-header/factsheet-header.component';
import { FactsheetOverviewComponent } from '@modules/project/factsheet/factsheet-overview/factsheet-overview.component';
import { FactsheetProjectStickerComponent } from '@modules/project/factsheet/factsheet-project-sticker/factsheet-project-sticker.component';
import { FactsheetContactsComponent } from '@modules/project/factsheet/factsheet-contacts/factsheet-contacts.component';
import { ProjectDetailsComponent } from '@modules/project/project-details/project-details.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ProjectEmployeeListComponent } from '@modules/project/project-employee-list/project-employee-list.component';
import { NoFactsheetComponent } from './factsheet/no-factsheet/no-factsheet.component';

@NgModule({
  declarations: [
    ProjectListComponent,
    ProjectListItemComponent,
    ProjectListPlaceholderComponent,
    ProjectDetailsComponent,
    FactSheetComponent,
    FactsheetHeaderComponent,
    FactsheetOverviewComponent,
    FactsheetProjectStickerComponent,
    ProjectEmployeeListComponent,
    FactsheetContactsComponent,
    NoFactsheetComponent
  ],
  exports: [ProjectListComponent],
  imports: [
    CommonModule,
    EbcontGalaxyModule,
    FontAwesomeModule,
    HocModule,
    TeamModule,
    TranslateModule,
    CoreModule,
    GeneralModule,
    NgOptimizedImage,
    RouterLinkActive
  ]
})
export class ProjectModule {}
