<h3>{{ "action.download_profile" | translate }}</h3>

<form [formGroup]="downloadForm">
  <div>
    <label for="format">{{ 'team.download_profile.format'|translate }}</label>

    <gx-menu class="my-2" id="format" formControlName="format"
             [menuItems]="[{label: 'PDF', isRouterLink: false, id: 'pdf'}, {label: 'DOCX', isRouterLink: false, id: 'docx'}]"
             [multiSelect]="false"
    ></gx-menu>
  </div>
  <div>
    <label for="language">{{ 'language.singular'|translate }}</label>
    <gx-menu id="language" class="my-2" formControlName="language"
             [menuItems]="[{label: 'language.de'| translate, isRouterLink: false, id: 'de'}, {label: 'language.en'| translate, isRouterLink: false, id: 'en'}]"
             [multiSelect]="false"
    ></gx-menu>
  </div>
  <div>
    <label for="original">{{ 'team.download_profile.content'|translate }}</label>
    <gx-menu class="my-2" id="original" formControlName="original"
             [menuItems]="[{label: 'team.download_profile.original'| translate, isRouterLink: false, id: 'true'}, {label: 'team.download_profile.translation'| translate, isRouterLink: false, id: 'false'}]"
             [multiSelect]="false"
    ></gx-menu>
  </div>

  <!-- TODO input spacing is temporary -->
  <div class="my-4">
    <label>
      <input type="checkbox" class="mr-1" formControlName="short">
      {{ 'team.download_profile.short'|translate }}
    </label>
  </div>

  <!-- TODO input spacing is temporary -->
  <div class="my-4">
    <label>
      <input type="checkbox" class="mr-1" formControlName="anonymous">
      {{ 'team.download_profile.anonymous'|translate }}
    </label>
  </div>

  <!-- TODO  spacing is temporary -->
  <div class="mt-6 flex flex-col gap-y-4">
    <div class="flex justify-evenly">
      <gx-button (click)="updateHandler()" variant="neutral" [text]="'action.update' |translate"></gx-button>
      <gx-button (click)="copyLinkHandler()" variant="neutral" [text]="'action.copy_link.text' |translate"></gx-button>
    </div>
    <gx-button variant="prime" (click)="downloadHandler()" [text]="'action.download' |translate"></gx-button>
  </div>
</form>
