import { Injectable } from '@angular/core';
import { AbstractBackendService, TypeaheadItem } from '@core/prototypes/backend.service';
import { HttpClient } from '@angular/common/http';
import { ExceptionHandlerService } from '../exceptionhandler/exception.handler.service';
import { catchError, Observable, tap } from 'rxjs';
import { Project, ProjectFilter } from '../../models/project';
import { ListResult } from '@core/prototypes/list.result';
import { httpParamsFrom } from '@core/utils/custom.http.param.encoder';
import { emitSimpleEvent, SIMPLE_EVENTS } from '@core/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService extends AbstractBackendService<Project> {
  constructor(http: HttpClient, private exceptionService: ExceptionHandlerService) {
    super(`/api/projects/`, http);
  }

  override typeahead(text: string): Observable<TypeaheadItem[]> {
    return this.http
      .get<TypeaheadItem[]>(this.endPointUrl + 'typeahead?searchValue=' + text)
      .pipe(catchError(this.exceptionService.handleError<TypeaheadItem[]>()));
  }

  // eslint-disable-next-line unused-imports/no-unused-vars,@typescript-eslint/no-unused-vars
  override autocomplete(text: string, subPath?: string): Observable<string[]> {
    return this.http
      .get<string[]>(this.endPointUrl + 'autocomplete?searchValue=' + text)
      .pipe(catchError(this.exceptionService.handleError<string[]>()));
  }

  override list(filter: ProjectFilter, emitEvent?: boolean): Observable<ListResult<Project>> {
    // project code comes from the filter, but we don't want to send it to the server
    const httpParams = httpParamsFrom({ ...filter, projectCode: undefined });

    return this.http.get<ListResult<Project>>(this.endPointUrl + 'search', { params: httpParams }).pipe(
      tap((result) => {
        if (emitEvent) {
          emitSimpleEvent(SIMPLE_EVENTS.DOWNLOAD_PROGRESS, { filter, result });
        }
      }),
      catchError(this.exceptionService.handleError<ListResult<Project>>())
    );
  }

  technologies(text: string): Observable<string[]> {
    return this.http
      .get<string[]>(this.endPointUrl + 'factsheet-technologies?searchValue=' + text)
      .pipe(catchError(this.exceptionService.handleError<string[]>()));
  }
}
