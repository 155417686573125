import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExceptionHandlerService } from '../exceptionhandler/exception.handler.service';
import { catchError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  static USER_BASE_URL = '/api';

  constructor(
    private http: HttpClient,
    private exceptionService: ExceptionHandlerService,
  ) {
  }

  getAdminStatus() {
    return this.http
      .get<boolean>(`${UserService.USER_BASE_URL}/system/isAdmin`)
      .pipe(catchError(this.exceptionService.handleError<boolean>()));
  }
}
