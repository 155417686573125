/* eslint-disable @typescript-eslint/no-explicit-any,no-case-declarations */
import { Facet } from '../models/facet';
import { MenuItem } from '@ebcont/galaxy';
import { TypeaheadItem } from '../prototypes/backend.service';
import * as XLSX from 'xlsx';

export const PLACEHOLDER_IMAGE = '/assets/images/image-avatar-fallback.svg';

export const asEnumValue = <T>(enumeration: { [s: string]: T }, value: string): T | undefined => {
  return (Object.values(enumeration) as unknown as string[]).includes(value) ? (value as unknown as T) : undefined;
};

/**
 * Converts a list of facets to a list of menu items.
 * @param facets The facets to convert
 * @returns The list of menu items created from the facets
 */
export const facetsAsMenuItems = (facets: Facet[]): MenuItem[] => {
  return facets.map((facet) => {
    return { label: facet.value, id: facet.value } as MenuItem;
  });
};

/**
 * Converts a list of typeahead items to a list of menu items.
 * @param typeaheads The typeahead items to convert
 * @returns The list of menu items created from the typeahead items
 */
export const typeaheadsAsMenuItems = (typeaheads: TypeaheadItem[]): MenuItem[] => {
  return typeaheads.map((item) => {
    return { label: item.name, id: item.id } as MenuItem;
  });
};

/**
 * @param value The value to check
 * @returns True if the value is undefined, null or an empty string
 */
export const stringIsEmpty = (value?: string): boolean => {
  return value === undefined || value === null || value.trim() === '';
};

/**
 * Converts a list of values to a list of menu items.
 * @param values The values to convert
 * @param prefix The optional prefix to add before each value as label
 * @returns The list of menu items created from the values
 */
export const valuesAsMenuItems = (values: (string | number)[], prefix?: string): MenuItem[] => {
  return Array.from(new Set(values)).map((value) => {
    return {
      label: prefix ? `${prefix}${value}` : value.toString(),
      id: value.toString()
    } as MenuItem;
  });
};

export const REGEXP_NUMERIC_ONLY = new RegExp('^[+-]?((\\d+(\\.\\d*)?)|(\\.\\d+))$');

/**
 * Compares two numbers.
 * @param a The first number
 * @param b The second number
 * @returns A negative number if a is smaller than b, a positive number if a is greater than b, 0 if a and b are equal
 */
export const numberComparator = (a: number, b: number): number => {
  return a - b;
};

/**
 * Checks if a value is a valid number (integer or float).
 * @param value The value to check
 * @returns True if the value is a valid number (integer or float)
 */
export const numericMatcher = (value: string): boolean => {
  return REGEXP_NUMERIC_ONLY.test(value);
};

/**
 * @type Comparator
 * @description Defines a function that compares two objects and returns a number.
 *
 * @param a The first object
 * @param b The second object
 * @returns A negative number if a is smaller than b, a positive number if a is greater than b, 0 if a and b are equal
 */
export type Comparator = <T extends object>(a: T, b: T) => number;

/**
 * Returns the value of a field of an object as number.
 * @param subject The object to get the field value from
 * @param fieldName The name of the field to get the value from
 * @param defaultVal The default value to return if the field value is not a number
 * @returns The value of the field as number
 */
export const getNumberField = <T extends object>(subject: T, fieldName: string, defaultVal = 0): number => {
  const fromObject = Reflect.get(subject, fieldName);
  switch (typeof fromObject) {
    case 'number':
      return fromObject;
    case 'string':
      return isNaN(parseInt(fromObject, 10)) ? defaultVal : parseInt(fromObject, 10);
    default:
      return defaultVal;
  }
};

/**
 * Creates a comparator that compares two objects by a numeric field.
 * @param fieldName The name of the field to compare
 * @param ordering The ordering of the comparator (asc or desc)
 * @param defaultVal The default value to return if the field value is not a number
 */
export const createNumericComparator = (
  fieldName: string,
  ordering: 'asc' | 'desc' = 'asc',
  defaultVal = 0
): Comparator => {
  return (a, b) => {
    return ordering === 'asc'
      ? getNumberField(a, fieldName, defaultVal) - getNumberField(b, fieldName, defaultVal)
      : getNumberField(b, fieldName, defaultVal) - getNumberField(a, fieldName, defaultVal);
  };
};

export const getStringField = <T extends object>(subject: T, fieldName: string, defaultVal = ''): string => {
  const fromObject = Reflect.get(subject, fieldName);
  switch (typeof fromObject) {
    case 'string':
      return fromObject;
    case 'number':
      return fromObject.toString();
    default:
      return defaultVal;
  }
};

/**
 * Compares multiple numbers based on their position in the array
 * @param array The numbers to compare
 */
export function compareBy(...array: number[]): number {
  for (let i = 0; i < array.length; i++) {
    const a = array[i];
    const b = array[++i];

    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
  }
  return 0;
}

/**
 * Creates a comparator that compares two objects by a string field.
 * @param fieldName The name of the field to compare
 * @param ordering The ordering of the comparator (asc or desc)
 * @param defaultVal The default value to return if the field value is not a string
 */
export const createStringComparator = (
  fieldName: string,
  ordering: 'asc' | 'desc' = 'asc',
  defaultVal = ''
): Comparator => {
  return (a, b): number => {
    return ordering === 'asc'
      ? getStringField(a, fieldName, defaultVal).toLowerCase().localeCompare(getStringField(b, fieldName, defaultVal))
      : getStringField(b, fieldName, defaultVal).toLowerCase().localeCompare(getStringField(a, fieldName, defaultVal));
  };
};

/**
 * Returns the number from a composite string.
 * @param value The composite string
 * @param separator The separator of the composite string
 * @param index The index of the number in the composite string
 * @param defaultVal The default value to return if the number is not a valid number
 */
export const getNumberFromCompositeString = (value: string, separator = ' ', index = 0, defaultVal = 0): number => {
  const parts = value.split(separator);
  return isNaN(parseInt(parts[index], 10)) ? defaultVal : parseInt(parts[index], 10);
};

/**
 * Converts a string to a menu item.
 * @param text The text to convert
 * @returns The menu item created from the text, ID and LABEL are both the text
 */
export const textAsMenuItem = (text: string): MenuItem => {
  return { id: text, label: text } as MenuItem;
};

/**
 * @param array1 The first array
 * @param array2 The secons array
 * @returns The intersection of the two arrays
 */
export const intersection = <T>(array1: T[], array2: T[]): T[] => {
  return array1.filter((value) => {
    return array2.includes(value);
  });
};

/**
 * Warning! This is a simple implementation, does not handle dates, functions, etc.
 * @param obj The object to copy
 * @returns A deep copy of the object
 */
export const deepCopy = <T>(obj: T): T => {
  return JSON.parse(JSON.stringify(obj));
};

/**
 * @type GetValueFunction
 * @description Defines a function that gets a value from an object. It can also be used to create a new object,
 * depending on the value type.
 * @param T The type of the object
 * @param U The type of the value to get
 * @param data The object to get the value from
 * @returns The value from the object
 */
export type GetValueFunction<T extends object, U> = (data: T) => U;

/**
 * @param data The data objects
 * @param getValueFn The function to get the value from the object
 * @returns The values from the objects
 */
export const getValues = <T extends object, U>(data: T[], getValueFn: GetValueFunction<T, U>): U[] => {
  return data.map((item) => {
    return getValueFn(item);
  });
};

/**
 * Saves the given data and filter into an XLSX file. The data will be written to the first sheet named "Data" and
 * the filter will be written to the second sheet named "Filter parameters". The .XLSX extension will be appended
 * automatically.
 * @param data The data object
 * @param filter The filter object
 * @param fileName The name of the file to save into without extension
 */
export const downloadJSONAsXLSX = (data: any, filter: any, fileName: string): void => {
  const dataSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
  const cols: { wch: number }[] = [];
  Object.keys(data[0]).forEach(() => {
    cols.push({ wch: 30 });
  });
  dataSheet['!cols'] = cols;

  const filterSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
    asKeyValues(filter).filter((item) => {
      return !item.key.startsWith('page');
    })
  );
  filterSheet['!cols'] = cols;

  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, dataSheet, 'Data');
  XLSX.utils.book_append_sheet(wb, filterSheet, 'Filter parameters');

  /* save to file */
  XLSX.writeFile(wb, `${fileName}.xlsx`);
};

export interface KeyValue {
  key: string;
  value: string;
}

/**
 * @param object The object to convert
 * @returns The object as a list of key-value pairs
 */
export const asKeyValues = (object: object): KeyValue[] => {
  return Object.keys(object).map((key) => {
    return { key, value: Reflect.get(object, key) };
  });
};

export enum SIMPLE_EVENTS {
  DOWNLOAD_STARTING = 'download-starting',
  DOWNLOAD_PROGRESS = 'download-progress',
  DOWNLOAD_COMPLETED = 'download-completed'
}

/**
 * Emits a simple event with the given name and detail.
 * @param eventName The name of the event
 * @param detail The detail of the event
 */
export const emitSimpleEvent = (eventName: SIMPLE_EVENTS, detail?: any): void => {
  document.dispatchEvent(new CustomEvent(eventName, { detail }));
};

export const parseStringToXML = (xmlString: string): XMLDocument => {
  const cleaned = xmlString.replaceAll(/xmlns="[^"]*"/gm, '');
  return new DOMParser().parseFromString(cleaned, 'text/xml');
};

/**
 * @param xml The XML document
 * @param xPath The XPath to get the element from
 * @returns The element from the XML document
 */
export const getElementByXpath = (xml: XMLDocument, xPath: string): XPathResult | undefined => {
  return xml.evaluate(xPath, xml, xml.createNSResolver(xml), XPathResult.FIRST_ORDERED_NODE_TYPE, null);
};

/**
 * @param xml The XML document
 * @param xPath The XPath to get the elements from
 * @returns The elements from the XML document
 */
export const getElementsByXpath = (xml: XMLDocument, xPath: string): XPathResult | undefined => {
  return xml.evaluate(xPath, xml, xml.createNSResolver(xml), XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null);
};

/**
 * @param value The XPath result
 * @param defaultValue The default value to return if the result is undefined
 */
export const getSingleString = (value?: XPathResult, defaultValue = 'n/a'): string => {
  return value?.singleNodeValue?.textContent ?? defaultValue;
};

/**
 * @param value The XPath result
 * @returns The nodes from the snapshot
 */
export const getNodesFromSnapshot = (value?: XPathResult): Node[] => {
  const result: Node[] = [];
  if (!value) {
    return result;
  }
  for (let i = 0; i < value.snapshotLength; i++) {
    const node = value.snapshotItem(i);
    if (node) {
      result.push(node.cloneNode(true));
    }
  }
  return result;
};

/**
 * @param value The XPath result
 * @param defaultValue The default value to return if the value is undefined
 * @returns The string values from the XPath result
 */
export const getMultipleStrings = (value?: XPathResult, defaultValue: string[] = []): string[] => {
  if (!value) {
    return defaultValue;
  }
  const result: string[] = [];
  getNodesFromSnapshot(value).forEach((node) => {
    if (node.textContent) {
      result.push(node.textContent);
    }
  });
  return result;
};

/**
 * @param value The XPath result
 * @param attributes The attributes to get from the elements
 * @param defaultValue The default value to return if the value is undefined
 */
export const getItemsWithAttributes = (
  value?: XPathResult,
  attributes: string[] = [],
  defaultValue: string[][] = []
): string[][] => {
  const result: string[][] = [];
  if (!value) {
    return defaultValue;
  }
  getNodesFromSnapshot(value).forEach((node) => {
    const item: string[] = [];
    if (node.textContent) {
      item.push(node.textContent);
    }
    for (const attribute of attributes) {
      item.push((node as Element).getAttribute(attribute) ?? '');
    }
    result.push(item);
  });
  return result;
};
