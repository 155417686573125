<div class="mx-8 flex flex-wrap justify-evenly gap-8">
  <a class="--card" *ngFor="let item of links" [href]="item.link" target="_blank"
     [id]="'card-'+ item.rank + '-' + code">
    <div>
      <img [src]="getIcon(item)" [alt]="item.icon" class="--card-image"/>
    </div>
    <div class="--card-header">{{ getHeader(item) }}</div>
    <div class="--card-text">{{ getText(item) }}</div>
  </a>
</div>
