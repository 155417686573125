<div class="flex h-full max-h-full divide-x overflow-hidden" data-testId="project-list-container">
  <div class="flex flex-1 flex-col overflow-auto">
    <div class="flex w-full flex-col border-b border-ui-border">
      <div class="flex items-center justify-between">
        <gx-section-title
          [title]="(lastResult.totalNumberResultsets > 1 ? 'project.size.plural' : 'project.size.singular') | translate  : { projects: lastResult.totalNumberResultsets || 0} "
          class="px-sx"/>

        <gx-button
          *ngIf="isAdmin"
          [text]="'downloadTheList' | translate"
          [title]="'downloadTheList' | translate"
          [icon]="csvExportIcon"
          textClass="hidden md:inline"
          class="mr-4"
          [isDisabled]="listIsDownloading"
          (click)="downloadTheList()"></gx-button>
      </div>

      <hoc-filter-block [textSearch]="searchForName" (changed)="filterBlockChanged($event)" [disableSorting]="true"
                        [filters]="filtersForBlock"></hoc-filter-block>
    </div>

    <hoc-grouped-list
      [loading]="loading"
      [groupLetters]="groupLetters"
      [items]="items"
      [headerBlock]="headerBlock"
      [loadingBlock]="loadingBlock"
      [footerBlock]="footerBlock"
      [groupBlock]="groupBlock"
      [itemBlock]="itemBlock"
      [emptyItemsBlock]="emptyItemsBlock"
      (scrolledToEnd)="scrolledToEnd()">
    </hoc-grouped-list>
  </div>
  <div
    @slideInFromRight
    class="absolute inset-0 z-10 flex flex-1 flex-col overflow-auto bg-ui-surface-rise md:static"
    *ngIf="isDrawerOpen && items.size > 0">
    <app-project-details [project]="selectedProject" [isAdmin]="isAdmin"
                         (closeClicked)="closeDrawer()"></app-project-details>
  </div>
</div>

<ng-template #emptyItemsBlock>
  <app-empty-list-page (backButtonClicked)="handleEmptyList()"></app-empty-list-page>
</ng-template>

<ng-template #headerBlock>
</ng-template>

<ng-template #groupBlock let-letter>
  <h3 class="list-title-text">{{ letter }}</h3>
</ng-template>

<ng-template #loadingBlock>
  <ng-container *ngTemplateOutlet="loadingTitle"></ng-container>
  <ng-container *ngFor="let item of loadingSkeletonSize">
    <app-project-list-placeholder/>
  </ng-container>
</ng-template>

<ng-template #loadingTitle>
  <div class="list-title">
    <h3 class="list-title-text my-1 ms-4"></h3>
  </div>
</ng-template>

<ng-template #itemBlock let-item>
  <app-project-list-item [project]="item" [isAdmin]="isAdmin" (click)="clickedOnProject(item)"></app-project-list-item>
</ng-template>

<ng-template #footerBlock>
  <div #footerContainer class="px-3 py-4">
    <gx-pagination
      *ngIf="loadMode === 'paged' && items.size > 0"
      [data]="pagination"
      (newPage)="gotoPage($event)"
      [availablePageSizes]="config.pageSizes"
      [currentPageSize]="filter.pageSize"
      [lengthBeginSegment]="2"
      [lengthEndSegment]="2"
      (newPageSize)="newPageSize($event)"/>
  </div>
</ng-template>
