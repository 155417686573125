import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'hoc-grouped-list',
  templateUrl: './hoc-grouped-list-component.html',
  styleUrls: ['./hoc-grouped-list-component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HocGroupedListComponent {
  @Input() isExtended = true;
  @Input() headerBlock?: TemplateRef<Element>;
  @Input() footerBlock?: TemplateRef<Element>;
  @Input() itemBlock?: TemplateRef<Element>;
  @Input() emptyItemsBlock?: TemplateRef<Element>;
  @Input() groupBlock?: TemplateRef<Element>;
  @Input() loadingBlock?: TemplateRef<Element>;
  @Input() loading = true;
  @Input() groupLetters: string[] = [];
  @Input() items: Map<string, unknown[]> = new Map();
  @Output() scrolledToEnd = new EventEmitter<void>();

  /**
   * @param letter The letter to get the items for
   * @returns The items for the given letter
   */
  public getItemsForLetter(letter: string): unknown[] {
    return this.items.get(letter) ?? [];
  }

  /**
   * The "scrollEnd" is a throttled event happening only once when the user scrolls to the top or bottom of the list.
   * When the user scrolls to the end, the "scrolledToEnd" event is emitted.
   */
  public scrollEnd() {
    this.scrolledToEnd.emit();
  }
}
