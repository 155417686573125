/* eslint-disable @typescript-eslint/no-explicit-any */
import { CoreCompetence } from './core.competence';
import { EmployeeListProject } from './project';
import { Skill } from './skill';
import { Position } from './position';
import { GroupingOperator } from '../prototypes/grouping';
import { Filter } from '../prototypes/filtering';
import { ApplicationConfiguration } from '../services/configuration/application.configuration.interface';
import { InjectorKeys } from '../utils/injector.keys';
import { Inject } from '@angular/core';
import { DownloadMapperFunction } from '@core/prototypes/backend.service';

export interface EmployeeDetails {
  id: string; // UUID
  name: string;
  hasHr4youData: boolean;
  email: string;
  nationalityCountryCode: string;
  nationalityCountryCodeText: string;
  phone: string;
  photo?: string;
  roleDescription: string;
  company?: string;
  educations: string[];
  coreCompetences: CoreCompetence[];
  languages: string[];
  projects: EmployeeListProject[];
  skillTree: Skill[];
  positions: Position[];
  boards: string[];
  area?: string;
}

export interface Employee {
  id: string; // UUID
  possibleIds: string[]; // UUIDs
  businessPartnerFormattedName: string; // employeeDetails.name
  familyName: string;
  photo?: string;
  companyName?: string;
  roleDescription: string;
  area?: string;
  boards: string[];
  projectCount: number;
  email: string;
  positions: Position[]; // locations come from here
  phone?: string;
  location?: string;
  skill?: string;
}

export interface EmployeeFilter extends Filter {
  searchValue?: string;
  locations?: string;
  companies?: string;
  areas?: string;
  gremien?: string;
  customerIds?: string;
  languages?: string;
  projectIds?: string;
}

export interface EmployeeSkillFilter {
  skill: string;
  skillLevel: number;
  sortDirection: string;
  pageNumber: number;
  pageSize: number;
}

export interface EmployeeCoreCompetenceFilter {
  searchValue: string;
  sortDirection: string;
  pageNumber: number;
  pageSize: number;
  sortValue: string;
}

export const updateEmployeeFilter = (filter: EmployeeFilter, params: Record<string, string>): EmployeeFilter => {
  // we only inherit the values of the active only, page number and page size, every other value is overwritten
  return {
    activeOnly: filter.activeOnly,
    pageNumber: filter.pageNumber,
    pageSize: filter.pageSize,
    ...params
  };
};

export const basicEmployeeFilter = (config: ApplicationConfiguration): EmployeeFilter => {
  return {
    activeOnly: config.activeOnly?.toString() ?? true,
    pageNumber: 0,
    pageSize: config.pageSize ?? 10
  };
};

export class EmployeeFilterBuilder {
  private readonly filter: EmployeeFilter;

  constructor(@Inject(InjectorKeys.CONFIGURATION) config: ApplicationConfiguration) {
    this.filter = basicEmployeeFilter(config);
  }

  withActiveOnly(activeOnly: boolean): this {
    this.filter.activeOnly = activeOnly.toString();
    return this;
  }

  withSearchValue(searchValue: string): this {
    this.filter.searchValue = searchValue;
    return this;
  }

  withPageNumber(pageNumber: number): this {
    this.filter.pageNumber = pageNumber;
    return this;
  }

  withPageSize(pageSize: number): this {
    this.filter.pageSize = pageSize;
    return this;
  }

  withPartialFilter(filter: Partial<EmployeeFilter>): this {
    Object.assign(this.filter, filter);
    return this;
  }

  build(): EmployeeFilter {
    return this.filter;
  }
}

export const employeeFilterBuilder = (config: ApplicationConfiguration): EmployeeFilterBuilder => {
  return new EmployeeFilterBuilder(config);
};

export const employeeGroupingOperator: GroupingOperator<Employee> = (
  employees: Employee[],
  sorting: 'asc' | 'desc'
): Map<string, Employee[]> => {
  const map = new Map<string, Employee[]>();
  employees.forEach((item: Employee) => {
    const key = item.familyName.substring(0, 1).toUpperCase();
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  }); // collect all
  if (sorting === 'desc') {
    return new Map([...map.entries()].sort().reverse());
  }
  return map;
};

export const employeeDownloadMapper: DownloadMapperFunction = (
  item: any
): Record<string, string | number | boolean> => {
  const employee = item as Employee;
  return {
    Name: employee.businessPartnerFormattedName,
    Email: employee.email,
    Role: employee.roleDescription,
    Phone: employee.phone ?? 'n/a',
    Location: employee.location ?? 'n/a',
    Company: employee.companyName ?? 'n/a'
  };
};
