import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { InjectorKeys } from '@core/utils/injector.keys';
import { BackendService } from '@core/prototypes/backend.service';
import { AbstractGroupedList } from '@hoc/hoc-grouped-list';
import { Employee, EmployeeCoreCompetenceFilter, employeeGroupingOperator } from '@core/models/employee';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationConfiguration } from '@core/services/configuration/application.configuration.interface';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-core-competence-employee-list',
  templateUrl: './core-competence-employee-list.component.html',
  styleUrls: ['./core-competence-employee-list.component.css']
})
export class CoreCompetenceEmployeeListComponent extends AbstractGroupedList<Employee> implements OnChanges {
  @Input() isAdmin = false;
  @Input() coreCompetence? = '';
  @Input() sortDirection = 'asc';

  public override filter: EmployeeCoreCompetenceFilter = {
    searchValue: '',
    sortDirection: this.sortDirection,
    pageNumber: 0,
    pageSize: 10,
    sortValue: 'FamilyName'
  };

  constructor(
    @Inject(InjectorKeys.EMPLOYEES_CORE_COMPETENCE_SERVICE) private service: BackendService<Employee>,
    cd: ChangeDetectorRef,
    router: Router,
    route: ActivatedRoute,
    @Inject(InjectorKeys.CONFIGURATION) public config: ApplicationConfiguration
  ) {
    super(service, employeeGroupingOperator, config, cd, router, route);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes['coreCompetence'] && changes['coreCompetence'].currentValue) ||
      (changes['sortDirection'] && changes['sortDirection'].currentValue)
    ) {
      this.filter.searchValue = this.coreCompetence ?? '';
      this.sorting = this.sortDirection as 'asc' | 'desc';
      this.filter.sortDirection = this.sortDirection;
      this.filter.pageNumber = 0;
      this.reloadList();
    }
  }

  private reloadList(): void {
    if (this.coreCompetence && this.coreCompetence.trim().length > 0) {
      this.reload();
    }
  }

  protected readonly JSON = JSON;
  loadingSkeletonSize = Array.from(Array(4).keys());

  getPersonLink(item: Employee) {
    return (
      'team?activeOnly=false&pageNumber=0&pageSize=10&searchValue=' +
      item.businessPartnerFormattedName +
      `&sorting=asc&employee=${item.email}&tab=profile`
    );
  }
}
