import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuItem } from '@ebcont/galaxy';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faRightFromBracket, faUser } from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'hoc-user-menu',
  templateUrl: './hoc-user-menu.component.html',
  styleUrls: ['./hoc-user-menu.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HocUserMenuComponent {
  @Input()
  public label = '';

  @Input()
  public items: MenuItem[] = [];

  @Input()
  public menuIcon?: IconProp = 'user';

  @Output() navigation = new EventEmitter<unknown>();

  /** The list of item codes. */
  protected itemCodes = ['profile', 'logout'];
  /** The icons for the items. */
  protected icons = [faUser, faRightFromBracket];

  /**
   * @param code The code of the item.
   * @returns The icon for the item.
   */
  protected getIcon(code: string): IconDefinition {
    const index = this.itemCodes.indexOf(code);
    return index >= 0 ? this.icons[index] : this.icons[0];
  }
}
