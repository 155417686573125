<div class="__bg_surface_rise">
  <div class="__image"></div>
  <div class="__infos">
    <div class="__name">&nbsp;</div>
    <div class="__description">&nbsp;</div>
  </div>
  <div class="__buttons">
    <div class="__button_border">
      <div class="__button_content">&nbsp;</div>
    </div>
    <div class="__button_border_small">
      <div class="__button_content_small">&nbsp;</div>
    </div>
  </div>
</div>
<div class="__tabs">
  <div class="__element_large">&nbsp;</div>
  <div class="__element_large">&nbsp;</div>
  <div class="__element_large">&nbsp;</div>
</div>

<div class="__bg_slate">
  <div class="__row">
    <div class="__search_bar_border">
      <div class="__search_content">&nbsp;</div>
    </div>
    <div class="__button_border_small">
      <div class="__button_content_small">&nbsp;</div>
    </div>
  </div>

  <div class="__row">
    <div class="__element_small">&nbsp;</div>
    <div class="__button_border">
      <div class="__button_content">&nbsp;</div>
    </div>
  </div>

  <div class="__line">&nbsp;</div>

  <div class="__row">
    <div class="__element_small">&nbsp;</div>
  </div>
</div>

<div class="__bg_surface_rise">
  <div class="__column">
    <ng-container *ngFor="let item of loadingSkeletonSize">
      <div class="__row">
        <div class="__column">
          <div class="__element">&nbsp;</div>
          <div class="__element_small">&nbsp;</div>
        </div>
        <div class="__column">
          <div class="__element">&nbsp;</div>
          <div class="__element_large">&nbsp;</div>
        </div>
        <div class="__column">
          <div class="__element">&nbsp;</div>
          <div class="__element_small">&nbsp;</div>
        </div>
        <div class="__button_border_small">
          <div class="__button_content_small">&nbsp;</div>
        </div>
      </div>
      <div class="__line">&nbsp;</div>
    </ng-container>
  </div>
</div>

