/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Description } from '@ebcont/galaxy';
import { TranslateService } from '@ngx-translate/core';
import { getElementByXpath, getElementsByXpath, getMultipleStrings, getSingleString } from '@core/utils/utils';

@Component({
  selector: 'app-factsheet-header',
  templateUrl: './factsheet-header.component.html',
  styleUrls: ['./factsheet-header.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FactsheetHeaderComponent implements OnChanges {
  @Input() factSheet: XMLDocument | undefined;
  public generalItems: Description[] = [];

  constructor(private translate: TranslateService, public cd: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['factSheet'] && changes['factSheet'].currentValue) {
      this.factSheet = changes['factSheet'].currentValue;
      if (this.factSheet) {
        this.generalItems = [
          {
            term: this.translate.instant('project.factSheet.status'),
            description: getSingleString(getElementByXpath(this.factSheet, '//project-sticker-status'))
          },
          {
            term: this.translate.instant('project.factSheet.header.numberOfEmployees'),
            description: getMultipleStrings(
              getElementsByXpath(this.factSheet, '//contacts/ebcont/contact')
            ).length.toString()
          }
        ];
        this.cd.markForCheck();
        this.cd.detectChanges();
      }
    }
  }

  public getSingleString(xpath: string): string {
    return getSingleString(getElementByXpath(this.factSheet!, xpath));
  }

  public openConfluence(): void {
    if (!this.factSheet) {
      return;
    }
    const url = getSingleString(getElementByXpath(this.factSheet, '//confluence-url'), undefined);
    if (url) {
      window.open(url, '_blank');
    }
  }
}
