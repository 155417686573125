<ng-template #header>

</ng-template>
<ng-template #content>

</ng-template>

<ng-template #footer let-command>
  <app-hoc-profile-download-form-component
    [email]="email"
    (closeDialog)="closeDialog($event)"/>
</ng-template>


