import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faEllipsisV, faPlus } from '@fortawesome/free-solid-svg-icons';
import { EmployeeDetails } from '@core/models/employee';
import { GalaxyDialogService, MenuItem } from '@ebcont/galaxy';
import { UserService } from '@core/services/user/user.service';

@Component({
  selector: 'hoc-person-card',
  templateUrl: './hoc-person-card.component.html',
  styleUrls: ['./hoc-person-card.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HocPersonCardComponent implements OnInit {
  /** @ignore */
  public name = '';
  /** @ignore */
  public image = '';
  /** @ignore */
  public items: { term: string; description: string }[] = [];
  public ellipsisIcon = faEllipsisV;
  public menuItems: MenuItem[];
  public isAdmin = false;

  protected employeeDetails: EmployeeDetails | undefined;
  protected commandId = '';

  @Input() set details(employeeDetailsParam: EmployeeDetails | undefined) {
    this.employeeDetails = employeeDetailsParam;
    this.refreshData();
  }

  constructor(library: FaIconLibrary, private userService: UserService, dialogService: GalaxyDialogService) {
    library.addIcons(faPlus);
    this.menuItems = [
      {
        label: 'action.download_profile',
        isRouterLink: false,
        id: 'level',
        clickEvent: () => {
          dialogService.add({}, 'profileDownload');
        }
      }
    ];
  }

  public call() {
    window.open(`tel:${this.employeeDetails?.phone}`, '_self');
  }

  public mailto() {
    window.open(`mailto:${this.employeeDetails?.email}`);
  }

  ngOnInit(): void {
    this.updateAdminStatus();
    this.refreshData();
  }

  private updateAdminStatus() {
    this.userService.getAdminStatus().subscribe((value: boolean) => {
      this.isAdmin = value;
    });
  }

  private refreshData(): void {
    if (this.employeeDetails) {
      this.name = this.employeeDetails.name;
      this.image = this.employeeDetails.photo ?? '';
      this.items = [
        { term: 'team.role', description: this.employeeDetails.roleDescription },
        { term: 'team.mobile', description: this.employeeDetails.phone },
        { term: 'team.email', description: this.employeeDetails.email }
      ];
    }
  }

  isMyProfile() {
    // return this.userService.getEmail() === this.employeeDetails?.email;
    return true;
  }

  onDropDownClick($event: MenuItem[]) {
    if ($event.length !== 1) {
      return;
    }
    const event = $event[0];

    if (event.clickEvent) {
      event.clickEvent();
    } else {
      // TODO implement router behaviour
      console.error('Routing not implemented');
    }
  }
}
