import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeamListComponent } from './components/team-list/team-list.component';
import { HocModule } from '@hoc/hoc.module';
import { HttpClientModule } from '@angular/common/http';
import { NgxTranslateModule } from '../translate/translate.module';
import { RouterLink } from '@angular/router';
import { TeamListItemComponent } from './components/team-list-item/team-list-item.component';
import { CoreModule } from '@core/core.module';
import { EbcontGalaxyModule } from '@ebcont/galaxy';
import { TeamListPlaceholderComponent } from './components/team-list-placeholder/team-list-placeholder.component';
import { EmployeeDetailsComponent } from './components/employee/employee-details.component';
import { EmployeeDetailsPlaceholderComponent } from '@modules/team/components/employee-details-placeholder/employee-details-placeholder.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { GeneralModule } from '@modules/general/general.module';
import { EmployeeToItemPipe } from './employee-to-item.pipe';
import { EmployeeProfileComponent } from './components/employee-profile/employee-profile.component';
import { EmployeeSkillsComponent } from './components/employee-skills/employee-skills.component';
import { EmployeeProjectsComponent } from './components/employee-projects/employee-projects.component';

@NgModule({
  declarations: [
    TeamListComponent,
    TeamListItemComponent,
    TeamListPlaceholderComponent,
    EmployeeDetailsComponent,
    EmployeeDetailsPlaceholderComponent,
    EmployeeToItemPipe,
    EmployeeProfileComponent,
    EmployeeSkillsComponent,
    EmployeeProjectsComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [TeamListComponent, TeamListComponent, TeamListPlaceholderComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    NgxTranslateModule,
    RouterLink,
    EbcontGalaxyModule,
    HocModule,
    CoreModule,
    FontAwesomeModule,
    GeneralModule
  ]
})
export class TeamModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far);
  }
}
