<div *ngIf="employee && employee.skillTree && employee.skillTree.length > 0">
  <div class="-mx-6 flex flex-wrap items-center gap-3 border-b border-ui-border bg-ui-surface p-4 lg:px-4">
    <hoc-filter-block [textSearch]="searchForName" (changed)="filterBlockChanged($event)" [embedded]="true"
                      [statusFilterVisible]=false [filters]="filtersForBlock" defaultSorting="level"
                      [sortingItems]="skillSortItems"></hoc-filter-block>
  </div>

  <gx-tree-view
    *ngIf="filteredSkills"
    [comparator]="comparator"
    [data]="filteredSkills">
    <ng-template #grouping let-data>
      <div class="sticky top-0 z-10 border-b border-b-ui-border bg-ui-surface px-sx py-1.5">
        <h3 class="m-0 text-sm font-semibold leading-6">{{ data.item.name }}*</h3>
      </div>
    </ng-template>
    <ng-template #summary let-data>
      <summary
        class="cursor-pointer p-3 text-lg font-semibold ring-2 ring-inset ring-transparent transition hover:bg-ui-surface-rise hover:text-ui-interactive-hover focus-visible:text-ui-interactive-hover focus-visible:outline-none focus-visible:ring-ui-focus focus-visible:ring-offset-2 lg:px-6">
        {{ data.item.name }}**
      </summary>
    </ng-template>
    <ng-template #details let-data>
      <div
        class="leaf my-1 flex w-full justify-between gap-2 rounded-full bg-ui-surface px-4 py-2">
        <div class="text-ui-interactive">
          {{ data.data.name }}***
        </div>
        <div class="flex items-center gap-3 @lg:w-auto @lg:justify-end">
          <div class="text-sm">{{ 'years' | translate:{data: data.data.years ? data.data.years : "n/a"} }}</div>
          <gx-rating [rating]="data.data.factor" activeRatingClass="__full-star"
                     inactiveRatingClass="__empty-star"/>
        </div>
      </div>
    </ng-template>
  </gx-tree-view>
</div>
<!--<div-->
<!--  *ngIf="!employee || !employee?.skillTree || employee!.skillTree!.length <= 0">{{ 'employee.skills.no_skills' | translate }}-->
<!--</div>-->

