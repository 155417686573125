import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HocModule } from '@hoc/hoc.module';
import { NgxTranslateModule } from '@modules/translate/translate.module';
import {
  EbcontGalaxyModule,
  GalaxyDialogService,
  GalaxyInjectorKeys,
  GalaxyLoggingService,
  GalaxySettingsService,
  GalaxyToastService,
  GxBreadcrumbsComponent
} from '@ebcont/galaxy';
import { InjectorKeys } from '@core/utils/injector.keys';
import { ApplicationConfigurationService } from '@core/services/configuration/application.configuration.service';
import { environment } from '@env/environment';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalService
} from '@azure/msal-angular';
import { TeamModule } from '@modules/team/team.module';
import { ProjectModule } from '@modules/project/project.module';
import { SkillModule } from '@modules/skill/skill.module';
import { LinksModule } from '@modules/useful/links.module';
import { CustomerModule } from '@modules/customer/customer.module';
import { CoreModule } from '@core/core.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from '@core/auth/auth-azure-conf';


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxTranslateModule,
    FontAwesomeModule,
    MsalModule,
    EbcontGalaxyModule,
    HocModule,
    TeamModule,
    ProjectModule,
    SkillModule,
    LinksModule,
    CustomerModule,
    CoreModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
    { provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory },
    { provide: MSAL_INTERCEPTOR_CONFIG, useFactory: MSALInterceptorConfigFactory },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    { provide: GalaxyInjectorKeys.LOGGING, useClass: GalaxyLoggingService },
    { provide: GalaxyInjectorKeys.SETTINGS, useClass: GalaxySettingsService },
    { provide: InjectorKeys.ENVIRONMENT, useValue: environment },
    { provide: InjectorKeys.CONFIGURATION, useClass: ApplicationConfigurationService },
    GalaxyDialogService,
    GalaxyToastService,
    GxBreadcrumbsComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
