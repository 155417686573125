import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  createDialog,
  DialogCloseAction,
  DialogCommand,
  GalaxyDialogService,
  STANDARD_DIALOG_ID
} from '@ebcont/galaxy';

@Component({
  selector: 'hoc-profile-download',
  templateUrl: './hoc-profile-download.component.html',
  styleUrls: ['./hoc-profile-download.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HocProfileDownloadComponent implements OnInit {
  @ViewChild('footer') footer: TemplateRef<unknown> | undefined;
  @ViewChild('content') content: TemplateRef<unknown> | undefined;
  @ViewChild('header') header: TemplateRef<unknown> | undefined;

  @Input() email?: string;
  private commandId = '';

  constructor(private service: GalaxyDialogService, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.service.command$.subscribe({
      next: (value: DialogCommand) => {
        if (value.dialogId === 'profileDownload') {
          if (value.command === 'open') {
            this.commandId = this.service.add(
              createDialog({
                ...value.options,
                footer: this.footer,
                content: this.content,
                header: this.header
              }),
              STANDARD_DIALOG_ID,
              value.commandId
            );
          } else if (value.command === 'close') {
            this.service.response$.next({
              commandId: value.commandId,
              response: DialogCloseAction.CLOSED
            });
          }
          this.cd.detectChanges();
        }
      }
    });
  }

  closeDialog(action: DialogCloseAction) {
    console.log('Close dialog action', action);
    this.service.close(this.commandId);
  }
}
