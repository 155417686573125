import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsefulLinksComponent } from './useful-links/useful-links.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HocModule } from '@hoc/hoc.module';

@NgModule({
  declarations: [UsefulLinksComponent],
  exports: [UsefulLinksComponent],
  imports: [CommonModule, FontAwesomeModule, HocModule]
})
export class LinksModule {}
