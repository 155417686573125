/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { GroupingOperator } from '@core/prototypes/grouping';
import { Inject } from '@angular/core';
import { InjectorKeys } from '@core/utils/injector.keys';
import { ApplicationConfiguration } from '@core/services/configuration/application.configuration.interface';
import { Filter } from '@core/prototypes/filtering';
import { DownloadMapperFunction } from '@core/prototypes/backend.service';

/**
 * @interface Skill
 * @description This interface represents a skill when returned as a part of the employee details
 * @param name: string The name of the skill
 * @param years?: string The years of experience
 * @param factor: number The factor of the skill (number of stars)
 * @param children?: Skill[] The child skills
 */
export interface Skill {
  name: string;
  years?: string;
  factor: number;
  children?: Skill[];
}

/**
 * @interface SkillListItem
 * @description This interface represents a skill when returned as a part of a skill/core competence search
 * @param skill: string The name of the skill/core competence
 * @param type: 'skill' | 'coreCompetence' The type of the skill/core competence
 * @param count?: number The count of the skill/core competence (how many employees have it)
 */
export interface SkillListItem {
  skill: string;
  type: 'skill' | 'coreCompetence';
  count?: number;
}

/**
 * @type SkillStats
 * @description This type represents a record of skill stats
 * @param string: string The number of stars ("1", "2", "3", "4", "5")
 * @param number: number The number of employees having the skill/core competence with that number of stars
 */
export type SkillStats = Record<string, number>;

/**
 * @function skillLevelComparator
 * @description This function compares two skills by their factor
 * @param a The first skill
 * @param b The second skill
 * @returns number The comparison result
 */
export const skillLevelComparator = (a: Skill, b: Skill) => {
  return b.factor - a.factor;
};

/**
 * @function skillYearsComparator
 * @description This function compares two skills by their years of experience. Missing values count as 0.
 * @param a The first skill
 * @param b The second skill
 * @returns number The comparison result
 */
export const skillYearsComparator = (a: Skill, b: Skill) => {
  const yearsA = parseInt(a.years ?? '0', 10);
  const yearsB = parseInt(b.years ?? '0', 10);
  return yearsB - yearsA;
};

// obsolete, not used any more
// export const skillGroupingOperator: GroupingOperator<Skill> = (
//   skills: Skill[],
//   sorting: 'asc' | 'desc'
// ): Map<string, Skill[]> => {
//   const map = new Map<string, Skill[]>();
//   skills.forEach((item: Skill) => {
//     const key = item.name.substring(0, 1).toUpperCase();
//     const collection = map.get(key);
//     if (!collection) {
//       map.set(key, [item]);
//     } else {
//       collection.push(item);
//       collection.sort((a, b) => {
//         const aName = a.name.split(' ').reverse().join('');
//         const bName = b.name.split(' ').reverse().join('');
//         return aName.localeCompare(bName) * (sorting === 'asc' ? 1 : -1);
//       });
//     }
//   }); // collect all
//   return map;
// };

/**
 * @function skillListItemGroupingOperator
 * @description This function groups skill list items by their first letter
 * @param skills The list of skill list items
 * @param sorting The sorting order
 * @returns Map<string, SkillListItem[]> The grouped skill list items
 */
export const skillListItemGroupingOperator: GroupingOperator<SkillListItem> = (
  skills: SkillListItem[],
  sorting: 'asc' | 'desc'
): Map<string, SkillListItem[]> => {
  const groupedSkills = new Map<string, SkillListItem[]>();
  skills.forEach((item: SkillListItem) => {
    const initialLetter: string = item.skill.substring(0, 1).toUpperCase();
    const collection: SkillListItem[] = groupedSkills.get(initialLetter) || [];
    collection.push(item);
    groupedSkills.set(initialLetter, collection);
  });
  if (sorting === 'desc') {
    return new Map([...groupedSkills.entries()].sort().reverse());
  }
  return groupedSkills;
};

/**
 * @interface SkillFilter
 * @description This interface represents a filter for skills
 * @param searchValue?: string The search value
 * @param type?: string The search option
 */
export interface SkillFilter extends Filter {
  searchValue?: string;
  type?: 'skill' | 'coreCompetence';
}

/**
 * @function basicSkillFilter
 * @description This function returns a basic skill filter
 * @param config The application configuration
 * @returns SkillFilter The basic skill filter
 */
export const basicSkillFilter = (config: ApplicationConfiguration): SkillFilter => {
  return {
    pageNumber: 0,
    pageSize: config.pageSize ?? 10,
    type: 'skill'
  };
};

/**
 * @function skillFilterBuilder
 * @description This function returns a new skill filter builder
 * @param config The application configuration
 */
export const skillFilterBuilder = (config: ApplicationConfiguration): SkillFilterBuilder => {
  return new SkillFilterBuilder(config);
};

/**
 * @class SkillFilterBuilder
 * @description This class represents a fluent builder for skill filters
 */
export class SkillFilterBuilder {
  private readonly filter: SkillFilter;

  /**
   * @constructor
   * @param config The application configuration
   */
  constructor(@Inject(InjectorKeys.CONFIGURATION) config: ApplicationConfiguration) {
    this.filter = basicSkillFilter(config);
  }

  /**
   * @function withSearchValue
   * @description This function sets the search value
   * @param searchValue
   * @returns this The builder for chaining
   */
  withSearchValue(searchValue: string): this {
    this.filter.searchValue = searchValue;
    return this;
  }

  /**
   * @function withPageNumber
   * @description This function sets the page number for the filter
   * @param pageNumber The page number
   * @returns this The builder for chaining
   */
  withPageNumber(pageNumber: number): this {
    this.filter.pageNumber = pageNumber;
    return this;
  }

  /**
   * @function withPageSize
   * @description This function sets the page size for the filter
   * @param pageSize The page size
   *  @returns this The builder for chaining
   */
  withPageSize(pageSize: number): this {
    this.filter.pageSize = pageSize;
    return this;
  }

  /**
   * @function withPartialFilter
   * @description This function sets a partial filter
   * @param filter The partial filter
   * @returns this The builder for chaining
   */
  withPartialFilter(filter: Partial<SkillFilter>): this {
    Object.assign(this.filter, filter);
    return this;
  }

  /**
   * @function build
   * @description This function builds the skill filter
   * @returns SkillFilter The built skill filter
   */
  build(): SkillFilter {
    return this.filter;
  }
}

/**
 * @function updateSkillFilter
 * @description This function updates a skill filter with new parameters
 * @param filter The original skill filter
 * @param params The new parameters
 * @returns SkillFilter The updated skill filter
 */
export const updateSkillFilter = (filter: SkillFilter, params: Record<string, string>): SkillFilter => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const newParams: any = {
    sorting: Reflect.get(params, 'sorting'),
    searchValue: Reflect.get(params, 'searchValue')
  };
  return {
    pageNumber: filter.pageNumber,
    pageSize: filter.pageSize,
    type: filter.type,
    ...newParams
  };
};

/**
 * @function skillDownloadMapper
 * @description This function maps a skill list item to a record for download
 * @param item The skill list item
 * @returns Record<string, string | number | boolean> The mapped record
 */
export const skillDownloadMapper: DownloadMapperFunction = (
  item: object
): Record<string, string | number | boolean> => {
  const skill = item as SkillListItem;
  return {
    Name: skill.skill,
    Count: skill.count ?? 0
  };
};

/**
 * @function getAllLeafNames
 * @description This function returns all leaf names of a skill tree (excluding the root)
 * @param skill The skill tree
 * @returns string[] The leaf names
 */
export const getAllLeafNames = (skill: Skill): string[] => {
  const leafs: string[] = [];
  if (skill.children) {
    skill.children.forEach((skill: Skill) => {
      leafs.push(...getAllLeafNames(skill));
    });
  } else {
    leafs.push(skill.name);
  }
  return leafs;
};

/**
 * @function filterTreeByLeafNames
 * @description This function filters a skill tree by leaf names
 * @param tree  The skill tree
 * @param leafNames The leaf names
 * @returns Skill[] The filtered skill tree, only those elements are returned that have a leaf with a name from the list
 */
export const filterTreeByLeafNames = (tree: Skill[], leafNames: string[]): Skill[] => {
  return tree.filter((skill: Skill) => {
    if (skill.children) {
      skill.children = filterTreeByLeafNames(skill.children, leafNames);
      return skill.children.length > 0;
    }
    return leafNames.includes(skill.name);
  });
};
