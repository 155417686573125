/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChildren
} from '@angular/core';
import { faChevronDown, faClose, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  FilterMethod,
  FilterOperation,
  GxDateFilterComponent,
  GxDropdownComponent,
  GxNumberFilterComponent,
  MenuItem
} from '@ebcont/galaxy';
import { textAsMenuItem } from '@core/utils/utils';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, from, Observable, Subject, Subscription, tap } from 'rxjs';
import {
  AppliedFilter,
  createCaptionForDateFilter,
  createCaptionForDropdown,
  createCaptionForNumberFilter,
  FilterInstance,
  FILTERS,
  getNewSelectedItems,
  getOperation,
  getStatusFilter
} from '@core/prototypes/filtering';
import { ActivatedRoute, convertToParamMap } from '@angular/router';

@Component({
  selector: 'hoc-filter-block',
  templateUrl: './hoc-filter-block.component.html',
  styleUrls: ['./hoc-filter-block.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HocFilterBlockComponent implements OnInit, OnDestroy, OnChanges {
  public searchIcon = faSearch;
  public closeIcon = faClose;
  public dropdownIcon = faChevronDown;
  public deleteFilterIcon = faTimes;

  /** Whether the user is admin or not. */
  @Input() isAdmin = false;
  /** Whether to only show active items or not. */
  protected activeOnly = true;
  /** The search value for the text search field */
  protected searchValue: MenuItem = textAsMenuItem('');

  protected selectedOperations: Record<string, FilterOperation> = {};

  /** Whether the sorting should be disabled. */
  @Input() disableSorting = false;
  /** Whether the status filter is visible or not. */
  @Input() statusFilterVisible = true;
  /** The usable filter instances. */
  @Input() filters: FilterInstance[] = [];
  /** The function to provide text search capabilities. */
  @Input() textSearch: (text: string) => Observable<MenuItem[]> = () => {
    return EMPTY;
  };
  /** The sorting order. */
  @Input() sorting = 'undefined';
  /** The event emitter to broadcast when any of the filters changed. */
  @Output() changed = new EventEmitter<Record<string, string>>();
  /** The sorting items. */
  @Input() sortingItems: MenuItem[] = [
    { label: 'sorting.asc', id: 'asc', isRouterLink: false },
    { label: 'sorting.desc', id: 'desc', isRouterLink: false }
  ];
  /** The default sorting order. */
  @Input() defaultSorting = 'undefined';
  /** The external sorting if any. */
  @ContentChild('sorting') externalSorting?: TemplateRef<unknown>;
  /** The external filter if any. */
  @ContentChild('filtering') externalFiltering?: TemplateRef<unknown>;

  /** Whether the filter block is embedded into another component, like a drawer within a grouped list. */
  @Input() embedded = false;
  /** External event emitter to make possible programmatic filter changes. */
  @Input() applyFilter?: EventEmitter<AppliedFilter>;

  /** A simple subject to support broadcasting of a change. */
  private broadcastChange = new Subject<void>();
  /** The subscription to the broadcast change subject. */
  private broadcastSubscription: Subscription;
  /** The selectable filters. */
  protected availableFilters: FILTERS[] = [];
  /** The visible filters. */
  protected visibleFilters: FILTERS[] = [];
  /** The current filter values. */
  protected filterValues: Record<string, any> = {};
  /** The status filter. */
  protected statusFilter: FilterInstance;

  /** Initialized flag, to prevent broadcasting changes before everything is set up. */
  private initialized = false;

  /** Relay the caption generators to the template */
  protected createCaption = createCaptionForDropdown;
  protected createCaptionNumber = createCaptionForNumberFilter;
  protected createCaptionDate = createCaptionForDateFilter;

  @ViewChildren('GxDropdown') gxDropdowns?: QueryList<GxDropdownComponent>;

  constructor(private translate: TranslateService, private route: ActivatedRoute, public cd: ChangeDetectorRef) {
    this.broadcastSubscription = this.broadcastChange.subscribe(this.broadcast.bind(this));
    this.statusFilter = getStatusFilter(this.translate);
  }

  /**
   * Sets up the filters by filtering out the active only filter (always) and the admin only filters (when user is not
   * admin).
   */
  protected setupFilters() {
    this.availableFilters = this.filters
      .filter((filter) => {
        return filter.key !== FILTERS.ACTIVE_ONLY;
      })
      .filter((filter) => {
        return this.isAdmin ? true : !filter.adminOnly;
      })
      .map((filter) => {
        return filter.key as FILTERS;
      });
    this.showUsedFilters();
    this.cd.markForCheck();
  }

  /**
   * Makes sure the used filters are visible and set up correctly.
   */
  protected showUsedFilters() {
    this.updateSingleFilter(this.statusFilter, this.activeOnly ? 'true' : 'false');
    this.visibleFilters = this.availableFilters.filter((filter) => {
      return Object.hasOwn(this.filterValues, filter); // those filters which have a value
    }); // those filters which have a value

    setTimeout(() => {
      this.visibleFilters
        .map((filter) => {
          return this.getFilter(filter);
        })
        .forEach((filter) => {
          if (filter) {
            this.updateSingleFilter(filter, this.filterValues[filter.key as FILTERS]);
          }
        });

      setTimeout(() => {
        this.initialized = true;
        this.notifyClient();
      }, 200);
    }, 200);
  }

  /**
   * Updates a single filter with a new value.
   * @param filter The filter
   * @param newValue The new value
   * @private
   */
  private updateSingleFilter(filter: FilterInstance, newValue: string) {
    const found: GxDropdownComponent[] = [];
    this.gxDropdowns?.forEach((dropdown) => {
      if (dropdown.label.endsWith(filter.key)) {
        found.push(dropdown);
      }
    });
    if (found.length > 0) {
      if (filter.filterType === 'items') {
        const newItems = getNewSelectedItems(filter, newValue);
        filter.selectedItems = newItems;
        found[0].setSelectedItems(newItems);
      }
      if (filter.filterType === 'date') {
        const dateFilter = found[0] as any as GxDateFilterComponent;
        dateFilter.selectedOperation = getOperation(filter, newValue);
        // const operation = getOperation(filter, newValue);
        // if (operation) {
        //   this.selectedOperations[filter.key] = operation;
        // }
      }
      if (filter.filterType === 'number') {
        const numberFilter = found[0] as any as GxNumberFilterComponent;
        numberFilter.selectedOperation = getOperation(filter, newValue);
      }
    }
    this.cd.markForCheck();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['filters']) {
      this.filters = changes['filters'].currentValue;
      this.setupFilters();
    }
  }

  public ngOnInit(): void {
    this.setupFilters();

    // when in embedded mode, no need to process the query params
    if (this.embedded) {
      return;
    }

    const queryParams = from(this.route.queryParams).pipe(
      tap((queryParams) => {
        this.sorting = queryParams['sorting'] || this.defaultSorting;
        this.activeOnly = queryParams['activeOnly'] === 'true';
        this.searchValue = textAsMenuItem(queryParams['searchValue'] || '');
        Array.from(convertToParamMap(queryParams).keys)
          .filter((key) => {
            return !['searchValue', 'sorting', 'activeOnly', 'pageSize', 'pageNumber'].includes(key);
          })
          .forEach((key) => {
            this.filterValues[key] = queryParams[key];
          });
      })
    );

    queryParams.subscribe(() => {
      this.setupFilters();
    });

    if (this.applyFilter) {
      this.applyFilter.subscribe((filter) => {
        const filterInstance = this.getFilter(filter.filter);
        if (filterInstance) {
          if (!this.visibleFilters.includes(filter.filter)) {
            this.visibleFilters.push(filter.filter);
          }
          setTimeout(() => {
            this.updateSingleFilter(filterInstance, filter.value);
          }, 100);
        }
      });
    }
  }

  public ngOnDestroy(): void {
    this.broadcastSubscription?.unsubscribe();
  }

  /**
   * Broadcasts the current filter settings.
   */
  protected broadcast() {
    this.changed.emit({
      searchValue: this.searchValue.id,
      activeOnly: this.activeOnly ? 'true' : 'false',
      sorting: this.sorting,
      ...this.filterValues
    });
  }

  /**
   * @param value The new value containing the searched text both in ID and LABEL
   */
  protected textSearched(value: MenuItem) {
    this.searchValue = value;
    this.notifyClient();
  }

  /**
   * @param value The new value for the sorting order
   */
  protected sortingChanged(value: string): void {
    this.sorting = value;
    this.notifyClient();
  }

  /**
   * When a simple dropdown filter is selected, update the filter values and broadcast the change.
   * @param name The name of the filter
   * @param items The items selected
   */
  filterAction(name: string, items: MenuItem[]) {
    if (name === 'activeOnly') {
      this.activeOnly = items[0].id === 'true';
      this.notifyClient();
      return;
    }
    this.filterValues[name] = items
      .map((item) => {
        return item.id;
      })
      .join(',');
    this.notifyClient();
  }

  /**
   * When a filter is selected, update the visible filters and if the filter was removed from visible filters, then
   * delete the corresponding value from the filter values and broadcast the change.
   * @param items The items selected
   */
  toggleFilterVisibility(items: MenuItem[]) {
    if (this.visibleFilters.includes(items[0].id as FILTERS)) {
      this.visibleFilters = this.visibleFilters.filter((filter) => {
        return filter !== items[0].id;
      });
      delete this.filterValues[items[0].id as FILTERS];
      this.notifyClient();
    } else {
      this.visibleFilters.push(items[0].id as FILTERS);
      const filter = this.getFilter(items[0].id as FILTERS);
      if (filter) {
        this.updateSingleFilter(filter, '');
      }
    }
  }

  /**
   * @return The available filters as menu items
   */
  getAllAvailableFilters() {
    return this.availableFilters.map((filter) => {
      return { label: 'filter.names.' + filter, isRouterLink: false, id: filter } as MenuItem;
    });
  }

  /**
   * @param key The filter key
   * @return The filter instance for the given key
   */
  protected getFilter(key: FILTERS): FilterInstance | undefined {
    return this.filters.find((filter) => {
      return filter.key === key;
    });
  }

  protected readonly textAsMenuItem = textAsMenuItem;

  /**
   * When a filter operation is selected, update the filter values and broadcast the change.
   * @param name
   * @param operation
   */
  filterOperationAction(name: string, operation: FilterOperation) {
    this.selectedOperations[name] = operation; // this must run to allow correct working
    const filter = this.getFilter(name as FILTERS);
    if (!filter || !filter.operationNeeded) {
      // when the operation is not needed
      return;
    }

    let value = '';
    if (filter.filterType === 'date') {
      value = operation.isMulti
        ? `${(operation.firstOperand as Date).toISOString().substring(0, 10)}:${(operation.secondOperand as Date)
            .toISOString()
            .substring(0, 10)}`
        : (operation.firstOperand as Date).toISOString().substring(0, 10);
    }
    if (filter.filterType === 'number') {
      value = operation.isMulti ? `${operation.firstOperand}:${operation.secondOperand}` : `${operation.firstOperand}`;
    }

    this.filterValues[name as FILTERS] = `${operation.id}:${value}`;
    this.notifyClient();
  }

  numberFilterChanged(name: FILTERS, method: FilterMethod<number>) {
    const filter = this.getFilter(name as FILTERS);
    if (!filter || !filter.methodNeeded) {
      // when the method is not needed
      return;
    }
    this.filterValues[name as FILTERS] = method;
    this.notifyClient();
  }

  dateFilterChanged(name: FILTERS, method: FilterMethod<Date>) {
    const filter = this.getFilter(name as FILTERS);
    if (!filter || !filter.methodNeeded) {
      // when the method is not needed
      return;
    }
    this.filterValues[name as FILTERS] = method;
    this.notifyClient();
  }

  private notifyClient() {
    if (this.initialized) {
      this.broadcastChange.next();
    }
  }
}
