<!-- App Navigation -->
<!-- FIXME: gx-navigation-item is part of tab index, so users need 2 tabs to focus on link -->
<nav
  [class.compact]="isNavigationCompact"
  [@sidebarTrigger]="isNavigationCompact ? 'open' : 'close'">
  <gx-navigation-item *ngFor="let item of navigationItems$ | async"
                      text="{{item.text | translate}}"
                      [icon]="item.icon"
                      [image]="item.image"
                      [class]="item.class"
                      tabindex="-1"
                      [isNavigationCompact]="isNavigationCompact"
                      routerLink="{{item.routerLink}}"></gx-navigation-item>
</nav>

<div class="collapseButton">
  <gx-button
    text="Collapse"
    hasIcon="only"
    icon="chevron-left"
    (click)="toggleCompact()"
  />
</div>
