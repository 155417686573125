import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { SkillListItem } from '@core/models/skill';
import { InjectorKeys } from '@core/utils/injector.keys';
import { BackendService } from '@core/prototypes/backend.service';
import { UserService } from '@core/services/user/user.service';
import { Customer } from '@core/models/customer';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.css']
})
export class CustomerDetailsComponent implements OnChanges {
  @Input() isAdmin = false;
  @Input() customer: Customer | undefined;
  @Output() closeClicked: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    @Inject(InjectorKeys.SKILL) private service: BackendService<SkillListItem>,
    private userService: UserService,
    public cd: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['customer'] && changes['customer'].currentValue) {
      this.customer = changes['customer'].currentValue;
      this.cd.markForCheck();
      this.cd.detectChanges();
    }
  }

  onCloseButtonClicked() {
    this.closeClicked.emit();
  }

  protected readonly parseInt = parseInt;
}
