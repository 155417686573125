import { ChangeDetectorRef, Inject, Pipe, PipeTransform } from '@angular/core';
import { InjectorKeys } from '../utils/injector.keys';
import { EmployeeDetailsService } from '../services/employee/employee.details.service';
import { Observable, of } from 'rxjs';
import { PLACEHOLDER_IMAGE } from '../utils/utils';
import { map } from 'rxjs/operators';

/**
 * Pipe to download the profile images from the backend, and cache them. The caching just applies to the last file (so
 * consecutive calls with the same value will not trigger a new download).
 */
@Pipe({
  name: 'profileImage',
  pure: false
})
export class ProfileImagePipe implements PipeTransform {
  private lastValue = '';
  private profileImage = '';

  constructor(
    @Inject(InjectorKeys.EMPLOYEE_DETAILS_SERVICE) private service: EmployeeDetailsService,
    private ref: ChangeDetectorRef
  ) {}

  transform(value?: string, isProfilImage = true): Observable<string> {
    if (!value || value.trim().length < 1) {
      return of(PLACEHOLDER_IMAGE);
    }

    if (value === this.lastValue) {
      return of(this.profileImage);
    }

    const image = isProfilImage ? this.service.getProfileImage(value) : this.service.getProfilePicture(value);

    return image.pipe(
      map((image) => {
        this.lastValue = value;
        this.profileImage = image;
        this.ref.markForCheck();
        return this.profileImage;
      })
    );
  }
}
