import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { SkillListItem, SkillStats } from '@core/models/skill';
import { InjectorKeys } from '@core/utils/injector.keys';
import { BackendService } from '@core/prototypes/backend.service';
import { UserService } from '@core/services/user/user.service';
import { SkillsService } from '@core/services/employee/skills.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-skill-details',
  templateUrl: './skill-details.component.html',
  styleUrls: ['./skill-details.component.css']
})
export class SkillDetailsComponent implements OnInit, OnChanges {
  @Input() isAdmin = false;
  @Input() skill: SkillListItem | undefined;
  @Output() closeClicked: EventEmitter<void> = new EventEmitter<void>();

  public skillStats: SkillStats = {};
  public selectedStars?: number;
  public sorting = 'asc';

  constructor(
    @Inject(InjectorKeys.SKILL) private service: BackendService<SkillListItem>,
    private userService: UserService,
    public cd: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['skill'] && changes['skill'].currentValue) {
      this.reloadList();
    }
  }

  ngOnInit(): void {
    this.reloadList();
  }

  // skill: (Breitband-) Delphi-Methode
  // corecompetence: (Web-) Server-Wartung
  public reloadList(): void {
    if (this.skill && this.skill.type === 'skill') {
      // there is no count on core competences
      (this.service as SkillsService).getSkillCounts(this.skill.skill).subscribe((result) => {
        this.skillStats = result;
        this.selectedStars = parseInt(
          Object.entries(result).reduce(([pStar, pNum], [cStar, cNum]) => {
            if (pStar > cStar && pNum > 0) {
              return [pStar, pNum];
            }
            return [cStar, cNum];
          })[0]
        );
        this.cd.detectChanges();
      });
    }
  }

  onCloseButtonClicked() {
    this.closeClicked.emit();
  }

  public asKeyValuePairs(stats: SkillStats): { key: string; value: number }[] {
    const pairs = Object.entries(stats).map(([key, value]) => {
      return { key, value };
    });
    pairs.sort((a, b) => {
      return b.key.localeCompare(a.key);
    });
    return pairs;
  }

  protected readonly JSON = JSON;

  getTotalEmployees() {
    return this.asKeyValuePairs(this.skillStats)
      .map((pair) => {
        return pair.value;
      })
      .reduce((a, b) => {
        return a + b;
      }, 0);
  }

  starSelected(key: string) {
    this.selectedStars = parseInt(key);
    this.cd.detectChanges();
  }

  protected readonly parseInt = parseInt;

  getStarContainerClass(key: string) {
    return '--star-container ' + (parseInt(key) === this.selectedStars ? '--selected' : '');
  }

  sortingChanged(sorting: string) {
    this.sorting = sorting;
  }
}
