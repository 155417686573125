<div class="flex flex-col">
  <div class="flex w-full">
    <ng-template *ngIf="headerBlock" [ngTemplateOutlet]="headerBlock || defaultHeaderBlock"></ng-template>
  </div>
  <div>
    <div id="content" (scrollend)="scrollEnd()">
      <ng-template *ngIf="loading" [ngTemplateOutlet]="loadingBlock || defaultLoading"></ng-template>
      <ng-template [ngTemplateOutlet]="itemList" *ngIf="items.size >0"></ng-template>
      <div *ngIf="emptyItemsBlock">
        <ng-template [ngTemplateOutlet]="emptyItemsBlock" *ngIf="items.size <= 0 && !loading"></ng-template>
      </div>
      <div #footerContainer>
        <ng-template *ngIf="footerBlock" [ngTemplateOutlet]="footerBlock || defaultFooterBlock"></ng-template>
      </div>
    </div>
  </div>
</div>

<ng-template #defaultLoading>
  <gx-loader></gx-loader>
</ng-template>

<ng-template #itemList>
  <nav aria-label="Directory">
    <div class="list-group">
      <div *ngFor="let letter of groupLetters">
        <div class="list-title" *ngIf="!loading" [id]="letter">
          <ng-container [ngTemplateOutlet]="groupBlock || defaultGroupBlock"
                        [ngTemplateOutletContext]="{$implicit: letter, letter}"/>
        </div>
        <ul role="list" class="list">
          <ng-container *ngIf="!loading">
            <ng-container *ngFor="let item of getItemsForLetter(letter)"
                          [ngTemplateOutlet]="itemBlock || defaultItemBlock"
                          [ngTemplateOutletContext]="{ $implicit: item, item}"/>
          </ng-container>


        </ul>
      </div>
    </div>
  </nav>
</ng-template>

<ng-template #defaultItemBlock let-item>
  <pre title="default item block!">
    {{ item | json }}
  </pre>
</ng-template>

<ng-template #defaultGroupBlock let-letter>
  <pre title="default group block!">
    {{ letter }}
  </pre>
</ng-template>

<ng-template #defaultHeaderBlock>
</ng-template>

<ng-template #defaultFooterBlock>
</ng-template>
