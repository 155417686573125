import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Description, GxDropdownComponent, MenuItem } from '@ebcont/galaxy';
import { EmployeeDetails } from '@core/models/employee';
import { CoreCompetence } from '@core/models/core.competence';
import { compareBy, getNumberFromCompositeString } from '@core/utils/utils';

@Component({
  selector: 'app-employee-profile',
  templateUrl: './employee-profile.component.html',
  styleUrls: ['./employee-profile.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeProfileComponent {
  @Input() employee: EmployeeDetails | undefined;
  public downIcon = faChevronDown;
  public competenceSortItems: MenuItem[] = [
    { label: 'sorting.level', isRouterLink: false, id: 'level' },
    { label: 'sorting.years', isRouterLink: false, id: 'years' }
  ];
  public currentCompetenceSort: MenuItem[] = [this.competenceSortItems[0]];
  public currentSkillSort: MenuItem[] = [this.competenceSortItems[0]];
  @Output() companySelected: EventEmitter<string> = new EventEmitter<string>();
  @Output() locationSelected: EventEmitter<string> = new EventEmitter<string>();
  @Output() boardSelected: EventEmitter<string> = new EventEmitter<string>();

  public selectFiltersForClickedItem(clickedItem: Description): void {
    if (clickedItem.term === 'team.company') {
      this.companySelected.emit(clickedItem.description);
    }
    if (clickedItem.term === 'team.location') {
      this.locationSelected.emit(clickedItem.description);
    }
  }

  public getSortedCompetences(): CoreCompetence[] {
    const result = [...(this.employee?.coreCompetences ?? [])];
    if (this.currentCompetenceSort.length < 1) {
      return result;
    }
    if (this.currentCompetenceSort[0].id === 'level') {
      result.sort((a, b) => {
        return compareBy(
          b.levelInt,
          a.levelInt,
          getNumberFromCompositeString(b.duration),
          getNumberFromCompositeString(a.duration)
        );
      });
    }
    if (this.currentCompetenceSort[0].id === 'years') {
      result.sort((a, b) => {
        return compareBy(
          getNumberFromCompositeString(b.duration),
          getNumberFromCompositeString(a.duration),
          b.levelInt,
          a.levelInt
        );
      });
    }
    return result;
  }

  public competenceSortingChanged(menuItems: MenuItem[]): void {
    this.currentCompetenceSort = menuItems;
  }

  public captionForSorting = (self: GxDropdownComponent): string => {
    return (
      self.translate.instant(self.label || 'empty_string') +
      ' ' +
      self.translate.instant(`sorting.${self.selectedItems[0]?.id}`)
    );
  };

  public getEducation(): string[] {
    return this.employee?.educations || [];
  }

  public boardClicked(board: string): void {
    this.boardSelected.emit(board);
  }
}
