import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { FacetGroup } from '../../models/facet';
import { FacetsService } from './facet.service.interface';
import { ExceptionHandlerService } from '../exceptionhandler/exception.handler.service';
import 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FacetsServiceImpl implements FacetsService {
  constructor(private http: HttpClient, private exceptionService: ExceptionHandlerService) {}

  public getFacetGroup(): Observable<FacetGroup> {
    return this.http
      .get<FacetGroup>('/api/employees/facets')
      .pipe(catchError(this.exceptionService.handleError<FacetGroup>()));
  }
}
