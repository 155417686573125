<div class="flex h-full max-h-full divide-x overflow-hidden" data-testId="customer-list-container">
  <div class="flex flex-1 flex-col overflow-auto">
    <div class="flex w-full flex-col border-b border-ui-border">
      <div class="flex items-center justify-between">
        <gx-section-title
          [title]="'customer.size' | translate : { customer: lastResult.totalNumberResultsets || 0 }"
          class="px-sx"/>

        <gx-button
          *ngIf="isAdmin"
          [text]="'downloadTheList' | translate"
          [title]="'downloadTheList' | translate"
          [icon]="csvExportIcon"
          textClass="hidden md:inline"
          class="mr-4"
          [isDisabled]="listIsDownloading"
          (click)="downloadTheList()"></gx-button>
      </div>

        <hoc-filter-block
                (changed)="filterBlockChanged($event)"
                [filters]="filtersForBlock"
                [isAdmin]="isAdmin"
                [textSearch]="searchForName"
                defaultSorting="asc"
        />
      <hoc-grouped-list
        [loading]="loading"
        [items]="items"
        [groupLetters]="groupLetters"
        [loadingBlock]="loadingBlock"
        [itemBlock]="itemBlock"
        [groupBlock]="groupBlock"
        [footerBlock]="footerBlock"
        [emptyItemsBlock]="emptyItemsBlock"
        (scrolledToEnd)="scrolledToEnd()">
      </hoc-grouped-list>
    </div>
  </div>
  <div
    @slideInFromRight
    class="absolute inset-0 z-10 flex flex-1 flex-col overflow-auto bg-ui-surface-rise md:static"
    *ngIf="isDrawerOpen  && items.size > 0">
    <app-customer-details [customer]="selectedCustomer" [isAdmin]="isAdmin"
                          (closeClicked)="closeDrawer()"></app-customer-details>
  </div>
</div>

<ng-template #emptyItemsBlock>
  <app-empty-list-page (backButtonClicked)="handleEmptyList()"></app-empty-list-page>
</ng-template>

<ng-template #groupBlock let-letter>
  <h3 class="list-title-text">{{ letter }}</h3>
</ng-template>

<ng-template #loadingBlock>
  <ng-container *ngTemplateOutlet="loadingTitle"></ng-container>
  <ng-container *ngFor="let item of loadingSkeletonSize">
    <app-customer-list-placeholder/>
  </ng-container>
</ng-template>

<ng-template #itemBlock let-item>
  <app-customer-list-item [customer]="item" [isAdmin]="isAdmin" [isSmallScreen]="false"
                          (click)="clickedOnCustomer(item)"/>
</ng-template>

<ng-template #loadingTitle>
  <div class="list-title">
    <h3 class="list-title-text my-1 ms-4"></h3>
  </div>
</ng-template>

<ng-template #footerBlock>
  <div #footerContainer class="px-3 py-4">
    <gx-pagination
      *ngIf="loadMode === 'paged' && items.size > 0"
      [data]="pagination"
      (newPage)="gotoPage($event)"
      [availablePageSizes]="config.pageSizes"
      [lengthBeginSegment]="2"
      [lengthEndSegment]="2"
      [currentPageSize]="filter.pageSize"
      (newPageSize)="newPageSize($event)"/>
    <app-customer-list-placeholder
      *ngIf="loadMode === 'infinite' && canLoadMore"
    />
  </div>
</ng-template>
