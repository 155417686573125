/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getElementByXpath, getElementsByXpath, getItemsWithAttributes, getSingleString } from '@core/utils/utils';

@Component({
  selector: 'app-factsheet-project-sticker',
  templateUrl: './factsheet-project-sticker.component.html',
  styleUrls: ['./factsheet-project-sticker.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FactsheetProjectStickerComponent implements OnChanges {
  @Input() factSheet: XMLDocument | undefined;
  public technologies: string[][] = [];
  public architectureImages: string[][] = [];

  constructor(private translate: TranslateService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['factSheet'] && changes['factSheet'].currentValue) {
      this.factSheet = changes['factSheet'].currentValue;
      this.regenerateData();
    }
  }

  public getSingleString(xpath: string) {
    return getSingleString(getElementByXpath(this.factSheet!, xpath));
  }

  protected regenerateData(): void {
    this.technologies = getItemsWithAttributes(getElementsByXpath(this.factSheet!, '//technologies/technology'), [
      'type'
    ]);
    this.architectureImages = getItemsWithAttributes(getElementsByXpath(this.factSheet!, '//architecture-image'), [
      'confluenceImageLink'
    ]);
  }
}
