<div class="flex h-full max-h-full divide-x overflow-hidden" data-testId="team-list-container">
  <div class="flex flex-1 flex-col overflow-auto">
    <div class="flex w-full flex-col border-b border-ui-border">
      <div class="flex items-center justify-between">
        <gx-section-title
          [title]="(lastResult.totalNumberResultsets > 1 ? 'team.size.plural' : 'team.size.singular') | translate  : { persons: lastResult.totalNumberResultsets || 0} "
          class="px-sx"/>

        <gx-button
          *ngIf="isAdmin"
          [text]="'downloadTheList' | translate"
          [title]="'downloadTheList' | translate"
          [icon]="csvExportIcon"
          textClass="hidden md:inline"
          class="mr-4"
          [isDisabled]="listIsDownloading"
          (click)="downloadTheList()"></gx-button>
      </div>

      <p id="nation" class="px-sx">{{ 'team.nation' | translate : {nations: lastResult.totalCountries || 0} }}</p>

      <hoc-filter-block [textSearch]="searchForName" [applyFilter]="applyNewFilter"
                        (changed)="filterBlockChanged($event)"
                        defaultSorting="asc"
                        [filters]="filtersForBlock"></hoc-filter-block>
    </div>

    <hoc-grouped-list
      [loading]="loading"
      [groupLetters]="groupLetters"
      [items]="items"
      [loadingBlock]="loadingBlock"
      [footerBlock]="footerBlock"
      [groupBlock]="groupBlock"
      [itemBlock]="itemBlock"
      [emptyItemsBlock]="emptyItemsBlock"
      (scrolledToEnd)="scrolledToEnd()">
    </hoc-grouped-list>
  </div>
  <div
    @slideInFromRight
    class="absolute inset-0 z-10 flex flex-1 flex-col overflow-auto bg-ui-surface-rise md:static"
    *ngIf="employeeEmail">
    <app-employee-details (closeClicked)="closeEmployeeDetails()"
                          (companySelected)="companySelected($event)"
                          (locationSelected)="locationSelected($event)"
                          (boardSelected)="boardSelected($event)"
                          [employeeId]="employeeEmail"></app-employee-details>
  </div>
</div>

<ng-template #headerBlock>
</ng-template>

<ng-template #groupBlock let-letter>
  <h3 class="list-title-text">{{ letter }}</h3>
</ng-template>

<ng-template #loadingBlock>
  <ng-container *ngTemplateOutlet="loadingTitle"></ng-container>
  <ng-container *ngFor="let item of loadingSkeletonSize">
    <app-team-list-placeholder [isExtended]="true"/>
  </ng-container>
</ng-template>

<ng-template #loadingTitle>
  <div class="list-title">
    <h3 class="list-title-text my-1 ms-4"></h3>
  </div>
</ng-template>

<ng-template #itemBlock let-item>
  <app-team-list-item [employee]="item" [isExtended]="isExtended" (employeeSelected)="employeeSelected($event)"/>
</ng-template>

<ng-template #emptyItemsBlock>
  <app-empty-list-page (backButtonClicked)="handleEmptyList()"></app-empty-list-page>
</ng-template>

<ng-template #footerBlock>
  <div #footerContainer class="px-3 py-4">
    <!-- TODO: Add a <gx-button>Load more</gx-button> also with infinite scroll. in case something is not working user has control!-->
    <gx-pagination
      *ngIf="loadMode === 'paged' && items.size > 0"
      [data]="pagination"
      (newPage)="gotoPage($event)"
      [availablePageSizes]="config.pageSizes"
      [currentPageSize]="filter.pageSize"
      [lengthBeginSegment]="2"
      [lengthEndSegment]="2"
      (newPageSize)="newPageSize($event)"/>
    <app-team-list-placeholder
      *ngIf="loadMode === 'infinite' && canLoadMore"
      [isExtended]="true"
      (click)="scrolledToEnd()"/>
  </div>
</ng-template>
