import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { AbstractBackendService } from '@core/prototypes/backend.service';
import { ExceptionHandlerService } from '../exceptionhandler/exception.handler.service';
import { Status } from '../../models/status.model';
import { ignore } from '@ebcont/galaxy';

@Injectable({
  providedIn: 'root'
})
export class StatusService extends AbstractBackendService<Status> {
  constructor(http: HttpClient, private exceptionService: ExceptionHandlerService) {
    super(`/api/health-check/`, http);
  }

  /**
   * @inheritDoc
   */
  override get(id: string): Observable<Status> {
    ignore(id);
    return this.http
      .get<Status>(this.endPointUrl + 'status')
      .pipe(catchError(this.exceptionService.handleError<Status>()));
  }
}
