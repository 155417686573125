<div class="flex flex-row items-center lg:w-[250px]">
  <div *ngIf="['starting', 'progress'].includes(status)" class="w-6">
    <gx-loader/>
  </div>
  <div *ngIf="!['starting', 'progress'].includes(status)" class="w-6">
    &nbsp;
  </div>
  <div *ngIf="status === 'starting'" class="hidden lg:flex">{{ 'download_starting' | translate }}</div>
  <div *ngIf="status === 'progress'" class="hidden lg:flex">{{ 'download_progress' | translate:{progress} }}</div>
  <div *ngIf="status === 'completed'" class="hidden lg:flex">{{ 'download_complete' | translate }}</div>
</div>

