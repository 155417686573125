import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { InjectorKeys } from '@core/utils/injector.keys';
import { BackendService } from '@core/prototypes/backend.service';
import { AbstractGroupedList } from '@hoc/hoc-grouped-list';
import { Employee, EmployeeFilter, employeeGroupingOperator } from '@core/models/employee';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationConfiguration } from '@core/services/configuration/application.configuration.interface';
import { Customer } from '@core/models/customer';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-customer-employee-list',
  templateUrl: './customer-employee-list.component.html',
  styleUrls: ['./customer-employee-list.component.css']
})
export class CustomerEmployeeListComponent extends AbstractGroupedList<Employee> implements OnChanges {
  @Input() isAdmin = false;
  @Input() customer?: Customer | undefined;

  constructor(
    @Inject(InjectorKeys.EMPLOYEES_SERVICE) private service: BackendService<Employee>,
    cd: ChangeDetectorRef,
    router: Router,
    route: ActivatedRoute,
    @Inject(InjectorKeys.CONFIGURATION) public config: ApplicationConfiguration
  ) {
    super(service, employeeGroupingOperator, config, cd, router, route);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // throw new Error('Method not implemented.');
    if (changes['customer'] && changes['customer'].currentValue) {
      this.customer = changes['customer'].currentValue;
      this.filter.activeOnly = 'false';
      (this.filter as EmployeeFilter).customerIds = this.customer?.id;
      this.filter.pageNumber = 0;
      this.reload();
    }
  }

  protected readonly JSON = JSON;
  loadingSkeletonSize = Array.from(Array(4).keys());

  getPersonLink(item: Employee) {
    return `team?activeOnly=false&pageNumber=0&pageSize=10&searchValue=${item.businessPartnerFormattedName}
    &sorting=asc&employee=${item.email}&tab=profile`;
  }
}
