import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Description } from '@ebcont/galaxy';

@Component({
  selector: 'app-team-list-placeholder',
  templateUrl: './team-list-placeholder.component.html',
  styleUrls: ['./team-list-placeholder.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamListPlaceholderComponent {
  @Input() isExtended = false;

  items: Description[] = [
    { term: '\u00a0', description: '\u00a0' },
    { term: '\u00a0', description: '\u00a0' },
    { term: '\u00a0', description: '\u00a0' }
  ];
}
