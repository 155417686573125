import { GroupingOperator } from '@core/prototypes/grouping';
import { Filter } from '@core/prototypes/filtering';
import { Inject } from '@angular/core';
import { InjectorKeys } from '@core/utils/injector.keys';
import { ApplicationConfiguration } from '@core/services/configuration/application.configuration.interface';
import { GetValueFunction } from '@core/utils/utils';
import { DownloadMapperFunction } from '@core/prototypes/backend.service';
import { ignore } from '@ebcont/galaxy';

export interface Project {
  id: string;
  name: string;
  projectBuyerParty: string;
  customerName: string;
  isInactive: boolean;
  projectParticipants: unknown[];
  sumHours?: number;
  factsheet?: string;
}

export interface EmployeeListProject {
  id: string;
  name: string;
  projectBuyerPartyUUID: string; // UUID
  customerName: string;
  isInactive: boolean;
  fromDate?: string;
  toDate?: string;
  workedHours?: number;
}

export const projectGroupingOperator: GroupingOperator<Project> = (
  projects: Project[],
  sorting: 'asc' | 'desc'
): Map<string, Project[]> => {
  ignore(sorting);
  const map = new Map<string, Project[]>();
  projects.forEach((item: Project) => {
    const key = item.name.substring(0, 1).toUpperCase();
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  }); // collect all
  if (sorting === 'desc') {
    return new Map([...map.entries()].sort().reverse());
  }
  return map;
};

export interface ProjectFilter extends Filter {
  searchValue?: string;
  customerIds?: string;
  projectTechnology?: string;
  fromDate?: string;
  toDate?: string;
  minHours?: string;
  maxHours?: string;
  inactive?: boolean;
}

export const basicProjectFilter = (config: ApplicationConfiguration): ProjectFilter => {
  return {
    activeOnly: config.activeOnly?.toString() ?? 'false',
    pageNumber: 0,
    pageSize: config.pageSize ?? 10
  };
};

export class ProjectFilterBuilder {
  private readonly filter: ProjectFilter;

  constructor(@Inject(InjectorKeys.CONFIGURATION) config: ApplicationConfiguration) {
    this.filter = basicProjectFilter(config);
  }

  withActiveOnly(activeOnly: boolean): this {
    this.filter.activeOnly = activeOnly.toString();
    return this;
  }

  withSearchValue(searchValue: string): this {
    this.filter.searchValue = searchValue;
    return this;
  }

  withPageNumber(pageNumber: number): this {
    this.filter.pageNumber = pageNumber;
    return this;
  }

  withPageSize(pageSize: number): this {
    this.filter.pageSize = pageSize;
    return this;
  }

  withPartialFilter(filter: Partial<ProjectFilter>): this {
    Object.assign(this.filter, filter);
    return this;
  }

  build(): ProjectFilter {
    return this.filter;
  }
}

export const projectFilterBuilder = (config: ApplicationConfiguration): ProjectFilterBuilder => {
  return new ProjectFilterBuilder(config);
};

export const updateProjectFilter = (filter: ProjectFilter, params: Record<string, string>): ProjectFilter => {
  return {
    activeOnly: filter.activeOnly,
    pageNumber: filter.pageNumber,
    pageSize: filter.pageSize,
    ...params
  };
};

export const getFromDateYearFunction: GetValueFunction<EmployeeListProject, number> = (
  project: EmployeeListProject
): number => {
  const thisYear = new Date().getFullYear().toString();
  return parseInt(project.fromDate?.split('-')[0] ?? thisYear, 10);
};

export const getToDateYearFunction: GetValueFunction<EmployeeListProject, number> = (
  project: EmployeeListProject
): number => {
  const thisYear = new Date().getFullYear().toString();
  return parseInt(project.toDate?.split('-')[0] ?? thisYear, 10);
};

export const projectDownloadMapper: DownloadMapperFunction = (
  item: object
): Record<string, string | number | boolean> => {
  const project = item as Project;
  return {
    Name: project.name,
    Inactive: project.isInactive ? 'Yes' : 'No',
    Customer: project.customerName,
    Participants: project.projectParticipants.length,
    TotalHours: project.sumHours ?? 'n/a'
  };
};
