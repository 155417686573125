import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication
} from '@azure/msal-browser';

const CLIENT_ID = '390cee4a-0e40-4b41-89f8-57c37aec91df'
const TENANT_ID = '125f4e76-2879-4870-8090-afdafdc11e92'
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      // 'Application (client) ID' of app registration in the Microsoft Entra admin center - this value is a GUID
      clientId: CLIENT_ID,
      // Full directory URL, in the form of https://login.microsoftonline.com/<tenant>
      authority: `https://login.microsoftonline.com/${TENANT_ID}`,
      // Must be the same redirectUri as what was provided in your app registration.
      redirectUri: window.location.origin
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE
    }
  });
}

// MSAL Interceptor is required to request access tokens in order to access the protected resource (Graph)
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  //add auth (Bearer token to each /api/** request)
  protectedResourceMap.set(window.location.origin + '/api', [`api://${CLIENT_ID}/User.Read`]);
  return {
    interactionType: InteractionType.Redirect, protectedResourceMap
  };
}


// MSAL Guard is required to protect routes and require authentication before accessing protected routes
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    loginFailedRoute: '/access-denied',
    authRequest: {
      scopes: ['user.read']
    }
  };
}
