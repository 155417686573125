<div class="group/item item">
  <div class="__image"></div>
  <div class="__infos">
    <div class="name">&nbsp;</div>
    <div class="name">&nbsp;</div>
  </div>
  <div class="__extended" *ngIf="isExtended">
    <gx-description-list
      [isInline]="true"
      [isPlaceholder]="true"
      [items]="[
        { term: '\u00a0', description: '\u00a0' },
      ]" />
    </div>
  </div>


