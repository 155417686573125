import { Injectable } from '@angular/core';
import { AbstractBackendService } from '@core/prototypes/backend.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ExceptionHandlerService } from '@core/services/exceptionhandler/exception.handler.service';
import { catchError, firstValueFrom, from, Observable, tap } from 'rxjs';
import { SkillFilter, SkillListItem, SkillStats } from '@core/models/skill';
import { ListResult } from '@core/prototypes/list.result';
import { httpParamsFrom } from '@core/utils/custom.http.param.encoder';
import { emitSimpleEvent, SIMPLE_EVENTS } from '@core/utils/utils';
import { map } from 'rxjs/operators';

const TWO_HOURS = 1000 * 60 * 60 * 2;

@Injectable({
  providedIn: 'root'
})
export class SkillsService extends AbstractBackendService<SkillListItem> {
  constructor(http: HttpClient, private exceptionService: ExceptionHandlerService) {
    super(`/api/employees/`, http);
  }

  /**
   * @inheritDoc
   */
  //eslint-disable-next-line
  override list(filter: SkillFilter, emitEvent?: boolean): Observable<ListResult<SkillListItem>> {
    const myFilter = { ...filter, sorting: undefined, sortDirection: Reflect.get(filter, 'sorting') };
    const httpParams = httpParamsFrom(myFilter);

    return filter.type === 'skill'
      ? this.listSkills(myFilter, emitEvent, httpParams)
      : from(this.listCoreCompetences(myFilter, emitEvent));
  }

  private listSkills(
    filter: SkillFilter,
    emitEvent: undefined | boolean,
    params: HttpParams
  ): Observable<ListResult<SkillListItem>> {
    return this.http.get<ListResult<SkillListItem>>(this.endPointUrl + 'search/skills/stats', { params }).pipe(
      tap((result) => {
        result.results.forEach((skill) => {
          skill.type = 'skill';
        });
        if (emitEvent) {
          emitSimpleEvent(SIMPLE_EVENTS.DOWNLOAD_PROGRESS, { filter, result });
        }
      }),
      catchError(this.exceptionService.handleError<ListResult<SkillListItem>>())
    );
  }

  /**
   * This is a bit special, because the core competences are not stored in the same way as the skills. It can be
   * queried just as a whole list and must be transformed into the same format as the skills. It will be also stored
   * into the cache.
   *
   * @param filter
   * @param emitEvent
   * @private
   */
  private listCoreCompetences(filter: SkillFilter, emitEvent: undefined | boolean): Promise<ListResult<SkillListItem>> {
    return new Promise((resolve) => {
      this.getFromCache<ListResult<SkillListItem>>('coreCompetences')
        .then((result) => {
          if (result) {
            return result;
          }
          return firstValueFrom(this.readCoreCompetences(filter, emitEvent));
        })
        .then((result) => {
          const items = Reflect.get(filter, 'searchValue')
            ? result.results.filter((item) => {
                return item.skill
                  .toLocaleLowerCase()
                  .includes((Reflect.get(filter, 'searchValue') as string).toLocaleLowerCase());
              })
            : [...result.results];
          if (Reflect.get(filter, 'sortDirection') === 'desc') {
            items.reverse();
          }
          const startItem = filter.pageNumber * filter.pageSize;
          const endItem = startItem + filter.pageSize;
          const partial = items.slice(startItem, endItem);
          resolve({
            totalNumberResultsets: items.length,
            currentStartIndex: startItem,
            currentEndIndex: endItem,
            results: partial
          } as ListResult<SkillListItem>);
        });
    });
  }

  private readCoreCompetences = (
    filter: SkillFilter,
    emitEvent: undefined | boolean
  ): Observable<ListResult<SkillListItem>> => {
    // always read all core competences, because it will be cached
    return this.autocompleteSkills('', 'coreCompetence').pipe(
      tap((result) => {
        if (emitEvent) {
          emitSimpleEvent(SIMPLE_EVENTS.DOWNLOAD_PROGRESS, { filter, result });
        }
      }),
      map((result) => {
        const listResult = {
          currentEndIndex: result.length,
          currentStartIndex: 0,
          results: result.map((skill) => {
            return { skill, type: 'coreCompetence', count: 0 } as SkillListItem;
          }),
          totalNumberResultsets: result.length
        } as ListResult<SkillListItem>;
        this.pushToCache('coreCompetences', listResult, TWO_HOURS);
        return listResult;
      }),
      catchError(this.exceptionService.handleError<ListResult<SkillListItem>>())
    );
  };

  autocompleteSkills(text: string, type: string): Observable<string[]> {
    return this.http
      .get<string[]>(this.endPointUrl + 'autocomplete/skills?searchValue=' + text + '&type=' + type)
      .pipe(catchError(this.exceptionService.handleError<string[]>()));
  }

  public getSkillCounts(skill: string): Observable<SkillStats> {
    return this.http
      .get<SkillStats>(this.endPointUrl + `search/skills/${encodeURIComponent(skill)}/count?type=skill`)
      .pipe(catchError(this.exceptionService.handleError<SkillStats>()));
  }
}
