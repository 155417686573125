import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  Employee,
  employeeDownloadMapper,
  EmployeeFilterBuilder,
  employeeGroupingOperator,
  updateEmployeeFilter
} from '@core/models/employee';
import { ActivatedRoute, Router } from '@angular/router';
import { FacetGroup } from '@core/models/facet';
import { firstValueFrom, forkJoin, from, Observable, Subscription } from 'rxjs';
import { AbstractGroupedList } from '@hoc/hoc-grouped-list';
import { AppliedFilter, FilterInstance, filterInstance, FILTERS } from '@core/prototypes/filtering';
import { facetsAsMenuItems, SIMPLE_EVENTS, textAsMenuItem, typeaheadsAsMenuItems } from '@core/utils/utils';
import { InjectorKeys } from '@core/utils/injector.keys';
import { ApplicationConfiguration } from '@core/services/configuration/application.configuration.interface';
import { BackendService, TypeaheadItem } from '@core/prototypes/backend.service';
import { FacetsService } from '@core/services/facets/facet.service.interface';
import {
  addParamForRoute,
  GalaxyDrawerBlockingService,
  ignorePromise,
  MenuItem,
  slideInFromRight
} from '@ebcont/galaxy';
import { Customer } from '@core/models/customer';
import { Project } from '@core/models/project';
import { faClose, faDownload, faSearch } from '@fortawesome/free-solid-svg-icons';
import { EmployeeListResult } from '@core/prototypes/list.result';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
import { UserService } from '@core/services/user/user.service';

@Component({
  selector: 'app-team-list',
  templateUrl: './team-list.component.html',
  styleUrls: ['./team-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideInFromRight]
})
export class TeamListComponent extends AbstractGroupedList<Employee> implements OnInit, OnDestroy {
  @Input() isExtended = true;
  @ViewChild('footerContainer') footerBlock: TemplateRef<Element> | undefined;

  protected faSearch = faSearch;
  protected faClose = faClose;

  public employeeEmail?: string;
  public loadingSkeletonSize = new Array(environment.loadingSkeletonSize);

  override lastResult: EmployeeListResult = {} as EmployeeListResult;

  protected filtersForBlock: FilterInstance[] = [];

  @Input() maxLabelsInFilters = 2;

  private drawerCloseSubscription: Subscription | undefined;
  public autocompleteData: MenuItem[] = [];
  public searchValue: MenuItem = { id: '', label: '', isRouterLink: false } as MenuItem;
  public csvExportIcon = faDownload;
  public listIsDownloading = false;
  public isAdmin = false;
  protected initialized = false;
  protected applyNewFilter: EventEmitter<AppliedFilter> = new EventEmitter<AppliedFilter>();

  constructor(
    @Inject(InjectorKeys.EMPLOYEES_SERVICE) private service: BackendService<Employee>,
    cd: ChangeDetectorRef,
    router: Router,
    route: ActivatedRoute,
    @Inject(InjectorKeys.CONFIGURATION) public config: ApplicationConfiguration,
    @Inject(InjectorKeys.FACETS_SERVICE) private facetsService: FacetsService,
    @Inject(InjectorKeys.CUSTOMERS_SERVICE) private customersService: BackendService<Customer>,
    @Inject(InjectorKeys.PROJECTS_SERVICE) private projectsService: BackendService<Project>,
    private userService: UserService,
    private drawerBlockingService: GalaxyDrawerBlockingService,
    private zone: NgZone
  ) {
    super(service, employeeGroupingOperator, config, cd, router, route);
    this.loadMode = 'paged'; // now infinite loading mode is used
    this.lastResult.totalCountries = 0;

    this.userService.getAdminStatus().subscribe((isAdmin) => {
      this.isAdmin = isAdmin;
    });
  }

  ngOnInit(): void {
    this.filter = new EmployeeFilterBuilder(this.config).build();
    const facetsObs = from(firstValueFrom(this.facetsService.getFacetGroup()));
    const customerObs = from(firstValueFrom(this.customersService.typeahead('')));
    const projectsObs = from(this.projectsService.typeahead(''));
    this.route.queryParams.subscribe((queryParams) => {
      this.employeeEmail = queryParams['employee'];
      const pageNumber = parseInt(queryParams['pageNumber'] || 0);
      const pageSize = parseInt(queryParams['pageSize'] || this.config.pageSize);

      this.filter.pageNumber = pageNumber;
      this.filter.pageSize = pageSize;
      this.pagination.currentPage = pageNumber;
      this.pagination.pageSize = pageSize;

      this.cd.detectChanges();
    });

    this.drawerCloseSubscription = this.drawerBlockingService.closeNotification$.subscribe(() => {
      this.closeEmployeeDetails();
    });

    forkJoin({
      facets: facetsObs,
      customers: customerObs,
      projects: projectsObs
    }).subscribe({
      next: ({ facets, customers, projects }) => {
        this.registerFacetFilters(facets);
        this.registerCustomersFilter(customers);
        this.registerProjectsFilter(projects);
      },
      complete: () => {
        this.initialized = true;
        this.cd.markForCheck();
        this.cd.detectChanges();
      }
    });
  }

  ngOnDestroy(): void {
    this.drawerCloseSubscription?.unsubscribe();
  }

  public handleEmptyList(): void {
    this.searchValue = textAsMenuItem('');
    this.applyFilter();
  }

  /**
   * Reloads the current page with the current filter values in the query parameter.
   */
  private applyFilter() {
    const newFilter = { ...this.filter };
    Reflect.deleteProperty(newFilter, 'isAdmin');
    this.filter = newFilter;
    this.sorting = Reflect.get(this.filter, 'sorting');
    this.router.navigate(['team'], { queryParams: newFilter });
    this.reload();
  }

  /**
   * When the user selects an employee, we need to show the employee details in the drawer.
   * @param email The Email of the employee to show.
   * @ignore
   */
  public employeeSelected(email: string) {
    ignorePromise(
      addParamForRoute(this.router, this.route, { employee: email }).then(() => {
        this.employeeEmail = email;
      })
    );
  }

  /**
   * Register all the filters depending on the facets.
   * @param facets The facets returned from the backend.
   * @private
   */
  private registerFacetFilters(facets: FacetGroup) {
    this.filtersForBlock = [
      filterInstance(FILTERS.LOCATIONS, facetsAsMenuItems(facets.locations), {
        switchMode: true,
        maxLabels: this.maxLabelsInFilters,
        isMulti: true
      }),
      filterInstance(FILTERS.COMPANIES, facetsAsMenuItems(facets.companies), {
        switchMode: true,
        isMulti: true,
        maxLabels: this.maxLabelsInFilters
      }),
      filterInstance(FILTERS.AREAS, facetsAsMenuItems(facets.areas), {
        switchMode: true,
        isMulti: true,
        maxLabels: this.maxLabelsInFilters
      }),
      filterInstance(FILTERS.GREMIEN, facetsAsMenuItems(facets.gremien), {
        switchMode: true,
        isMulti: true,
        maxLabels: this.maxLabelsInFilters
      }),
      filterInstance(FILTERS.LANGUAGES, facetsAsMenuItems(facets.languages), {
        switchMode: true,
        isMulti: true,
        maxLabels: this.maxLabelsInFilters
      })
    ];
  }

  closeEmployeeDetails(): void {
    this.employeeEmail = undefined;
    ignorePromise(addParamForRoute(this.router, this.route, { employee: null, tab: null }));
  }

  private registerCustomersFilter(customers: TypeaheadItem[]) {
    this.filtersForBlock.push(
      filterInstance(FILTERS.CUSTOMERS, typeaheadsAsMenuItems(customers), {
        switchMode: true,
        maxLabels: this.maxLabelsInFilters,
        isMulti: true,
        searchable: true
      })
    );
  }

  private registerProjectsFilter(projects: TypeaheadItem[]) {
    this.filtersForBlock.push(
      filterInstance(FILTERS.PROJECTS, typeaheadsAsMenuItems(projects), {
        switchMode: true,
        maxLabels: this.maxLabelsInFilters,
        isMulti: true,
        searchable: true
      })
    );
  }

  public downloadTheList() {
    this.listIsDownloading = true;
    ignorePromise(
      this.backendService.downloadList(this.filter, employeeDownloadMapper, 'employees', this.pagination.totalItems)
    );
  }

  @HostListener(`document:${SIMPLE_EVENTS.DOWNLOAD_COMPLETED}`, ['$event'])
  protected downloadCompleted(): void {
    this.listIsDownloading = false;
  }

  /**
   * Searches for a name to serve the autocomplete dropdown.
   * @param text
   */
  protected searchForName = (text: string): Observable<MenuItem[]> => {
    return this.service.autocomplete(text).pipe(
      map((suggest: string[]) => {
        return suggest.map((item) => {
          return textAsMenuItem(item);
        });
      })
    );
  };

  filterBlockChanged(value: Record<string, string>) {
    if (!this.initialized) {
      return;
    }
    const oldFilter = JSON.stringify(this.filter); // the current filter
    this.filter = updateEmployeeFilter(this.filter, value);
    const newFilter = JSON.stringify(this.filter); // the new filter
    // the update is also triggered after a reload, and when there is no real change, then we should not
    // apply the filter, which would cause a reload of the same data and inducing an infinite loop
    if (oldFilter !== newFilter) {
      this.filter.pageNumber = 0;
      this.applyFilter();
    }
  }

  companySelected(company: string) {
    this.applyNewFilter.emit({ filter: FILTERS.COMPANIES, value: company });
  }

  locationSelected(location: string) {
    this.applyNewFilter.emit({ filter: FILTERS.LOCATIONS, value: location });
  }

  boardSelected(board: string) {
    this.applyNewFilter.emit({ filter: FILTERS.GREMIEN, value: board });
  }
}
