/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { getElementsByXpath, getItemsWithAttributes, getNodesFromSnapshot } from '@core/utils/utils';

interface CustomerContant {
  name: string;
  role: string;
  email: string;
  phone: string;
}

@Component({
  selector: 'app-factsheet-contacts',
  templateUrl: './factsheet-contacts.component.html',
  styleUrls: ['./factsheet-contacts.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FactsheetContactsComponent implements OnChanges {
  @Input() factSheet: XMLDocument | undefined;
  public ebcontEmployees: string[][] = [];
  public customers: CustomerContant[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['factSheet'] && changes['factSheet'].currentValue) {
      this.factSheet = changes['factSheet'].currentValue;
      this.regenerateData();
    }
  }

  protected regenerateData(): void {
    this.ebcontEmployees = getItemsWithAttributes(getElementsByXpath(this.factSheet!, '//contacts/ebcont/contact'), [
      'role'
    ]);
    this.customers = getNodesFromSnapshot(getElementsByXpath(this.factSheet!, '//contacts/customer/contact')).map(
      (customer) => {
        const customerContact: CustomerContant = {
          name: '',
          email: '',
          role: '',
          phone: ''
        };
        customerContact.role = (customer as Element).getAttribute('role') || 'n/a';
        customerContact.name = (customer as Element).querySelector('name')?.textContent || 'n/a';
        customerContact.email = (customer as Element).querySelector('email')?.textContent || 'n/a';
        customerContact.phone = (customer as Element).querySelector('phone')?.textContent || 'n/a';
        return customerContact;
      }
    );
  }
}
