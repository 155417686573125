import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'hoc-profile-image',
  templateUrl: './hoc-profile-image.component.html',
  styleUrls: ['./hoc-profile-image.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HocProfileImageComponent {
  @Input()
  public value = '';
  @Input()
  public profilePicture = false;
}
