import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-empty-list-page',
  templateUrl: './empty-list-page.component.html',
  styleUrls: ['./empty-list-page.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyListPageComponent {
  @Output() backButtonClicked: EventEmitter<void> = new EventEmitter<void>();

  public navigateBack(): void {
    this.backButtonClicked.emit();
  }
}
