/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MenuItem } from '@ebcont/galaxy';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapComponent implements OnChanges {
  @Input() selectedLocationItem: MenuItem = {} as any;

  public locationItems: Record<string, string> = {
    wien:
      '!1m18!1m12!1m3!1d2657.2447778589644!2d16.384704417443842!3d48.2404153!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!' +
      '4f13.1!3m3!1m2!1s0x476d065ae4d4d65d%3A0xaee8c05acef1feb1!2sEBCONT%20Zentrale!5e0!3m2!1sen!2sat' +
      '!4v1683904616251!5m2!1sen!2sat',
    graz:
      '!1m18!1m12!1m3!1d2717.8028955036652!2d15.450087400000001!3d47.0637167!2m3!1f0!2f0!3f0!3m2!1i1024!2i768' +
      '!4f13.1!3m3!1m2!1s0x476e4b403487347b%3A0x6284874de40d1571!2sEBCONT%20Zweigstelle%20Graz!5e0!3m2!1shu!2sat' +
      '!4v1709040415720!5m2!1shu!2sat',
    linz:
      '!1m18!1m12!1m3!1d2654.041389160273!2d14.291139299999998!3d48.3020554!2m3!1f0!2f0!3f0!3m2!1i1024' +
      '!2i768!4f13.1!3m3!1m2!1s0x477397856fe6fa7d%3A0x738632c9d1dcca92!2sEBCONT%20Zweigstelle%20Linz!5e0!3m2' +
      '!1shu!2sat!4v1709040595544!5m2!1shu!2sat',
    hard:
      '!1m18!1m12!1m3!1d2696.061433699059!2d9.686721177673292!3d47.48871657117929!2m3!1f0!2f0!3f0!3m2!1i1024' +
      '!2i768!4f13.1!3m3!1m2!1s0x479b124099abc83d%3A0x9802f0ce36c36c81!2sEBCONT%20proconsult%20-%20Zweigstelle%20Hard' +
      '!5e0!3m2!1shu!2sat!4v1709040667830!5m2!1shu!2sat',
    saarbruecken:
      '!1m18!1m12!1m3!1d2604.912904162322!2d6.992629677729918!3d49.24014047138641!2m3!1f0!2f0!3f0!3m2!1i1024!2i768' +
      '!4f13.1!3m3!1m2!1s0x4795b74904ac642d%3A0x8aad7c5321cebe4b!2sEBCONT%20informatics%20GmbH%20Zweigstelle%20' +
      'Saarbr%C3%BCcken!5e0!3m2!1shu!2sat!4v1709040747975!5m2!1shu!2sat',
    newyork:
      '!1m18!1m12!1m3!1d3022.4012843138908!2d-73.9833898225295!3d40.75319797138751!2m3!1f0!2f0!3f0!3m2!1i1024' +
      '!2i768!4f13.1!3m3!1m2!1s0x89c2590045f716d5%3A0xb8e292de8e7d5a92!2s501%205th%20Ave%20%231607%2C%20New%20' +
      'York%2C%20NY%2010017%2C%20Egyes%C3%BClt%20%C3%81llamok!5e0!3m2!1shu!2sat!4v1709040877147!5m2!1shu!2sat',
    stpoelten:
      '!1m18!1m12!1m3!1d2658.999281275962!2d15.625631277693657!3d48.206629771251144!2m3!1f0!2f0!3f0!3m2!1i1024' +
      '!2i768!4f13.1!3m3!1m2!1s0x477278a91e1b6b09%3A0x375025230ecbdaeb!2sKlostergasse%2031%2C%203100%20' +
      'St.%20P%C3%B6lten!5e0!3m2!1sen!2sat!4v1709109437464!5m2!1sen!2sat',
    frankfurt:
      '!1m18!1m12!1m3!1d2558.8214556595813!2d8.6623048!3d50.108348799999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768' +
      '!4f13.1!3m3!1m2!1s0x47bd094c995a9a95%3A0xd607f6dfbeda798e!2sEBCONT%20informatics%20GmbH%20Zweigstelle%20' +
      'Frankfurt!5e0!3m2!1sen!2sat!4v1709109708934!5m2!1sen!2sat',
    neulengbach:
      '!1m18!1m12!1m3!1d2659.6788446419832!2d15.904068999999998!3d48.193539!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1' +
      '!3m3!1m2!1s0x476d9de0ac17beff%3A0xe0207e4405fe3fe3!2sEBCONT%20works%20GmbH!5e0!3m2!1sen' +
      '!2sat!4v1709109847938!5m2!1sen!2sat'
  };

  public path: SafeResourceUrl = this.createPath(this.locationItems['vienna']);

  protected createPath(param: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(
      `https://www.google.com/maps/embed?pb=${param}&loading=async`
    );
  }

  constructor(private domSanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedLocationItem'] && changes['selectedLocationItem'].currentValue) {
      this.selectedLocationItem = changes['selectedLocationItem'].currentValue;
      this.path = this.createPath(this.locationItems[this.selectedLocationItem.id]);
    }
  }
}
