import { Employee, EmployeeSkillFilter } from '../../models/employee';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, tap } from 'rxjs';
import { AbstractBackendService } from '../../prototypes/backend.service';
import { EmployeeListResult, ListResult } from '../../prototypes/list.result';
import { httpParamsFrom } from '../../utils/custom.http.param.encoder';
import { ExceptionHandlerService } from '../exceptionhandler/exception.handler.service';
import { emitSimpleEvent, SIMPLE_EVENTS } from '@core/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class EmployeesSkillService extends AbstractBackendService<Employee> {
  constructor(http: HttpClient, private exceptionService: ExceptionHandlerService) {
    super(`/api/employees/`, http);
  }

  /**
   * @inheritDoc
   */
  override list(filter: EmployeeSkillFilter, emitEvent?: boolean): Observable<ListResult<Employee>> {
    const skill = filter.skill + '';
    Reflect.deleteProperty(filter, 'skill');
    const params = httpParamsFrom({ ...filter, type: 'skill' });
    return this.http
      .get<EmployeeListResult>(this.endPointUrl + 'search/skills/' + encodeURIComponent(skill), {
        params: params
      })
      .pipe(
        tap((result) => {
          result.results.forEach((employee) => {
            Reflect.deleteProperty(employee, 'debugInfos'); // unnecessary
          });
          if (emitEvent) {
            emitSimpleEvent(SIMPLE_EVENTS.DOWNLOAD_PROGRESS, { filter, result });
          }
        }),
        catchError(this.exceptionService.handleError<EmployeeListResult>())
      );
  }
}
