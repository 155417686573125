<div class="flex w-full flex-col p-3">
  <div class="flex cursor-pointer  flex-row" [ngClass]="customer.isInactive ? '__inActive' : ''">
    <div class="__item font-bold">{{ customer.businessPartnerName }}</div>
    <div class="__item">
      <div>
        <span class="pr-1 font-bold">
          {{ customer.numberOfEmployes }}
        </span>
        <span>
        {{ "employee.plural" | translate }}
        </span>
      </div>
    </div>

    <div class="__item">
      <div>
        <span class="pr-1 font-bold">
          {{ customer.numberOfProjects }}
        </span>
        <span>
        {{ "project.plural" | translate }}
        </span>
      </div>
    </div>

    <div class="__item" *ngIf="isAdmin && !isSmallScreen">
      <span
        class="pr-3 font-bold" *ngIf="customer?.sumHours">
      {{ customer.sumHours }}
      </span>
      <span>
        {{ "hours" | translate }}
      </span>
      <span
        class="pr-3" *ngIf="!customer?.sumHours">
        {{ 'n/A' }}
      </span>
    </div>
  </div>
</div>
