<div class="size-full overflow-y-auto @container">
  <gx-page-title [title]="'dashboard.title' | translate"/>
  <div class="px-sx pb-16 text-4xl" data-testid="greeting">{{ 'dashboard.greeting' | translate : {firstName} }}</div>
  <div class="my-6 grid grid-cols-1 gap-4 px-sx @5xl:grid-cols-2 @5xl:gap-8">

    <!-- cards -->
    <div id="cards">

      <div *ngIf="dashBoardCardItems1">
        <ng-container
          *ngTemplateOutlet="title; context: { $implicit: { title: 'dashboard.titles.title_1', subTitle: 'dashboard.subtitles.subtitle_1' }}">
        </ng-container>
      </div>

      <dl class="grid grid-cols-2 gap-x-2">
        <gx-kpi-card *ngFor="let kpiCard of dashBoardCardItems1" class="odd:last:col-span-2" [kpiCard]="kpiCard"/>
      </dl>

      <div *ngIf="dashBoardCardItems2">
        <ng-container *ngTemplateOutlet="title; context: { $implicit: { title: 'dashboard.titles.title_2' }}">
        </ng-container>
      </div>

      <dl class="grid grid-cols-2 gap-2">
        <gx-kpi-card *ngFor="let kpiCard of dashBoardCardItems2" class="odd:last:col-span-2" [kpiCard]="kpiCard"/>
      </dl>
    </div>
    <!-- end cards -->

    <!-- map -->
    <div id="map">
      <ng-container
        *ngTemplateOutlet="title; context: { $implicit: { title: 'dashboard.titles.title_3', map:true }}"></ng-container>
      <app-map *ngIf="selectedLocationItem" [selectedLocationItem]="selectedLocationItem"></app-map>
    </div>
    <!-- end map -->
  </div>

</div>

<ng-template #title let-data>
  <div class="mt-7 flex h-12 items-baseline">
    <h2 class="mr-3 text-2xl font-semibold leading-6">{{ data.title | translate }}</h2>
    <span *ngIf="data.subTitle" class="">{{ data.subTitle | translate }}</span>
    <ng-container *ngIf="data.map">
      <div class="flex h-12 flex-1 items-baseline">
        <gx-dropdown #locationDropdown label="location.singular" [isRightAligned]="true" icon="chevron-down"
                     [multiSelect]="false"
                     [createCaption]="createCaptionForDropdown"
                     [menuItems]="locationItems"
                     [isSelectable]="false"/>
        <a class="ml-auto content-end hover:underline focus-visible:underline focus-visible:outline-none"
           target="_blank" href="https://maps.google.com"><span>Google
            maps</span></a>
      </div>
    </ng-container>
  </div>
</ng-template>
