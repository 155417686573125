import { AbstractBackendService, TypeaheadItem } from '@core/prototypes/backend.service';
import { Customer, CustomerFilter } from '../../models/customer';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ExceptionHandlerService } from '@core/services/exceptionhandler/exception.handler.service';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ListResult } from '@core/prototypes/list.result';
import { httpParamsFrom } from '@core/utils/custom.http.param.encoder';
import { emitSimpleEvent, SIMPLE_EVENTS } from '@core/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class CustomersService extends AbstractBackendService<Customer> {
  constructor(http: HttpClient, private exceptionService: ExceptionHandlerService) {
    super(`/api/customers/`, http);
  }

  // private modifyFilter = (filter: object): object => {
  //   const modifiedFilter = { ...filter };
  //   const sorting = Reflect.get(filter, 'sorting');
  //   if (sorting) {
  //     Reflect.deleteProperty(modifiedFilter, 'sorting');
  //     Reflect.set(modifiedFilter, 'sortDirection', sorting);
  //   }
  //   return modifiedFilter;
  // };

  /**
   * @inheritDoc
   */
  override list(filter: CustomerFilter, emitEvent?: boolean): Observable<ListResult<Customer>> {
    // const modifiedFilter = this.modifyFilter(filter);
    const httpParams: HttpParams = httpParamsFrom(filter);
    return this.http.get<ListResult<Customer>>(`${this.endPointUrl}search`, { params: httpParams }).pipe(
      tap((result) => {
        if (emitEvent) {
          emitSimpleEvent(SIMPLE_EVENTS.DOWNLOAD_PROGRESS, { filter, result });
        }
      }),
      catchError(this.exceptionService.handleError<ListResult<Customer>>())
    );
  }

  override typeahead(text: string): Observable<TypeaheadItem[]> {
    return this.http
      .get<TypeaheadItem[]>(this.endPointUrl + 'typeahead?searchValue=' + text)
      .pipe(catchError(this.exceptionService.handleError<TypeaheadItem[]>()));
  }

  /**
   * @inheritDoc
   */
  // eslint-disable-next-line unused-imports/no-unused-vars,@typescript-eslint/no-unused-vars
  override autocomplete(text: string): Observable<string[]> {
    return this.http
      .get<string[]>(this.endPointUrl + 'autocomplete?searchValue=' + text)
      .pipe(catchError(this.exceptionService.handleError<string[]>()));
  }
}
