<ng-container *ngIf="skill; else placeholder">
  <header>
    <gx-button (click)="onCloseButtonClicked()" hasIcon="only" icon="close" text="Close" variant="neutral"/>
  </header>
  <div class="flex w-full flex-col justify-start gap-4 p-4">
    <div class="text-xl font-bold">{{ skill.skill }}</div>
    <div *ngIf="skill.type === 'skill'" class="flex flex-col gap-4">
      <div>{{ 'skill.employees' | translate: {count: getTotalEmployees()} }}</div>
      <div class="flex flex-wrap gap-4">
        <div *ngFor="let stars of asKeyValuePairs(skillStats)" [class]="getStarContainerClass(stars.key)"
             (click)="starSelected(stars.key)">
          <gx-rating [rating]="parseInt(stars.key)+1" activeRatingClass="--activeRating"
                     inactiveRatingClass="--inactiveRating"></gx-rating>
          <div>({{ stars.value }})</div>
        </div>
      </div>
      <div>
        <app-skill-employee-list [stars]="this.selectedStars" [skill]="this.skill.skill"></app-skill-employee-list>
      </div>
    </div>
    <div *ngIf="skill.type === 'coreCompetence'" class="flex flex-col gap-4">
      <app-core-competence-employee-list [coreCompetence]="this.skill.skill"></app-core-competence-employee-list>
    </div>
  </div>
</ng-container>

<ng-template #placeholder>
  <div>placeholder</div>
</ng-template>
