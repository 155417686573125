<div *ngIf="employee && employee.projects && employee.projects.length > 0">
  <section class="-mx-6">
    <div class="flex flex-wrap items-center gap-3 border-b border-ui-border bg-ui-surface p-4 lg:px-4">
      <hoc-filter-block [textSearch]="searchForName" (changed)="filterBlockChanged($event)" [embedded]="true"
                        [statusFilterVisible]=false [filters]="filtersForBlock">
        <ng-template #sorting>
          <gx-sorting-selector-advanced
            (changedSortingStatus)="handleChangedSortingStatus($event)"
            [menuItems]="projectSortingSelectorItems"
            id="sortingSelector"
            label="Sorting">
          </gx-sorting-selector-advanced>
        </ng-template>
      </hoc-filter-block>
    </div><!--    </div>-->

    <ng-template [ngTemplateOutlet]="activities"/>
  </section>
</div>

<div
  *ngIf="!employee || !employee.projects || employee!.projects!.length <= 0">{{ 'employee.projects.no_projects' | translate }}
</div>

<ng-template #activities>
  <div *ngIf="activeProjects && activeProjects.length > 0">
    <ng-container
      *ngTemplateOutlet="projectList; context: { $implicit: activeProjects, heading: 'running' }"></ng-container>
  </div>

  <div *ngIf="inactiveProjects && inactiveProjects.length > 0">
    <ng-container
      *ngTemplateOutlet="projectList; context: { $implicit: inactiveProjects, heading: 'finished' }"></ng-container>
  </div>
</ng-template>

<ng-template #projectList let-heading="heading" let-filteredProjects>
  <div class="w-full bg-ui-surface py-4 pl-4 text-base font-bold">{{ heading | translate }}
    ({{ filteredProjects.length }})
  </div>
  <ul class="overflow-auto" role="list">
    <li *ngFor="let filteredProject of filteredProjects" class="relative flex border-b-DEFAULT p-4">
      <div class="w-1/2 min-w-[140px] overflow-hidden px-2">
        <div class="font-bold">{{ filteredProject.customerName || 'n/a' }}</div>
        <div>{{ filteredProject.name || 'n/a' }}</div>
      </div>
      <div class="w-2/5 min-w-[140px] px-2">
        <div>{{ 'date_from_to' | translate }}</div>
        <div>
          {{ (filteredProject.fromDate ? (filteredProject.fromDate | date : 'dd.MM.yyyy') : "n/a") + ' - ' + (filteredProject.toDate ? (filteredProject.toDate | date : 'dd.MM.yyyy') : "n/a") }}
        </div>
      </div>
      <div *ngIf="isAdmin" class="w-[10%] min-w-[75px] px-2">
        <div>{{ 'hours' | translate }}</div>
        <div>{{ filteredProject.workedHours || 'n/a' }}</div>
      </div>
    </li>
  </ul>
</ng-template>

