import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { HocNavigationComponent } from './navigation/hoc-navigation.component';
import { NgxTranslateModule } from '@modules/translate/translate.module';
import { MapComponent } from './map/map.component';
import { EbcontGalaxyModule } from '@ebcont/galaxy';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HocGroupedListComponent } from './hoc-grouped-list';
import { CoreModule } from '@core/core.module';
import { HocAccessDeniedComponent } from './hoc-access-denied/hoc-access-denied.component';
import { HocPersonCardComponent } from '@hoc/person-card/hoc-person-card.component';
import { HocUserMenuComponent } from '@hoc/hoc-user-menu/hoc-user-menu.component';
import { HocProfileImageComponent } from './hoc-image/hoc-profile-image.component';
import { HocFilterBlockComponent } from '@hoc/hoc-filter-block/hoc-filter-block.component';
import { HocDownloadStatusComponent } from '@hoc/hoc-download-status/hoc-download-status.component';
import { HocProfileDownloadComponent } from './person-card/hoc-profile-download/hoc-profile-download.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HocProfileDownloadFormComponent } from '@hoc/person-card/hoc-profile-download/hoc-profile-download-form/hoc-profile-download-form.component';

const HOC_MODULES: NgModule['declarations'] & NgModule['exports'] = [
  HocNavigationComponent,
  HocGroupedListComponent,
  MapComponent,
  HocAccessDeniedComponent,
  HocPersonCardComponent,
  HocUserMenuComponent,
  HocProfileImageComponent,
  HocFilterBlockComponent,
  HocDownloadStatusComponent
];

@NgModule({
  declarations: [HOC_MODULES, HocProfileDownloadComponent, HocProfileDownloadFormComponent],
  exports: [HOC_MODULES],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    RouterModule,
    EbcontGalaxyModule,
    NgxTranslateModule,
    CoreModule,
    NgOptimizedImage,
    ReactiveFormsModule
  ],
  providers: []
})
export class HocModule {}
