/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { Project } from '@core/models/project';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { parseStringToXML } from '@core/utils/utils';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.css']
})
export class ProjectDetailsComponent implements OnChanges {
  @Input() isAdmin = false;
  @Input() project: Project | undefined;
  @Output() closeClicked: EventEmitter<void> = new EventEmitter<void>();

  public faLink = faLink;
  protected factSheet: XMLDocument | undefined;

  constructor(public cd: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['project'] && changes['project'].currentValue) {
      this.project = changes['project'].currentValue;
      if (this.project?.factsheet) {
        this.factSheet = parseStringToXML(this.project.factsheet);
      } else {
        this.factSheet = undefined;
      }
      this.cd.detectChanges();
    }
  }

  onCloseButtonClicked() {
    this.closeClicked.emit();
  }

  protected readonly parseInt = parseInt;

  getCustomerLink() {
    return (
      '/customer?searchValue=' +
      encodeURIComponent(this.project!.customerName) +
      '&customerId=' +
      this.project!.projectBuyerParty
    );
  }

  getTeamLink() {
    return '/team?projectIds=' + this.project!.id;
  }
}
