import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { DashboardComponent } from './dashboard/dashboard.component';
import { PageNotFoundComponent } from './page-not-found/pagenotfound.component';
import { HocModule } from '@hoc/hoc.module';
import { NgxTranslateModule } from '../translate/translate.module';
import { EbcontGalaxyModule } from '@ebcont/galaxy';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmptyListPageComponent } from './empty-list-page/empty-list-page.component';
import { RouterLink } from '@angular/router';
import { SettingsComponent } from './settings/settings.component';
import { EmptyDetailsListPageComponent } from './empty-details-list-page/empty-details-list-page.component';

@NgModule({
  declarations: [
    DashboardComponent,
    PageNotFoundComponent,
    EmptyListPageComponent,
    SettingsComponent,
    EmptyDetailsListPageComponent
  ],
  exports: [DashboardComponent, PageNotFoundComponent, EmptyListPageComponent, EmptyDetailsListPageComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    EbcontGalaxyModule,
    HocModule,
    FormsModule,
    NgxTranslateModule,
    ReactiveFormsModule,
    RouterLink
  ],
  providers: []
})
export class GeneralModule {}
