import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ignorePromise } from '@ebcont/galaxy';

@Injectable({
  providedIn: 'root'
})
export class ExceptionHandlerService {
  constructor(private router: Router) {
  }

  handleError<T>() {
    return (error: HttpErrorResponse): Observable<T> => {
      if (error.status === 403) {
        ignorePromise(
          this.router.navigate(['/access-denied'], {
            queryParams: null,
            queryParamsHandling: 'merge'
          })
        );
      } else if (error.status != 200) {
        ignorePromise(
          this.router.navigate(['/500'], {
            queryParams: null,
            queryParamsHandling: 'merge'
          })
        );
      }
      return EMPTY;
    };
  }
}
