import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  faGlobe,
  faGraduationCap,
  faHandshake,
  faHome,
  faInfoCircle,
  faSuitcase
} from '@fortawesome/free-solid-svg-icons';
import { GALAXY_SETTINGS, GalaxySettingsService } from '@ebcont/galaxy';
import { MainMenuService, NavigationItem } from '@core/services/menu/main.menu.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'hoc-navigation',
  templateUrl: './hoc-navigation.component.html',
  styleUrls: ['./hoc-navigation.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('sidebarTrigger', [
      state('open', style({})),
      state('close', style({})),
      transition('open => close', [
        style({ width: '4.5rem' }),
        animate('300ms ease-in-out', style({ width: '15rem' }))
      ]),
      transition('close => open', [style({ width: '15rem' }), animate('300ms ease-in-out', style({ width: '4.5rem' }))])
    ])
  ]
})
export class HocNavigationComponent implements OnInit {
  showsSidebar = true;
  @Input() isActive = false;
  @Input() image: string | undefined;

  public navigationItems$: Observable<NavigationItem[]> = of([]);

  constructor(library: FaIconLibrary, private settings: GalaxySettingsService, private mainMenu: MainMenuService) {
    library.addIcons(faHome, faInfoCircle, faSuitcase, faGraduationCap, faHandshake, faGlobe);
  }

  setActive(active: boolean) {
    this.isActive = active;
  }

  toggleCompact() {
    this.settings.toggle(GALAXY_SETTINGS.NAVIGATION_COMPACT);
  }

  get isNavigationCompact() {
    return this.settings.isTrue(GALAXY_SETTINGS.NAVIGATION_COMPACT);
  }

  ngOnInit(): void {
    this.setNavigationItems();
  }

  private setNavigationItems() {
    this.navigationItems$ = this.mainMenu.getNavigationItems();
  }
}
