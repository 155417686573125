import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SkillListItem } from '@core/models/skill';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-skill-list-item',
  templateUrl: './skill-list-item.component.html',
  styleUrls: ['./skill-list-item.component.css']
})
export class SkillListItemComponent {
  @Input() isAdmin = false;
  @Input() skill: SkillListItem = {} as SkillListItem;
}
