<div class="m-4">
  <h1>{{'settings.plural' | translate}}</h1>
  
  <div class="grid grid-cols-2 gap-4">
    <p>{{'settings.theme' | translate}}:</p>
    <gx-theme-button></gx-theme-button>

    <p>{{'settings.language' | translate}}:</p>
    <gx-language-menu></gx-language-menu>
  </div>

</div>
