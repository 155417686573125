import { HttpParameterCodec, HttpParams } from '@angular/common/http';

export class CustomHttpParamEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}

export const httpParamsFrom = (data: object): HttpParams => {
  let httpParams = new HttpParams({ encoder: new CustomHttpParamEncoder() });
  Object.keys(data)
    .filter((key) => {
      return Reflect.get(data, key) && key !== 'isAdmin';
    })
    .filter((key) => {
      return Reflect.get(data, key);
    })
    .forEach((key) => {
      httpParams = httpParams.append(key, Reflect.get(data, key));
    });
  return httpParams;
};
