import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { AbstractGroupedList } from '@hoc/hoc-grouped-list';
import {
  Project,
  projectDownloadMapper,
  ProjectFilterBuilder,
  projectGroupingOperator,
  updateProjectFilter
} from '@core/models/project';
import {
  EXACTLY_OPERATION,
  GalaxyDrawerBlockingService,
  ignorePromise,
  MenuItem,
  slideInFromRight
} from '@ebcont/galaxy';
import { environment } from '@env/environment';
import { InjectorKeys } from '@core/utils/injector.keys';
import { ApplicationConfiguration } from '@core/services/configuration/application.configuration.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService, TypeaheadItem } from '@core/prototypes/backend.service';
import {
  DateFilterParameters,
  FilterInstance,
  filterInstance,
  FILTERS,
  NumberFilterParameters
} from '@core/prototypes/filtering';
import { SIMPLE_EVENTS, textAsMenuItem, typeaheadsAsMenuItems } from '@core/utils/utils';
import { forkJoin, Observable } from 'rxjs';
import { Customer } from '@core/models/customer';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ProjectsService } from '@core/services/employee/projects.service';
import { UserService } from '@core/services/user/user.service';
import { TranslateService } from '@ngx-translate/core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.css'],
  animations: [slideInFromRight]
})
export class ProjectListComponent extends AbstractGroupedList<Project> implements OnInit {
  @ViewChild('drawerContent') drawerContent: TemplateRef<Element> | undefined;
  @ViewChild('drawerHeader') drawerHeader: TemplateRef<Element> | undefined;
  loadingSkeletonSize = new Array(environment.loadingSkeletonSize);
  maxLabelsInFilters = 2;
  isAdmin = false;

  public isDrawerOpen = false;
  public selectedProject: Project | undefined;

  public filtersForBlock: FilterInstance[] = [];
  public listIsDownloading = false;
  private initialized = false;
  public searchValue: MenuItem = { id: '', label: '', isRouterLink: false } as MenuItem;
  private projectCode = '';

  constructor(
    @Inject(InjectorKeys.PROJECTS_SERVICE) private service: BackendService<Project>,
    cd: ChangeDetectorRef,
    router: Router,
    route: ActivatedRoute,
    @Inject(InjectorKeys.CONFIGURATION) public config: ApplicationConfiguration,
    @Inject(InjectorKeys.CUSTOMERS_SERVICE) private customersService: BackendService<Customer>,
    private projectsService: ProjectsService,
    private userService: UserService,
    private translate: TranslateService,
    private drawerBlockingService: GalaxyDrawerBlockingService
  ) {
    super(service, projectGroupingOperator, config, cd, router, route);
  }

  ngOnInit(): void {
    this.filter = new ProjectFilterBuilder(this.config).build();
    const customers = this.customersService.typeahead('');
    const projects = this.projectsService.technologies('');
    this.route.queryParams.subscribe((queryParams) => {
      const pageNumber = parseInt(queryParams['pageNumber'] || 0);
      const pageSize = parseInt(queryParams['pageSize'] || this.config.pageSize);
      this.projectCode = queryParams['projectCode'] || '';
      this.filter.pageNumber = pageNumber;
      this.filter.pageSize = pageSize;
      this.pagination.currentPage = pageNumber;
      this.pagination.pageSize = pageSize;
    });

    this.userService.getAdminStatus().subscribe((isAdmin) => {
      this.isAdmin = isAdmin;
    });

    forkJoin({
      customers,
      projects
    }).subscribe({
      next: ({ customers, projects }) => {
        this.registerCustomersFilter(customers);
        this.registerProjectsFilter(projects);
      },
      complete: () => {
        this.initialized = true;
        this.cd.markForCheck();
        this.cd.detectChanges();
      }
    });
  }

  public handleEmptyList(): void {
    this.searchValue = textAsMenuItem('');
    this.isDrawerOpen = false;
    this.applyFilter();
  }

  private registerCustomersFilter(customers: TypeaheadItem[]) {
    this.filtersForBlock = [
      filterInstance(FILTERS.PROJECT_CUSTOMERS, typeaheadsAsMenuItems(customers), {
        switchMode: true,
        maxLabels: this.maxLabelsInFilters,
        isMulti: false,
        searchable: true
      })
    ];
  }

  private registerProjectsFilter(projects: string[]) {
    this.filtersForBlock.push(
      filterInstance(
        FILTERS.PROJECT_TECHNOLOGY,
        projects.map((p) => {
          return { label: p, id: p } as MenuItem;
        }),
        {
          switchMode: true,
          maxLabels: this.maxLabelsInFilters,
          isMulti: true,
          searchable: true
        }
      )
    );
    this.registerDateFilter(FILTERS.PROJECT_DATE_FROM);
    this.registerDateFilter(FILTERS.PROJECT_DATE_TO);
    this.registerNumberFilter(FILTERS.PROJECT_HOURS_MIN);
    this.registerNumberFilter(FILTERS.PROJECT_HOURS_MAX);
  }

  private registerNumberFilter(filterKey: FILTERS): void {
    const numberFilterParams: NumberFilterParameters = {
      defaultOperation: { ...EXACTLY_OPERATION },
      operations: [{ ...EXACTLY_OPERATION }],
      inputFieldStep: 1,
      inputFieldsWithArrows: true
    };

    this.filtersForBlock.push(
      filterInstance(filterKey, [{ label: 'test', id: 'test' } as MenuItem], {
        switchMode: true,
        adminOnly: true,
        isMulti: true,
        maxLabels: this.maxLabelsInFilters,
        filterType: 'number',
        numberFilterParams: numberFilterParams,
        filters: [filterKey],
        methodNeeded: false,
        operationNeeded: true
      })
    );
  }

  private registerDateFilter(filterKey: FILTERS): void {
    const dateFilterParams: DateFilterParameters = {
      defaultOperation: { ...EXACTLY_OPERATION },
      operations: [{ ...EXACTLY_OPERATION }],
      minYear: new Date().getFullYear() - 10,
      maxYear: new Date().getFullYear() + 10,
      iconAt: 'right'
    };

    this.filtersForBlock.push(
      filterInstance(filterKey, [], {
        switchMode: true,
        adminOnly: true,
        isMulti: true,
        maxLabels: this.maxLabelsInFilters,
        filterType: 'date',
        dateFilterParams: dateFilterParams,
        filters: [filterKey],
        methodNeeded: false,
        operationNeeded: true
      })
    );
  }

  private applyFilter() {
    // const newFilter = { ...this.filter };
    // Reflect.deleteProperty(newFilter, 'isAdmin');
    // Reflect.deleteProperty(newFilter, 'sorting');
    // this.filter = newFilter;
    ignorePromise(this.router.navigate(['projects'], { queryParams: this.filter }));
    this.reload();
  }

  public downloadTheList() {
    this.listIsDownloading = true;
    ignorePromise(
      this.backendService.downloadList(
        {
          ...this.filter,
          forDownload: true
        },
        projectDownloadMapper,
        'projects',
        this.pagination.totalItems
      )
    );
  }

  @HostListener(`document:${SIMPLE_EVENTS.DOWNLOAD_COMPLETED}`, ['$event'])
  protected downloadCompleted(): void {
    this.listIsDownloading = false;
  }

  protected readonly csvExportIcon = faDownload;

  /**
   * Searches for a name to serve the autocomplete dropdown.
   * @param text
   */
  protected searchForName = (text: string): Observable<MenuItem[]> => {
    return this.service.autocomplete(text).pipe(
      map((suggest: string[]) => {
        return suggest.map((item) => {
          return textAsMenuItem(item);
        });
      })
    );
  };

  filterBlockChanged(value: Record<string, string>) {
    if (!this.initialized) {
      return;
    }
    const oldFilter = JSON.stringify(this.filter); // the current filter
    this.filter = updateProjectFilter(this.filter, value);
    const newFilter = JSON.stringify(this.filter); // the new filter
    // the update is also triggered after a reload, and when there is no real change, then we should not
    // apply the filter, which would cause a reload of the same data and inducing an infinite loop
    if (oldFilter !== newFilter && this.initialized) {
      this.closeDrawer();
      this.filter.pageNumber = 0;
      this.applyFilter();
    }
  }

  clickedOnProject(item: Project) {
    this.selectedProject = item;
    this.isDrawerOpen = true;
  }

  closeDrawer() {
    this.isDrawerOpen = false;
  }

  public override onReloaded() {
    super.onReloaded();
    const allProjects = Array.from(this.items.values()).flat();
    const found = allProjects.find((customer) => {
      return customer.id === this.projectCode;
    });
    if (found) {
      this.clickedOnProject(found);
    }
  }
}
