import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  Input,
  OnInit
} from '@angular/core';
import { environment } from '@env/environment';
import { EXACTLY_OPERATION, ignorePromise, MenuItem } from '@ebcont/galaxy';
import { InjectorKeys } from '@core/utils/injector.keys';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationConfiguration } from '@core/services/configuration/application.configuration.interface';
import {
  Customer,
  customerDownloadMapper,
  CustomerFilterBuilder,
  customerGroupingOperator,
  updateCustomerFilter
} from '@core/models/customer';
import { AbstractGroupedList } from '@hoc/hoc-grouped-list';
import { BackendService } from '@core/prototypes/backend.service';
import {
  DateFilterParameters,
  Filter,
  FilterInstance,
  filterInstance,
  FILTERS,
  forNavigation,
  NumberFilterParameters,
  resolveCompositeFilterValues
} from '@core/prototypes/filtering';
import { UserService } from '@core/services/user/user.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { SIMPLE_EVENTS, textAsMenuItem } from '@core/utils/utils';
import { map } from 'rxjs/operators';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

@UntilDestroy()
@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerListComponent extends AbstractGroupedList<Customer> implements OnInit {
  @Input() maxLabelsInFilters = 2;

  public loadingSkeletonSize: number[] = new Array(environment.loadingSkeletonSize);
  public searchValue = textAsMenuItem('');
  public isAdmin = true;
  private initialized = false;
  protected filtersForBlock: FilterInstance[] = [];

  public listIsDownloading = false;

  public isDrawerOpen = false;
  public customerId = '';
  public selectedCustomer: Customer | undefined;

  constructor(
    @Inject(InjectorKeys.CUSTOMERS_SERVICE) customerBackendService: BackendService<Customer>,
    cd: ChangeDetectorRef,
    router: Router,
    route: ActivatedRoute,
    @Inject(InjectorKeys.CONFIGURATION) public config: ApplicationConfiguration,
    userService: UserService
  ) {
    super(customerBackendService, customerGroupingOperator, config, cd, router, route);
    userService.getAdminStatus().subscribe((isAdmin) => {
      this.isAdmin = isAdmin;
    });
  }

  ngOnInit(): void {
    this.filter = new CustomerFilterBuilder(this.config).build();
    this.route.queryParams.subscribe((params) => {
      const pageNumber = parseInt(params['pageNumber'] || 0);
      const pageSize = parseInt(params['pageSize'] || this.config.pageSize);
      this.customerId = params['customerId'] || '';
      this.filter.pageNumber = pageNumber;
      this.filter.pageSize = pageSize;
      this.pagination.currentPage = pageNumber;
      this.pagination.pageSize = pageSize;

      this.filtersForBlock = [
        this.getDateFilter(FILTERS.CUSTOMER_FROM_DATE),
        this.getDateFilter(FILTERS.CUSTOMER_TO_DATE),
        this.getNumberFilter(FILTERS.CUSTOMER_HOURS_MIN),
        this.getNumberFilter(FILTERS.CUSTOMER_HOURS_MAX)
      ];

      this.initialized = true;
      this.cd.markForCheck();
      this.cd.detectChanges();
    });
  }

  public handleEmptyList(): void {
    this.searchValue = textAsMenuItem('');
    this.applyFilter();
  }

  private getDateFilter(filterKey: FILTERS): FilterInstance {
    const dateFilterParams: DateFilterParameters = {
      defaultOperation: { ...EXACTLY_OPERATION },
      operations: [{ ...EXACTLY_OPERATION }],
      minYear: new Date().getFullYear() - 10,
      maxYear: new Date().getFullYear() + 10,

      iconAt: 'right'
    };

    return filterInstance(filterKey, [], {
      adminOnly: false, // FIXME: this should be true after testing
      switchMode: true,
      isMulti: true,
      maxLabels: this.maxLabelsInFilters,
      filterType: 'date',
      dateFilterParams: dateFilterParams,
      filters: [filterKey]
    });
  }

  private getNumberFilter(filterKey: FILTERS): FilterInstance {
    const numberFilterParams: NumberFilterParameters = {
      defaultOperation: { ...EXACTLY_OPERATION },
      operations: [{ ...EXACTLY_OPERATION }],
      inputFieldStep: 1,
      inputFieldsWithArrows: true
    };

    return filterInstance(filterKey, [{ label: 'test', id: 'test' } as MenuItem], {
      adminOnly: true,
      switchMode: true,
      isMulti: true,
      maxLabels: this.maxLabelsInFilters,
      filterType: 'number',
      numberFilterParams: numberFilterParams,
      filters: [filterKey]
    });
  }

  private applyFilter(): void {
    this.isDrawerOpen = false;
    this.router.navigate(['customer'], { queryParams: forNavigation(this.filter) });
    this.reloadAndDetectChanges();
  }

  private reloadAndDetectChanges() {
    this.reload();
    this.cd.detectChanges();
  }

  /**
   * Searches for a name to serve the autocomplete dropdown.
   * @param text
   */
  protected searchForName = (text: string): Observable<MenuItem[]> => {
    return this.backendService.autocomplete(text).pipe(
      map((suggest: string[]) => {
        return suggest.map((item) => {
          return textAsMenuItem(item);
        });
      })
    );
  };

  filterBlockChanged(value: Record<string, string>) {
    if (!this.initialized) {
      return;
    }
    const oldFilter = JSON.stringify(this.filter); // the current filter
    this.filter = updateCustomerFilter(this.filter, value);
    const newFilter = JSON.stringify(this.filter); // the new filter
    // the update is also triggered after a reload, and when there is no real change, then we should not
    // apply the filter, which would cause a reload of the same data and inducing an infinite loop
    if (oldFilter !== newFilter && this.initialized) {
      this.applyFilter();
    }
  }

  public override processFilter(): Filter {
    return resolveCompositeFilterValues(this.filter);
  }

  public downloadTheList() {
    this.listIsDownloading = true;
    ignorePromise(
      this.backendService.downloadList(this.filter, customerDownloadMapper, 'customers', this.pagination.totalItems)
    );
  }

  @HostListener(`document:${SIMPLE_EVENTS.DOWNLOAD_COMPLETED}`, ['$event'])
  protected downloadCompleted(): void {
    this.listIsDownloading = false;
  }

  protected readonly csvExportIcon = faDownload;

  clickedOnCustomer(item: Customer) {
    this.selectedCustomer = item;
    this.isDrawerOpen = true;
    this.cd.detectChanges();
  }

  closeDrawer() {
    this.isDrawerOpen = false;
  }

  public override onReloaded() {
    super.onReloaded();
    const allCustomers = Array.from(this.items.values()).flat();
    const found = allCustomers.find((customer) => {
      return customer.id === this.customerId;
    });
    if (found) {
      this.clickedOnCustomer(found);
    }
  }
}
