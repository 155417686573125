import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { InjectorKeys } from '@core/utils/injector.keys';
import { BackendService } from '@core/prototypes/backend.service';
import { AbstractGroupedList } from '@hoc/hoc-grouped-list';
import { Employee } from '@core/models/employee';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationConfiguration } from '@core/services/configuration/application.configuration.interface';
import { Project, ProjectFilter, projectGroupingOperator } from '@core/models/project';
import { Customer } from '@core/models/customer';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-customer-project-list',
  templateUrl: './customer-project-list.component.html',
  styleUrls: ['./customer-project-list.component.css']
})
export class CustomerProjectListComponent extends AbstractGroupedList<Project> implements OnChanges {
  @Input() isAdmin = false;
  @Input() customer?: Customer | undefined;

  constructor(
    @Inject(InjectorKeys.PROJECTS_SERVICE) private service: BackendService<Project>,
    cd: ChangeDetectorRef,
    router: Router,
    route: ActivatedRoute,
    @Inject(InjectorKeys.CONFIGURATION) public config: ApplicationConfiguration
  ) {
    super(service, projectGroupingOperator, config, cd, router, route);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // throw new Error('Method not implemented.');
    if (changes['customer'] && changes['customer'].currentValue) {
      this.customer = changes['customer'].currentValue;
      this.filter.activeOnly = 'false';
      (this.filter as ProjectFilter).customerIds = this.customer?.id;
      this.filter.pageNumber = 0;
      this.reload();
    }
  }

  protected readonly JSON = JSON;
  loadingSkeletonSize = Array.from(Array(4).keys());

  getPersonLink(item: Employee) {
    return `team?activeOnly=false&pageNumber=0&pageSize=10&searchValue=${item.businessPartnerFormattedName}
    &sorting=asc&employee=${item.email}&tab=profile`;
  }

  getProjectLink(item: Project) {
    return (
      'projects?activeOnly=false&pageNumber=0&pageSize=10&searchValue=' +
      item.id +
      '&customerId=' +
      item.projectBuyerParty +
      '&projectCode=' +
      item.id
    );
  }
}
