/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ALL_FILTER_OPERATIONS,
  EXACTLY_OPERATION,
  FilterMethod,
  FilterOperation,
  GxDateFilterComponent,
  GxDropdownComponent,
  GxNumberFilterComponent,
  MenuItem,
  ReloadFunction
} from '@ebcont/galaxy';
import { ApplicationConfiguration } from '../services/configuration/application.configuration.interface';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

/**
 * @interface Filter
 * @description Defines the parameters for a filter
 */
export interface Filter {
  activeOnly?: string;
  pageNumber: number;
  pageSize: number;
}

/**
 * The "any" filter item.
 */
export const FILTER_ANY: MenuItem = { label: 'filter.any' } as MenuItem;

/**
 * @interface FilterInstance
 * @describe Defines the parameters for a number filter instance
 *
 * @param defaultOperation The default operation
 * @param operations The available operations
 * @param inputFieldStep The step size for the input field
 * @param inputFieldsWithArrows Whether the input fields should have arrows
 * @param filterMethod The filter method
 */
export interface NumberFilterParameters {
  defaultOperation: FilterOperation;
  operations: FilterOperation[];
  inputFieldStep: number;
  inputFieldsWithArrows: boolean;
  filterMethod?: FilterMethod<number>;
}

/**
 * @interface DateFilterParameters
 * @describe Defines the parameters for a date filter instance
 *
 * @param defaultOperation The default operation
 * @param operations The available operations
 * @param minYear The minimum year
 * @param maxYear The maximum year
 * @param iconAt The icon position
 * @param filterMethod The filter method
 */
export interface DateFilterParameters {
  defaultOperation: FilterOperation;
  operations: FilterOperation[];
  minYear: number;
  maxYear: number;
  iconAt: 'left' | 'right';
  filterMethod?: FilterMethod<Date>;
}

/**
 * @interface FilterInstance
 * @description Defines the parameters fir a filter instance
 *
 * @param key The key of the filter
 * @param adminOnly Whether the filter is for admins only
 * @param items The available items
 * @param selectedItems The selected items
 * @param isMulti Whether the filter is a multi select filter
 * @param searchable Whether the filter is searchable
 * @param maxLabels The maximum number of labels in the caption
 * @param switchMode Whether the filter is operating is switch mode (single option can be selected/deselected)
 * @param reload The reload function to be used if searchable is true
 * @param filterType The filter type
 * @param numberFilter The additional number filter parameters
 * @param dateFilter The additional date filter parameters
 * @param filters The available filters
 * @param createCaption The caption creation function
 * @param canHide Whether the filter can be hidden
 * @param operationNeeded Whether the operation is needed for the client, marker flag for the client
 * @param valueNeeded Whether the filter method is needed for the client, marker flag for the client
 */
export interface FilterInstance {
  key: FILTERS;
  adminOnly?: boolean;
  items: MenuItem[];
  selectedItems: MenuItem[];
  selectedOperation?: FilterOperation;
  isMulti: boolean;
  searchable: boolean;
  maxLabels: number;
  switchMode: boolean;
  reload?: ReloadFunction;
  filterType: 'date' | 'number' | 'items';
  numberFilterParams: NumberFilterParameters;
  dateFilterParams: DateFilterParameters;
  filters?: FILTERS[];
  createCaption?: (dropdown: any) => string;
  canHide: boolean;
  operationNeeded?: boolean;
  methodNeeded?: boolean;
}

/**
 * @enum FILTERS
 * @description Defines the available filters
 */
export enum FILTERS {
  LOCATIONS = 'locations',
  COMPANIES = 'companies',
  AREAS = 'areas',
  GREMIEN = 'gremien',
  CUSTOMERS = 'customerIds',
  PROJECT_CUSTOMERS = 'customerId', // this is an error on the ML backend, wrong name for filter
  LANGUAGES = 'languages',
  PROJECTS = 'projectIds',
  SKILL = 'skill',
  YEARS = 'years',
  LEVEL = 'level',
  PROJECT_DATE_BEGIN = 'project_date_begin',
  PROJECT_DATE_END = 'project_date_end',
  CUSTOMER_FROM_DATE = 'fromDate',
  CUSTOMER_TO_DATE = 'toDate',
  CUSTOMER_HOURS_MIN = 'minHours',
  CUSTOMER_HOURS_MAX = 'maxHours',
  PROJECT_HOURS = 'project_hours',
  PROJECT_HOURS_MIN = 'minHours',
  PROJECT_HOURS_MAX = 'maxHours',
  PROJECT_DATE_FROM = 'fromDate',
  PROJECT_DATE_TO = 'toDate',
  ACTIVE_ONLY = 'activeOnly',
  PROJECT_TECHNOLOGY = 'projectTechnology',
  CORE_COMPETENCY = 'coreCompetency'
}

/**
 * @const DEFAULT_NUMBER_FILTER_PARAMS
 * @description The default number filter parameters
 */
export const DEFAULT_NUMBER_FILTER_PARAMS: NumberFilterParameters = {
  defaultOperation: EXACTLY_OPERATION,
  operations: ALL_FILTER_OPERATIONS,
  inputFieldStep: 20,
  inputFieldsWithArrows: true
};

/**
 * @const DEFAULT_DATE_FILTER_PARAMS
 * @description The default date filter parameters
 */
export const DEFAULT_DATE_FILTER_PARAMS: DateFilterParameters = {
  defaultOperation: EXACTLY_OPERATION,
  operations: ALL_FILTER_OPERATIONS,
  minYear: new Date().getFullYear() - 10,
  maxYear: new Date().getFullYear() + 10,
  iconAt: 'right'
};

/**
 * @function filterInstance
 * @description Creates a filter instance with the given parameters, based on reasonable defaults.
 *
 * @param key The key of the filter
 * @param items The initial items
 * @param options The options to be applied over the defaults
 * @returns The new filter instance
 */
export const filterInstance = (
  key: FILTERS,
  items: MenuItem[] = [],
  options: Partial<FilterInstance> = {}
): FilterInstance => {
  return Object.assign(
    {} as FilterInstance,
    {
      key,
      items,
      selectedItems: [],
      isMulti: true,
      searchable: true,
      maxLabels: 1,
      switchMode: false,
      filterType: 'items',
      numberFilterParams: { ...DEFAULT_NUMBER_FILTER_PARAMS },
      dateFilterParams: { ...DEFAULT_DATE_FILTER_PARAMS },
      canHide: true,
      operationNeeded: true,
      methodNeeded: false
    },
    options
  );
};

/**
 * @function emptyFilterInstance
 * @description Creates an empty filter instance with the given key.
 * @param key The key of the filter
 * @returns The empty filter instance
 */
export const emptyFilterInstance = (key: FILTERS): FilterInstance => {
  return {
    key,
    items: [],
    selectedItems: [],
    isMulti: false,
    searchable: false,
    maxLabels: 1,
    switchMode: false,
    filterType: 'items',
    numberFilterParams: { ...DEFAULT_NUMBER_FILTER_PARAMS },
    dateFilterParams: { ...DEFAULT_DATE_FILTER_PARAMS },
    canHide: true
  } as FilterInstance;
};

/**
 * @function emptyFilter
 * @description Creates an empty filter with the given configuration.
 * @param config The application configuration
 * @returns The empty filter
 */
export const emptyFilter = (config: ApplicationConfiguration): Filter => {
  return {
    activeOnly: config.activeOnly?.toString(),
    pageNumber: 0,
    pageSize: config.pageSize ?? 10
  };
};

/**
 * @function createCaptionForNumberFilter
 * @description Creates a caption for the given number filter.
 * @param numberFilter The filter for which the caption should be generated
 * @returns The caption
 */
export const createCaptionForNumberFilter = (numberFilter: GxNumberFilterComponent): string => {
  if (numberFilter.selectedOperation) {
    if (numberFilter.selectedOperation.isMulti) {
      if (!numberFilter.number1 || !numberFilter.number2) {
        return numberFilter.translate.instant(numberFilter.label);
      }
      return (
        numberFilter.translate.instant(numberFilter.label) +
        ': ' +
        numberFilter.translate.instant(numberFilter.selectedOperation.label) +
        ' ' +
        numberFilter.number1 +
        ' ' +
        numberFilter.translate.instant('filter.and') +
        ' ' +
        numberFilter.number2
      );
    } else {
      if (!numberFilter.number1) {
        return numberFilter.translate.instant(numberFilter.label);
      }
      return (
        numberFilter.translate.instant(numberFilter.label) +
        ': ' +
        numberFilter.translate.instant(numberFilter.selectedOperation.label) +
        ' ' +
        numberFilter.number1
      );
    }
  }

  return (
    numberFilter.translate.instant(numberFilter.label) + ': ' + numberFilter.translate.instant('filter.selectNumber')
  );
};

/**
 * @function createCaptionForDateFilter
 * @description Creates a caption for the given date filter.
 * @param dateFilter The filter for which the caption should be generated
 * @param dateFormat The date format to be used, default is "mediumDate" (Jan 1, 2000)
 * @returns The caption
 */
export const createCaptionForDateFilter = (dateFilter: GxDateFilterComponent, dateFormat = 'mediumDate'): string => {
  const datePipe = new DatePipe(dateFilter.translate.currentLang);
  if (dateFilter.selectedOperation) {
    if (dateFilter.selectedOperation.isMulti) {
      if (!dateFilter.date1 || !dateFilter.date2) {
        return dateFilter.translate.instant(dateFilter.label);
      }
      return (
        dateFilter.translate.instant(dateFilter.label) +
        ': ' +
        dateFilter.translate.instant(dateFilter.selectedOperation.label) +
        ' ' +
        datePipe.transform(dateFilter.date1, dateFormat) +
        ' ' +
        dateFilter.translate.instant('filter.and') +
        ' ' +
        datePipe.transform(dateFilter.date2, dateFormat)
      );
    } else {
      if (!dateFilter.date1) {
        return dateFilter.translate.instant(dateFilter.label);
      }
      return (
        dateFilter.translate.instant(dateFilter.label) +
        ': ' +
        dateFilter.translate.instant(dateFilter.selectedOperation.label) +
        ' ' +
        datePipe.transform(dateFilter.date1, dateFormat)
      );
    }
  }

  return dateFilter.translate.instant(dateFilter.label) + ': ' + dateFilter.translate.instant('filter.selectDate');
};

/**
 * The caption generation is a bit tricky. We want to show the selected items in the caption, but only if there are
 * not too many. If there are too many, we show the first few selected items and add a some "more" marker at the end.
 * @param dropdown The dropdown for which the caption should be generated
 * @ignore
 */
export const createCaptionForDropdown = (dropdown: GxDropdownComponent): string => {
  if (dropdown.selectedItems.length === 0) {
    return dropdown.translate.instant(dropdown.label) + ' ' + dropdown.translate.instant('filter.any');
  }

  if (dropdown.selectedItems.length === dropdown.menuItems.length) {
    return dropdown.translate.instant(dropdown.label) + ' ' + dropdown.translate.instant('filter.all');
  }

  if (dropdown.selectedItems.length <= dropdown.maxLabels) {
    if (dropdown.selectedItems.length < 2) {
      return (
        dropdown.translate.instant(dropdown.label) +
        ' ' +
        dropdown.translate.instant('filter.is') +
        ' ' +
        dropdown.translate.instant(dropdown.selectedItems[0].label)
      );
    } else {
      return (
        dropdown.translate.instant(dropdown.label) +
        ' ' +
        dropdown.translate.instant('filter.are') +
        ' ' +
        dropdown.selectedItems
          .map((item) => {
            return dropdown.translate.instant(item.label);
          })
          .join(', ')
      );
    }
  } else {
    const labels = dropdown.selectedItems
      .slice(0, dropdown.maxLabels)
      .map((item) => {
        return dropdown.translate.instant(item.label);
      })
      .join(', ');

    return (
      dropdown.translate.instant(dropdown.label) +
      ' ' +
      dropdown.translate.instant('filter.are') +
      ' ' +
      labels +
      ' ' +
      dropdown.translate.instant('filter.more')
    );
  }
};

/**
 * Tha status filter is a bit of special, it has a different caption generation and is not searchable.
 * @param translate The translation service
 */
export const getStatusFilter = (translate: TranslateService): FilterInstance => {
  return filterInstance(
    FILTERS.ACTIVE_ONLY,
    [
      { label: translate.instant('status.only_active'), id: 'true' },
      { label: translate.instant('status.active_and_inactive'), id: 'false' }
    ] as MenuItem[],
    {
      switchMode: false,
      isMulti: false,
      canHide: false
    }
  );
};

/**
 * Determines the new selected items based on the incoming IDs and the toggle flag.
 * @param instance The filter instance
 * @param incomingValues The incoming values, separated by comma
 * @param merge Whether to merge the selection (replace the already selected items with the new ones)
 * @returns The new selected items
 */
export const getNewSelectedItems = (instance: FilterInstance, incomingValues: string, merge = false): MenuItem[] => {
  if (merge) {
    if (instance.filterType === 'items') {
      return getSelectedItemsSimple(instance, incomingValues.split(','));
    } else {
      throw new Error('getSelectedItemsComposite is not yet implemented');
    }
  } else {
    if (instance.filterType === 'items') {
      return getItemsSimple(instance, incomingValues.split(','));
    } else {
      throw new Error('getItemsComposite is not yet implemented');
    }
  }
};

/**
 * Determines the new selected operation based on the incoming values.
 * @param instance The filter instance (date or number)
 * @param incomingValues The incoming values in OP:VALUE1:VALUE2 format
 * @returns The new selected operation, or undefined if "items" filter or operation not found
 */
export const getOperation = (instance: FilterInstance, incomingValues: string): FilterOperation | undefined => {
  const values = incomingValues.split(':');
  if (instance.filterType === 'date' || instance.filterType === 'number') {
    const operation = (
      instance.filterType === 'number' ? instance.numberFilterParams : instance.dateFilterParams
    ).operations.find((operation) => {
      return operation.id === values[0];
    });
    if (!operation) {
      return undefined;
    }
    operation.firstOperand = getFilterValue(instance, values, 1);
    operation.secondOperand = getFilterValue(instance, values, 2);
    return operation;
  }
  return undefined;
};

/**
 * Returns the filter value based on the filter type and the incoming values.
 * @param instance The filter instance
 * @param incomingValues The incoming values
 * @param index The index of the value
 * @returns The filter value or undefined if not found or the filter is "items"
 */
export const getFilterValue = (instance: FilterInstance, incomingValues: string[], index: number): any => {
  if (incomingValues.length < index + 1) {
    return undefined;
  }

  if (instance.filterType === 'date') {
    return new Date(incomingValues[index]);
  }
  if (instance.filterType === 'number') {
    return parseFloat(incomingValues[index]);
  }
  return undefined;
};

/**
 * Return the already selected items in the filter instance merged with the items specified by the IDs in the
 * incoming values.
 * @param instance The filter instance
 * @param incomingIDs The IDs of the "news" items
 * @returns The list of the already selected items and the new items specified by the "incomingValues"
 */
export const getSelectedItemsSimple = (instance: FilterInstance, incomingIDs: string[]): MenuItem[] => {
  const alreadySelectedIds: string[] = instance.selectedItems
    .filter((item: MenuItem) => {
      return incomingIDs.includes(item.id);
    })
    .map((item: MenuItem) => {
      return item.id;
    });

  const notSelectedIds: string[] = incomingIDs.filter((item: string) => {
    return !alreadySelectedIds.includes(item);
  });

  const newItems = [...instance.selectedItems];
  instance.items
    .filter((item: MenuItem) => {
      return notSelectedIds.includes(item.id);
    })
    .forEach((item: MenuItem) => {
      newItems.push(item);
    });
  return newItems;
};

/**
 * Returns the list of items specified by the IDs in the incomingIDs.
 * @param instance The instance of the filter
 * @param incomingIDs The incoming IDs
 * @returns The list of items specified by the IDs in the incomingIDs
 */
export const getItemsSimple = (instance: FilterInstance, incomingIDs: string[]): MenuItem[] => {
  return instance.items.filter((item: MenuItem) => {
    return incomingIDs.includes(item.id);
  });
};

/**
 * Resolves the composite values in the form of "OP:PARAM1:PARAM2" of the filter parameters,
 * for example: in:VALUE1:VALUE2 will be resolved as an "VALUE1,VALUE2".
 * @param params The parameters which may contain composite values
 * @returns The resolved parameters.
 */
export const resolveCompositeFilterValues = (params: any): any => {
  const resolved: object = {};

  Object.keys(params).forEach((key: string) => {
    const value = Reflect.get(params, key);
    if (typeof value === 'string' && value.includes(':')) {
      const parts = value.split(':');
      Reflect.set(resolved, key, parts.slice(1).join(','));
    } else {
      Reflect.set(resolved, key, value);
    }
  });
  return resolved;
};

// export const filterArray = <T extends object>(array: T[], filters: SimpleFilter[]): T[] => {
//   return array.filter((item: T) => {
//     return filters.every((filter: SimpleFilter) => {
//       return filter.values.includes(Reflect.get(item as object, filter.param));
//     });
//   });
// };

/**
 * Converts the given array to a list of menu items.
 * @param array The string array
 * @param useIndex Whether to use the index as the id, if false then the value will be used as the id
 * @param correction When using the index as the id, this value will be added to the index
 */
export const asMenuItems = (array: string[], useIndex = true, correction = 0): MenuItem[] => {
  return Array.from(
    new Set( // make it distinct
      array.filter((value) => {
        return !!value;
      }) // clear null and undefined values
    )
  ).map((value, index) => {
    // convert
    return { id: useIndex ? String(index + correction) : value, label: value, isRouterLink: false } as MenuItem;
  });
};

export const forNavigation = (filter: Filter): Record<string, string> => {
  const navigation: Record<string, string> = {};
  Object.keys(filter).forEach((key) => {
    if (Reflect.get(filter, key)) {
      // navigation[key] = encodeURIComponent(Reflect.get(filter, key).toString());
      navigation[key] = Reflect.get(filter, key).toString();
    }
  });
  return navigation;
};

export interface AppliedFilter {
  filter: FILTERS;
  value: string;
}
