import { AbstractBackendService } from '../../prototypes/backend.service';
import { DashboardStats } from '@core/models/dashboardStats';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ExceptionHandlerService } from '@core/services/exceptionhandler/exception.handler.service';
import { catchError, Observable } from 'rxjs';

/**
 * The EmployeeDetailsService interface is an extension of a standard backend service. It adds a method to retrieve the
 * profile image of an employee.
 */
@Injectable({
  providedIn: 'root'
})
export class DashboardService extends AbstractBackendService<DashboardStats> {
  constructor(http: HttpClient, private exceptionService: ExceptionHandlerService) {
    super(`/api/dashboard`, http);
  }

  override get(): Observable<DashboardStats> {
    return this.http
      .get<DashboardStats>(this.endPointUrl)
      .pipe(catchError(this.exceptionService.handleError<DashboardStats>()));
  }
}
