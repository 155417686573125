<ng-container *ngIf="factSheet">
  <app-factsheet-header [factSheet]="factSheet"></app-factsheet-header>
  <gx-tabs>
    <gx-tab [caption]="'project.factSheet.overview.overview' | translate" id="overview">
      <app-factsheet-overview [factSheet]="factSheet"></app-factsheet-overview>
    </gx-tab>
    <gx-tab [caption]="'project.factSheet.sticker.sticker' | translate" id="sticker">
      <app-factsheet-project-sticker [factSheet]="factSheet"></app-factsheet-project-sticker>
    </gx-tab>
    <gx-tab [caption]="'project.factSheet.contacts.contacts' | translate" id="contacts">
      <app-factsheet-contacts [factSheet]="factSheet"></app-factsheet-contacts>
    </gx-tab>
  </gx-tabs>
</ng-container>

