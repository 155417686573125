import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { InjectorKeys } from '@core/utils/injector.keys';
import { BackendService } from '@core/prototypes/backend.service';
import { AbstractGroupedList } from '@hoc/hoc-grouped-list';
import { Employee, employeeGroupingOperator, EmployeeSkillFilter } from '@core/models/employee';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationConfiguration } from '@core/services/configuration/application.configuration.interface';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-skill-employee-list',
  templateUrl: './skill-employee-list.component.html',
  styleUrls: ['./skill-employee-list.component.css']
})
export class SkillEmployeeListComponent extends AbstractGroupedList<Employee> implements OnChanges {
  @Input() isAdmin = false;
  @Input() stars? = 0;
  @Input() skill? = '';

  public override filter: EmployeeSkillFilter = {
    skill: '',
    skillLevel: 0,
    sortDirection: this.sorting,
    pageNumber: 0,
    pageSize: 10
  };

  constructor(
    @Inject(InjectorKeys.EMPLOYEES_SKILL_SERVICE) private service: BackendService<Employee>,
    cd: ChangeDetectorRef,
    router: Router,
    route: ActivatedRoute,
    @Inject(InjectorKeys.CONFIGURATION) public config: ApplicationConfiguration
  ) {
    super(service, employeeGroupingOperator, config, cd, router, route);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // throw new Error('Method not implemented.');
    if ((changes['stars'] && changes['stars'].currentValue) || (changes['skill'] && changes['skill'].currentValue)) {
      this.filter.skill = this.skill ?? '';
      this.filter.skillLevel = this.stars ?? 0;
      this.filter.pageNumber = 0;
      this.reloadList();
    }
  }

  private reloadList(): void {
    if (this.skill && this.skill.trim().length > 0 && this.stars && this.stars > 0) {
      this.reload();
    }
  }

  protected readonly JSON = JSON;
  loadingSkeletonSize = Array.from(Array(4).keys());

  getPersonLink(item: Employee) {
    return `team?activeOnly=false&pageNumber=0&pageSize=10&searchValue=${item.businessPartnerFormattedName}
    &sorting=asc&employee=${item.email}&tab=profile`;
  }

  public override sortingChanged(sorting: string) {
    this.sorting = sorting as 'asc' | 'desc';
    this.filter.skill = this.skill ?? '';
    this.filter.sortDirection = this.sorting;
    this.reloadList();
  }
}
