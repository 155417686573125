<div class="flex h-full flex-1 border-b border-ui-border" id="listBlock">
  <div class="flex flex-1 flex-col overflow-auto">
    <div class="flex items-center justify-between" id="titleBlock">
      <gx-section-title
        [title]="'skill.skillSize' | translate : { skills: lastResult.totalNumberResultsets }"
        class="px-sx" *ngIf="searchFor.id ==='skill'"/>
      <gx-section-title
        [title]="'skill.coreCompetenceSize' | translate : { skills: lastResult.totalNumberResultsets }"
        class="px-sx" *ngIf="searchFor.id === 'coreCompetence'"/>

      <gx-button
        *ngIf="isAdmin"
        [text]="'downloadTheList' | translate"
        [title]="'downloadTheList' | translate"
        [icon]="csvExportIcon"
        textClass="hidden md:inline"
        class="mr-4"
        [isDisabled]="listIsDownloading"
        (click)="downloadTheList()"></gx-button>
    </div>

    <div class="flex h-full flex-1 flex-col gap-4 py-4">
      <div class="flex w-full items-center gap-2" id="filterBlock">
        <hoc-filter-block [textSearch]="searchForName" [statusFilterVisible]="false"
                          (changed)="filterBlockChanged($event)" [defaultSorting]="sorting">
          <ng-template #filtering>
            <div
              class="rounded-full border-2 border-ui-interactive bg-ui-surface-rise px-4 py-2 ">
              <gx-dropdown
                [switchMode]="true"
                label="filter.searchOptions"
                icon="chevron-down"
                openWith="pill"
                [isRightAligned]="true"
                notificationAt="whenSelected"
                iconAt="right"
                [createCaption]="createCaptionForDropdown"
                [isSelectable]="true"
                [allSelectable]="false"
                [menuItems]="searchForOptions"
                [defaultSelectedItems]="[searchFor]"
                (menuItemSelected)="filterSelected($event)"
                [multiSelect]="false"
                [withSearch]="false"/>
            </div>
          </ng-template>
        </hoc-filter-block>
      </div>
      <hoc-grouped-list
        id="skillList"
        [loading]="loading"
        [groupLetters]="groupLetters"
        [items]="items"
        [loadingBlock]="loadingBlock"
        [footerBlock]="footerBlock"
        [groupBlock]="groupBlock"
        [itemBlock]="itemBlock"
        [emptyItemsBlock]="emptyItemsBlock"
        (scrolledToEnd)="scrolledToEnd()">
      </hoc-grouped-list>
    </div>
  </div>
  <div
    @slideInFromRight
    class="absolute inset-0 z-10 flex flex-1 flex-col overflow-auto bg-ui-surface-rise md:static"
    *ngIf="isDrawerOpen && items.size > 0">
    <app-skill-details [skill]="selectedSkill" [isAdmin]="isAdmin" (closeClicked)="closeDrawer()"></app-skill-details>
  </div>
</div>

<ng-template #loadingBlock>
  <ng-container *ngTemplateOutlet="loadingTitle"></ng-container>
  <ng-container *ngFor="let item of loadingSkeletonSize">
    <app-team-list-placeholder [isExtended]="true"/>
  </ng-container>
</ng-template>

<ng-template #loadingTitle>
  <div class="list-title">
    <h3 class="list-title-text my-1 ms-4"></h3>
  </div>
</ng-template>

<ng-template #emptyItemsBlock>
  <app-empty-list-page (backButtonClicked)="handleEmptyList()"></app-empty-list-page>
</ng-template>

<ng-template #footerBlock>
  <div #footerContainer class="flex w-full justify-center px-3 py-4">
    <!-- TODO: Add a <gx-button>Load more</gx-button> also with infinite scroll. in case something is not working user has control!-->
    <gx-pagination
      *ngIf="loadMode === 'paged' && items.size > 0"
      [data]="pagination"
      (newPage)="gotoPage($event)"
      [availablePageSizes]="config.pageSizes"
      [currentPageSize]="filter.pageSize"
      [lengthBeginSegment]="2"
      [lengthEndSegment]="2"
      (newPageSize)="newPageSize($event)"/>
    <app-team-list-placeholder
      *ngIf="loadMode === 'infinite' && canLoadMore"
      [isExtended]="true"
      (click)="scrolledToEnd()"/>
  </div>
</ng-template>

<ng-template #emptyItemsBlock>
  <app-empty-list-page (backButtonClicked)="handleEmptyList()"></app-empty-list-page>
</ng-template>

<ng-template #groupBlock let-letter>
  <h3 class="list-title-text">{{ letter }}</h3>
</ng-template>

<ng-template #loadingTitle>
  <div class="list-title">
    <h3 class="list-title-text my-1 ms-4"></h3>
  </div>
</ng-template>

<ng-template #itemBlock let-item>
  <app-skill-list-item [skill]="item" [isAdmin]="isAdmin" (click)="clickedOnSkill(item)"></app-skill-list-item>
</ng-template>
