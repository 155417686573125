import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-project-factsheet',
  templateUrl: './fact-sheet.component.html',
  styleUrls: ['./fact-sheet.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FactSheetComponent {
  @Input() factSheet: XMLDocument | undefined = undefined;
}
