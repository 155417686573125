import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { filter, Observable, Subject } from 'rxjs';
import { AccountInfo, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loginSuccess = new Subject<boolean>;

  constructor(private msal: MsalService, private msalBroadcastService: MsalBroadcastService) {
    this.msalBroadcastService.inProgress$
      .pipe(
        untilDestroyed(this),
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        )
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        untilDestroyed(this),
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe(msg => {
        console.log('login success', msg);
      });
  }

  /**
   * In order to show something to the user we have to set the active account. If the authentication starts the first
   * time getActiveAccount() is null. Therefore, we have to get all the accounts (most of the time getAllAccounts
   * contains 1 account) and set the active account.
   */
  checkAndSetActiveAccount() {
    const activeAccount = this.msal.instance.getActiveAccount();

    if (this.msal.instance.getAllAccounts().length > 0) {
      if (!activeAccount) {
        this.msal.instance.setActiveAccount(this.msal.instance.getAllAccounts()[0]);
      }
      this.loginSuccess.next(true);
    }
  }

  /**
   * @returns The user object from MSAL.
   */
  getUser(): AccountInfo | null {
    return this.msal.instance.getActiveAccount();
  }

  /**
   * @returns The username from the user object.
   */
  getUserEmail(): string | undefined {
    return this.msal.instance.getActiveAccount()?.username;
  }

  getUserFullName(): string | undefined {
    return this.msal.instance.getActiveAccount()?.name;
  }

  /**
   * Logs out the current user and redirects to the logout page.
   */
  logout() {
    this.msal.logoutRedirect();
  }

  /**
   * @returns True if the user is authenticated, false otherwise.
   */
  isAuthenticated(): boolean {
    return this.msal.instance.getActiveAccount() !== null;
  }

  getLoginSuccessSubject(): Observable<boolean> {
    return this.loginSuccess;
  }
}
