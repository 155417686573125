<div appClickStopPropagation class="flex w-full flex-row">
  <div class="grow"></div>

  <gx-dropdown
    [menuItems]="items"
    [item]="item"
    [label]="label"
    [icon]="menuIcon"
    data-testid="dropdown"
  ></gx-dropdown>

  <ng-template #item let-menuItem let-component="component">
    <div *ngIf="!menuItem.isRouterLink" class="flex flex-row items-center justify-between gap-4 p-2 hover:cursor-pointer hover:bg-ui-surface"
         (click)="menuItem.clickEvent(); navigation.emit($event)" data-testid="item">
      <button>{{ menuItem.label | translate }}</button>
      <fa-icon [icon]="getIcon(menuItem.id)"/>
    </div>
    <a *ngIf="menuItem.isRouterLink" [routerLink]="menuItem.routerLink" class="flex flex-row items-center justify-between gap-4 p-2 hover:cursor-pointer hover:bg-ui-surface"
       (click)="navigation.emit($event)" data-testid="item">
      <button>{{ menuItem.label | translate }}</button>
      <fa-icon [icon]="getIcon(menuItem.id)"/>
    </a>
  </ng-template>

  <div class="grow"></div>
</div>
